import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/app';
import 'firebase/auth';

// action - state management
import { ACCOUNT_INITIALIZE } from 'store/actions';

// project imports
import accountReducer from 'store/accountReducer';
import Loader from 'ui-component/Loader';
import config from 'config';
import { getUserProfileInfo } from 'services/user-service';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(config.firebase);
}

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ===========================|| FIREBASE CONTEXT & PROVIDER ||=========================== //

const FirebaseContext = createContext({
    ...initialState,
    firebaseEmailPasswordNewUser: () => Promise.resolve(),
    firebaseEmailPasswordSignIn: () => Promise.resolve(),
    firebaseGoogleSignIn: () => Promise.resolve(),
    sendPasswordResetEmail: () => Promise.resolve(),
    changePassword: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    token: null
});

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseEmailPasswordNewUser = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);
    const sendPasswordResetEmail = async (email) => firebase.auth().sendPasswordResetEmail(email);
    const logout = () => firebase.auth().signOut();
    const changePassword = async (email, oldpassword, newpassword) =>
        new Promise((resolve, reject) => {
            firebase
                .auth()
                .signInWithEmailAndPassword(email, oldpassword)
                .then((result) => {
                    firebase
                        .auth()
                        .currentUser.updatePassword(newpassword)
                        .then((res) => {
                            resolve(true);
                        })
                        .catch((er) => console.log(er));
                })
                .catch((er) => {
                    reject(er);
                    console.log(er);
                });
        });

    const token = () => firebase.auth().currentUser.getIdToken();

    useEffect(
        () =>
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
                    // console.log(`Claims: ${JSON.stringify(idTokenResult.claims)}`);
                    const token = await firebase.auth().currentUser.getIdToken();
                    const profile = await getUserProfileInfo(token, { email: user.email });
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                id: user.uid,
                                email: user.email,
                                claims: idTokenResult.claims,
                                profile
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            user: null
                        }
                    });
                }
            }),
        [dispatch]
    );

    if (!state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseEmailPasswordNewUser,
                firebaseEmailPasswordSignIn,
                firebaseGoogleSignIn,
                sendPasswordResetEmail,
                changePassword,
                logout,
                token
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
