import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// material-ui
import { Typography, CircularProgress, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreTwoTone';
import ChevronRightIcon from '@material-ui/icons/ChevronRightTwoTone';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { getTree, getTreeForId, getTreeLazyForId } from 'services/geo-service';
import { FormattedMessage } from 'react-intl';
import useAuth from 'hooks/useAuth';
import { RestaurantMenu } from '@material-ui/icons';

// third-party
// import { AnimatedTree } from 'react-tree-graph';

const geoLevelColors = {
    1: 'darkblue',
    2: '#3cb44b',
    3: 'dodgerblue',
    4: 'darkgoldenrod',
    5: 'darkcyan',
    6: 'coral',
    7: 'steelblue',
    8: '#f032e6',
    9: 'darkblue',
    10: 'darkgoldenrod',
    11: '#87b649'
};

const GeoTree = (props) => {
    const { handleGeoLevelSelection, isError, title, height, skipLeaf, forAhawal, skipLevel } = props;
    const dispatch = useDispatch();
    const { user, token } = useAuth();
    const [loading, setLoading] = useState(false);
    const [tree, setTree] = useState({});

    const shouldProceedChildren = (location) => {
        if (skipLeaf) {
            const leaf =
                location.children &&
                location.children.length > 0 &&
                (+location.children[0].level_id === 10 || +location.children[0].level_id === 11);
            if (leaf) {
                return false;
            }
            return true;
        }
        return location.children && location.children.length > 0;
    };

    const updateTreeWithChildrenFor = (tree, locationId, response) => {
        if (tree.children) {
            const updated = tree.children.map((c) => {
                if (c.location_id === locationId) {
                    c = response;
                } else {
                    updateTreeWithChildrenFor(c, locationId, response);
                }
                return c;
            });
            tree.children = updated;
        }
    };

    const getChildTreeFor = (location) => {
        const immediateParentToLeaves = ['शहरी नगर', 'मंडल', 'उपखंड नगर'];
        if ((forAhawal && +location.level_id === 6) || +location.level_id === 7) {
            // Leaf for Ahwaal
            setTree(location);
        }
        if (forAhawal && parseInt(location.level_id, 10) >= 7) {
            return;
        }
        if (skipLeaf && immediateParentToLeaves.includes(location.name_suffix)) {
            return;
        }
        if (skipLevel && skipLevel?.includes(location.level_id)) {
            setTree(location);
            return;
        }

        setLoading(true);
        getTreeForId(token, location.location_id)
            .then((response) => {
                const treeClone = { ...tree };
                if (tree.children && tree.children.length > 0) {
                    //  recurssively check and update children at appropriate parentc
                    updateTreeWithChildrenFor(treeClone, location.location_id, response);
                    setTree(treeClone);
                    console.log('treeClone', treeClone);
                } else {
                    setTree(response);
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    useEffect(() => {
        getChildTreeFor(user.profile.location);
    }, []);

    const renderLabel = (item) => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <Typography
                onClick={(event) => {
                    // setActiveItemId(item.id);
                    // if you want after click do expand/collapse comment this two line
                    event.stopPropagation();
                    event.preventDefault();
                    if (handleGeoLevelSelection) {
                        handleGeoLevelSelection(item);
                    }
                    // getChildTreeFor(item);
                }}
                sx={{
                    color: item && item.level && item.level.level_id && geoLevelColors[item.level.level_id]
                }}
            >
                {item.localized_name} - {item.name_suffix}
            </Typography>
            {/* <Typography
                sx={{
                    color: 'd0d0d0'
                }}
            >
                ~ [स्तर: {item && item.level && item.level.localized_name}]
            </Typography> */}
        </Box>
    );

    const renderTree = (nodes) => (
        <TreeItem sx={{ backgroundColor: '#fff' }} key={nodes.location_id} nodeId={nodes.location_id} label={renderLabel(nodes)}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    );
    return (
        <>
            {/* <Typography color="error">
                <FormattedMessage id="bhaugolik-rachana-select" />
            </Typography> */}
            {loading && <CircularProgress color="primary" size={20} />}
            {tree && !loading && (
                <TreeView
                    aria-label="rich object"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpanded={['root']}
                    defaultExpandIcon={<ChevronRightIcon onClick={() => console.log('Expand Clicked')} />}
                    // sx={{ height: 110, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                >
                    {renderTree(tree)}
                </TreeView>
            )}
        </>
        // <MainCard
        //     title={title || <FormattedMessage id="bhaugolik-rachana" />}
        //     sx={{ borderColor: isError ? 'tomato' : '#d0d0d0', height: height || '400px', overflowY: 'auto' }}
        // >
        //     {loading && <CircularProgress color="primary" size={20} />}
        //     {tree && !loading && (
        //         <TreeView
        //             aria-label="rich object"
        //             defaultCollapseIcon={<ExpandMoreIcon />}
        //             defaultExpanded={['root']}
        //             defaultExpandIcon={<ChevronRightIcon onClick={() => console.log('Expand Clicked')} />}
        //         >
        //             {renderTree(tree)}
        //         </TreeView>
        //     )}
        // </MainCard>
    );
};

export default GeoTree;
