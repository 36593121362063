import dashboard from './dashboard';
import widget from './widget';
import application from './application';
import forms from './forms';
import elements from './elements';
import pages from './pages';
import utilities from './utilities';
import support from './support';
import other from './other';
import pmpDashboard from './pmp-dashboard';
import pmpApplication from './pmp-application';
import pmpAbout from './pmp-about';

// ===========================|| MENU ITEMS ||=========================== //

const MenuItems = {
    // items: [dashboard, widget, application, forms, elements, pages, utilities, support, other]
    items: [pmpDashboard, pmpApplication, pmpAbout]
};

export default MenuItems;
