// action - state management

import { SADYASTHITI_INPUT_SELECTION } from 'store/actions';

// ===========================|| ACCOUNT REDUCER ||=========================== //

const sadyasthitiReducer = (state, action) => {
    switch (action.type) {
        case SADYASTHITI_INPUT_SELECTION: {
            const { selectedGeo, month, year, data } = action.payload;
            return {
                ...state,
                selectedGeo,
                month,
                year,
                data
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default sadyasthitiReducer;
