import { Box, Dialog, DialogActions, DialogTitle, Grid, IconButton } from '@material-ui/core';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
    timelineOppositeContentClasses
} from '@material-ui/lab';
import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { Close } from '@material-ui/icons';

export default function HistorySuchiDialog({ model, setModel, timeline, setTimeline }) {
    const handleClose = () => {
        setModel(false);
        setTimeline([]);
    };

    const getDateTime = (date) => {
        const timestamp = new Date(date);

        // Separate date
        const year = timestamp.getFullYear();
        const month = timestamp.getMonth() + 1; // Months are zero-based, so add 1
        const day = timestamp.getDate();

        // Separate time
        const hours = timestamp.getHours();
        const minutes = timestamp.getMinutes();
        const seconds = timestamp.getSeconds();
        const milliseconds = timestamp.getMilliseconds();

        // Output
        // console.log(`Date: ${day}-${month}-${year}`);
        // console.log(`Time: ${hours}:${minutes}:${seconds}.${milliseconds}`);
        return `${day}-${month}-${year}`;
    };
    return (
        <>
            <Dialog
                // fullScreen={fullScreen}
                fullWidth
                open={model}
                onClose={handleClose}
                // aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Box flex="row" justifyContent="space-between" alignItems="center" display="flex">
                        <span>स्वयंसेवक सूची इतिहास</span>
                        <IconButton onClick={handleClose}>
                            <Close color="error" />
                        </IconButton>
                    </Box>
                </DialogTitle>
                {/* <DialogContent>
                    <DialogContentText>खाली क्रमशः भौगोलिक रचना आहे, त्यामधून आवश्यक ते स्थान निवडा</DialogContentText>
                </DialogContent> */}
                <DialogActions>
                    <Grid container>
                        <Grid item xs={12}>
                            <Timeline
                                sx={{
                                    [`& .${timelineOppositeContentClasses.root}`]: {
                                        flex: 0.2
                                    }
                                }}
                            >
                                {timeline && timeline?.length > 0 ? (
                                    timeline?.map((value) => (
                                        <>
                                            {value?.changesIn?.map((item) =>
                                                item?.name !== 'createdAt' ? (
                                                    <TimelineItem>
                                                        <TimelineOppositeContent color="text.secondary">
                                                            {getDateTime(value?.createData)}
                                                        </TimelineOppositeContent>
                                                        <TimelineSeparator>
                                                            <TimelineDot />
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent>
                                                            <Box
                                                                flex="row"
                                                                flexDirection="row"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                            >
                                                                &quot;{item?.name}&quot; {item?.previousValue}{' '}
                                                                <ArrowRightAltIcon
                                                                    sx={{ verticalAlign: 'top', color: '#e95a3a', fontSize: '30px' }}
                                                                />{' '}
                                                                {item?.currentValue}
                                                            </Box>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                ) : null
                                            )}
                                        </>
                                    ))
                                ) : (
                                    <h3>स्वयंसेवक सूचीचा इतिहास नाही</h3>
                                )}
                            </Timeline>
                        </Grid>

                        {/* <Grid item xs={12}>
                            <Timeline position="">
                                {timeline && timeline?.length > 0 ? (
                                    timeline?.map((value) =>
                                        value?.createData
                                            ? value?.changesIn?.map((cngItem) =>
                                                  cngItem?.name !== 'createdAt' ? (
                                                      <TimelineItem>
                                                          <TimelineOppositeContent color="text.secondary">
                                                              {getDateTime(value?.createData)}
                                                          </TimelineOppositeContent>
                                                          <TimelineSeparator>
                                                              <TimelineDot />
                                                              <TimelineConnector />
                                                          </TimelineSeparator>
                                                          <TimelineContent>
                                                              &quot;{cngItem?.name}&quot; {cngItem?.previousValue} &rarr;
                                                              {cngItem?.currentValue}
                                                          </TimelineContent>
                                                      </TimelineItem>
                                                  ) : null
                                              )
                                            : null
                                    )
                                ) : (
                                    <h3>स्वयंसेवक सूचीचा इतिहास नाही</h3>
                                )}
                            </Timeline>
                        </Grid> */}
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}
