import React from 'react';
// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import { Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@material-ui/core';
import { visuallyHidden } from '@material-ui/utils';
import SendTwoToneIcon from '@material-ui/icons/SendTwoTone';
import GeoTree from 'views/application/pmp-geo-structure/geo-tree';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    sortSpan: visuallyHidden
}));

const GeoTreeDialog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const {
        selectedGeo,
        geoTreeDialogOpen,
        handleGeoDialogClose,
        handleCancelGeoSelection,
        handleGeoLevelSelection,
        handleConfirmGeoSelection,
        skipLeafLevel
    } = props;

    // Geo Dialog
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    // const [geoTreeDialogOpen, setGeoTreeDialogOpen] = useState(false);
    // const [selectedGeo, setSelectedGeo] = React.useState();
    return (
        <Box>
            <Dialog
                fullScreen={fullScreen}
                open={geoTreeDialogOpen}
                onClose={handleGeoDialogClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">भौगोलिक रचना निवडा!</DialogTitle>
                <DialogContent>
                    <DialogContentText>खाली क्रमशः भौगोलिक रचना आहे, त्यामधून आवश्यक ते स्थान निवडा</DialogContentText>
                    <GeoTree
                        title="भौगोलिक रचना निवडा"
                        handleGeoLevelSelection={handleGeoLevelSelection}
                        isError={false}
                        height="200px"
                        skipLeaf={skipLeafLevel}
                        {...props}
                    />
                </DialogContent>
                <DialogActions sx={{ pr: 2.5 }}>
                    <Button
                        autoFocus
                        onClick={handleCancelGeoSelection}
                        // onClick={() => {
                        //     setSelectedGeo(undefined);
                        //     setGeoTreeDialogOpen(false);
                        // }}
                        color="error"
                    >
                        निवड रद्द करा
                    </Button>
                    {selectedGeo && (
                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleConfirmGeoSelection}
                            // onClick={() => {
                            //     setGeoTreeDialogOpen(false);
                            //     proceedAfterGeoSelection();
                            // }}
                            autoFocus
                            color="primary"
                            endIcon={<SendTwoToneIcon />}
                        >
                            &quot;{selectedGeo?.localized_name} {selectedGeo?.name_suffix}&quot; » निवडा
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default GeoTreeDialog;
