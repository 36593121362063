import React, { useEffect, useState } from 'react';
import './index.css';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { utils, writeFile } from 'xlsx';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import Loader from 'ui-component/Loader';

export default function SanghikTalukaAhawal({ ahawalResponse }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const defaultTalukaAhawal = {
        totalUpakhand: 0,
        presentUpakhand: 0,
        totalUpakhandNagar: 0,
        presentUpakhandNagar: 0,
        sankalpitShakha: 0,
        sankalpitMilan: 0,
        sankalpitSanghMandali: 0,
        sankalpitTotal: 0,
        presentSankalpitShakha: 0,
        presentSankalpitMilan: 0,
        presentSankalpitSanghMandali: 0,
        presentSankalpitTotal: 0,
        praudh: 0,
        tvk: 0,
        mahavidyalayin: 0,
        baal: 0,
        totalUpasthit: 0,
        shishu: 0
    };
    const [tableData, setTableData] = useState(defaultTalukaAhawal);

    const prepareAhawalData = () => {
        const obj = {
            totalUpakhand: ahawalResponse?.Total_upkhand || 0,
            presentUpakhand: ahawalResponse?.present_upkhand || 0,
            totalUpakhandNagar: ahawalResponse?.Total_upkhand_nagar || 0,
            presentUpakhandNagar: ahawalResponse?.present_upkhand_nagar || 0,

            sankalpitShakha: ahawalResponse?.ekunSankalpit?.['शाखा'] || 0,
            sankalpitMilan: ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] || 0,
            sankalpitSanghMandali: ahawalResponse?.ekunSankalpit?.['संघ मंडळी'] || 0,
            sankalpitTotal:
                ahawalResponse?.ekunSankalpit?.['शाखा'] +
                ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] +
                ahawalResponse?.ekunSankalpit?.['संघ मंडळी'],
            presentSankalpitShakha: ahawalResponse?.present_Sankalpit?.['शाखा'] || 0,
            presentSankalpitMilan: ahawalResponse?.present_Sankalpit?.['साप्ताहिक मिलन'] || 0,
            presentSankalpitSanghMandali: ahawalResponse?.present_Sankalpit?.['संघ मंडळी'] || 0,
            presentSankalpitTotal:
                ahawalResponse?.present_Sankalpit?.['शाखा'] +
                ahawalResponse?.present_Sankalpit?.['साप्ताहिक मिलन'] +
                ahawalResponse?.present_Sankalpit?.['संघ मंडळी'],

            praudh: ahawalResponse?.present_sayamsevak_upastiti?.['प्रौढ'] || 0,
            tvk: ahawalResponse?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'] || 0,
            mahavidyalayin: ahawalResponse?.present_sayamsevak_upastiti?.['महाविद्यालयीन'] || 0,
            baal: ahawalResponse?.present_sayamsevak_upastiti?.['बाल'] || 0,
            totalUpasthit: ahawalResponse?.present_sayamsevak_upastiti?.['एकूण'] || 0,
            shishu: ahawalResponse?.present_sayamsevak_upastiti?.['शिशु'] || 0
        };
        setTableData(obj);
    };
    useEffect(() => {
        if ((ahawalResponse && ahawalResponse.length > 0) || Object.keys(ahawalResponse).length > 0) {
            prepareAhawalData();
        }
    }, [ahawalResponse]);
    const fetchTalukaAhawalDS = () => {
        const sheetArray = [];
        const allUpkhand = ahawalResponse?.upkhand || [];
        const allUpkhandNagar = ahawalResponse?.upkhandNagar || [];
        const obj = {
            upkhandName: '',
            mandalName: '',
            upkhandNagarName: '',
            vastiName: '',
            totalGoan: 0,
            sankalpitGaon: 0,
            presentGaon: 0,
            sankalpit_shakha_praudh: 0,
            sankalpit_shakha_tvk: 0,
            sankalpit_shakha_mahavidyalayin: 0,
            sankalpit_shakha_sanyuktaVidyarthi: 0,
            sankalpit_milan_praudh: 0,
            sankalpit_milan_tvk: 0,
            sankalpit_milan_mahavidyalayin: 0,
            sankalpit_milan_sanyuktaVidyarthi: 0,
            sankalpit_sanghMandali: 0,
            total_sankalpit_ekake: 0,
            upasthit_shakha_praudh: 0,
            upasthit_shakha_tvk: 0,
            upasthit_shakha_mahavidyalayin: 0,
            upasthit_shakha_sanyuktaVidyarthi: 0,
            upasthit_milan_praudh: 0,
            upasthit_milan_tvk: 0,
            upasthit_milan_mahavidyalayin: 0,
            upasthit_milan_sanyuktaVidyarthi: 0,
            upasthit_sanghMandali: 0,
            total_upasthit_ekake: 0,
            upasthit_praudh: 0,
            upasthit_tvk: 0,
            upasthit_mahavidyalayin: 0,
            upasthit_baal: 0,
            upasthit_ekun: 0,
            upasthit_shishu: 0
        };
        if (allUpkhand && allUpkhand.length > 0) {
            allUpkhand.forEach((r) => {
                if (r.sanghik_record_found) {
                    const currentObj = obj;
                    currentObj.upkhandName = `${r?.location_name} ${r.name_suffix}` || '';
                    r.mandals.forEach((mr) => {
                        currentObj.totalGoan = mr?.gaon_count || 0;
                        currentObj.mandalName = mr?.mandal_name || '';
                        currentObj.sankalpit_shakha_praudh = mr?.Sankalp_total?.['शाखा']?.sub_types?.['प्रौढ'] || 0;
                        currentObj.sankalpit_shakha_tvk = mr?.Sankalp_total?.['शाखा']?.sub_types?.['तरुण व्यवसायी'] || 0;
                        currentObj.sankalpit_shakha_mahavidyalayin =
                            mr?.Sankalp_total?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0;
                        currentObj.sankalpit_shakha_sanyuktaVidyarthi = mr?.Sankalp_total?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'] || 0;
                        currentObj.sankalpit_milan_praudh = mr?.Sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'] || 0;
                        currentObj.sankalpit_milan_tvk = mr?.Sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'] || 0;
                        currentObj.sankalpit_milan_mahavidyalayin =
                            mr?.Sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0;
                        currentObj.sankalpit_milan_sanyuktaVidyarthi =
                            mr?.Sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'] || 0;
                        currentObj.sankalpit_sanghMandali = mr?.Sankalp_total?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'] || 0;
                        currentObj.total_sankalpit_ekake =
                            mr?.ekunSankalpit?.['शाखा'] + mr?.ekunSankalpit?.['साप्ताहिक मिलन'] + mr?.ekunSankalpit?.['संघ मंडळी'] || 0;
                        currentObj.upasthit_shakha_praudh = mr?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['प्रौढ'] || 0;
                        currentObj.upasthit_shakha_tvk = mr?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['तरुण व्यवसायी'] || 0;
                        currentObj.upasthit_shakha_mahavidyalayin =
                            mr?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0;
                        currentObj.upasthit_shakha_sanyuktaVidyarthi =
                            mr?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'] || 0;
                        currentObj.upasthit_milan_praudh = mr?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'] || 0;
                        currentObj.upasthit_milan_tvk =
                            mr?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'] || 0;
                        currentObj.upasthit_milan_mahavidyalayin =
                            mr?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0;
                        currentObj.upasthit_milan_sanyuktaVidyarthi =
                            mr?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'] || 0;
                        currentObj.upasthit_sanghMandali = mr?.present_sankalpit_age_wise?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'] || 0;
                        currentObj.total_upasthit_ekake =
                            mr?.present_Sankalpit?.['शाखा'] +
                                mr?.present_Sankalpit?.['साप्ताहिक मिलन'] +
                                mr?.present_Sankalpit?.['संघ मंडळी'] || 0;
                        currentObj.upasthit_praudh = mr?.present_sayamsevak_upastiti?.['प्रौढ'];
                        currentObj.upasthit_tvk = mr?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'];
                        currentObj.upasthit_mahavidyalayin = mr?.present_sayamsevak_upastiti?.['महाविद्यालयीन'];
                        currentObj.upasthit_baal = mr?.present_sayamsevak_upastiti?.['बाल'];
                        currentObj.upasthit_ekun = mr?.present_sayamsevak_upastiti?.['एकूण'];
                        currentObj.upasthit_shishu = mr?.present_sayamsevak_upastiti?.['शिशु'];
                        sheetArray.push([
                            currentObj.upkhandName,
                            currentObj.mandalName,
                            currentObj.totalGoan,
                            '',
                            '',
                            currentObj.sankalpit_shakha_praudh,
                            currentObj.sankalpit_shakha_tvk,
                            currentObj.sankalpit_shakha_mahavidyalayin,
                            currentObj.sankalpit_shakha_sanyuktaVidyarthi,
                            currentObj.sankalpit_milan_praudh,
                            currentObj.sankalpit_milan_tvk,
                            currentObj.sankalpit_milan_mahavidyalayin,
                            currentObj.sankalpit_milan_sanyuktaVidyarthi,
                            currentObj.sankalpit_sanghMandali,
                            currentObj.total_sankalpit_ekake,
                            currentObj.upasthit_shakha_praudh,
                            currentObj.upasthit_shakha_tvk,
                            currentObj.upasthit_shakha_mahavidyalayin,
                            currentObj.upasthit_shakha_sanyuktaVidyarthi,
                            currentObj.upasthit_milan_praudh,
                            currentObj.upasthit_milan_tvk,
                            currentObj.upasthit_milan_mahavidyalayin,
                            currentObj.upasthit_milan_sanyuktaVidyarthi,
                            currentObj.upasthit_sanghMandali,
                            currentObj.total_upasthit_ekake,
                            currentObj.upasthit_praudh,
                            currentObj.upasthit_tvk,
                            currentObj.upasthit_mahavidyalayin,
                            currentObj.upasthit_baal,
                            currentObj.upasthit_ekun,
                            currentObj.upasthit_shishu
                        ]);
                    });
                }
            });
        }
        if (allUpkhandNagar && allUpkhandNagar.length > 0) {
            allUpkhandNagar.forEach((r) => {
                if (r.sanghik_record_found) {
                    const currentObj = obj;
                    currentObj.upkhandNagarName = `${r?.location_name} ${r.name_suffix}` || '';
                    r.children_locations.forEach((vr) => {
                        currentObj.vastiName = vr?.location_name || '';
                        currentObj.sankalpit_shakha_praudh = vr?.sankalp_total?.['शाखा']?.sub_types?.['प्रौढ'] || 0;
                        currentObj.sankalpit_shakha_tvk = vr?.sankalp_total?.['शाखा']?.sub_types?.['तरुण व्यवसायी'] || 0;
                        currentObj.sankalpit_shakha_mahavidyalayin =
                            vr?.sankalp_total?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0;
                        currentObj.sankalpit_shakha_sanyuktaVidyarthi = vr?.sankalp_total?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'] || 0;
                        currentObj.sankalpit_milan_praudh = vr?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'] || 0;
                        currentObj.sankalpit_milan_tvk = vr?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'] || 0;
                        currentObj.sankalpit_milan_mahavidyalayin =
                            vr?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0;
                        currentObj.sankalpit_milan_sanyuktaVidyarthi =
                            vr?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'] || 0;
                        currentObj.sankalpit_sanghMandali = vr?.sankalp_total?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'] || 0;
                        currentObj.total_sankalpit_ekake =
                            vr?.ekunSankalpit?.['शाखा'] + vr?.ekunSankalpit?.['साप्ताहिक मिलन'] + vr?.ekunSankalpit?.['संघ मंडळी'] || 0;
                        currentObj.upasthit_shakha_praudh = vr?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['प्रौढ'] || 0;
                        currentObj.upasthit_shakha_tvk = vr?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['तरुण व्यवसायी'] || 0;
                        currentObj.upasthit_shakha_mahavidyalayin =
                            vr?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0;
                        currentObj.upasthit_shakha_sanyuktaVidyarthi =
                            vr?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'] || 0;
                        currentObj.upasthit_milan_praudh = vr?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'] || 0;
                        currentObj.upasthit_milan_tvk =
                            vr?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'] || 0;
                        currentObj.upasthit_milan_mahavidyalayin =
                            vr?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0;
                        currentObj.upasthit_milan_sanyuktaVidyarthi =
                            vr?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'] || 0;
                        currentObj.upasthit_sanghMandali = vr?.present_Sankalpit_age_wise?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'] || 0;
                        currentObj.total_upasthit_ekake =
                            vr?.present_Sankalpit?.['शाखा'] +
                                vr?.present_Sankalpit?.['साप्ताहिक मिलन'] +
                                vr?.present_Sankalpit?.['संघ मंडळी'] || 0;
                        currentObj.upasthit_praudh = vr?.present_sayamsevak_upastiti?.['प्रौढ'];
                        currentObj.upasthit_tvk = vr?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'];
                        currentObj.upasthit_mahavidyalayin = vr?.present_sayamsevak_upastiti?.['महाविद्यालयीन'];
                        currentObj.upasthit_baal = vr?.present_sayamsevak_upastiti?.['बाल'];
                        currentObj.upasthit_ekun = vr?.present_sayamsevak_upastiti?.['एकूण'];
                        currentObj.upasthit_shishu = vr?.present_sayamsevak_upastiti?.['शिशु'];
                        sheetArray.push([
                            currentObj.upkhandNagarName,
                            currentObj.vastiName,
                            '-',
                            '-',
                            '-',
                            currentObj.sankalpit_shakha_praudh,
                            currentObj.sankalpit_shakha_tvk,
                            currentObj.sankalpit_shakha_mahavidyalayin,
                            currentObj.sankalpit_shakha_sanyuktaVidyarthi,
                            currentObj.sankalpit_milan_praudh,
                            currentObj.sankalpit_milan_tvk,
                            currentObj.sankalpit_milan_mahavidyalayin,
                            currentObj.sankalpit_milan_sanyuktaVidyarthi,
                            currentObj.sankalpit_sanghMandali,
                            currentObj.total_sankalpit_ekake,
                            currentObj.upasthit_shakha_praudh,
                            currentObj.upasthit_shakha_tvk,
                            currentObj.upasthit_shakha_mahavidyalayin,
                            currentObj.upasthit_shakha_sanyuktaVidyarthi,
                            currentObj.upasthit_milan_praudh,
                            currentObj.upasthit_milan_tvk,
                            currentObj.upasthit_milan_mahavidyalayin,
                            currentObj.upasthit_milan_sanyuktaVidyarthi,
                            currentObj.upasthit_sanghMandali,
                            currentObj.total_upasthit_ekake,
                            currentObj.upasthit_praudh,
                            currentObj.upasthit_tvk,
                            currentObj.upasthit_mahavidyalayin,
                            currentObj.upasthit_baal,
                            currentObj.upasthit_ekun,
                            currentObj.upasthit_shishu
                        ]);
                    });
                }
            });
        }

        sheetArray.unshift([
            '',
            '',
            'एकूण गावे',
            'संकल्प असलेली गावे',
            'उपस्थित गावे',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'संघमंडळी',
            'एकूण एकके',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'संघमंडळी',
            'एकूण एकके',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'बाल',
            'एकूण',
            'शिशु'
        ]);
        sheetArray.unshift(['', '', '', '', '', '', 'शाखा', '', '', '', 'सा. मिलन', '', '', '', '', '', 'शाखा', '', '', '', 'सा. मिलन']);
        sheetArray.unshift([
            'उपखंड',
            'मंडल/वस्ती',
            '',

            'गाव संख्या',
            '',
            '',
            '',
            '',
            '',
            '',
            'एकूण संकल्पित एकके',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'एकूण उपस्थित एकके',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'उपस्थिती (स्वयंसेवक)'
        ]);
        return sheetArray;
    };
    const generateExcelFile = () => {
        setLoading(true);
        const wb = utils.book_new();
        const ws1 = utils.json_to_sheet(fetchTalukaAhawalDS());
        utils.book_append_sheet(wb, ws1, 'अहवाल');

        writeFile(wb, `${ahawalResponse?.location_name || ''} ${ahawalResponse?.name_suffix || ''} अहवाल.xlsx`);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'यशस्वीरितीने फाईल डाउनलोड झालेली आहे!',
            variant: 'alert',
            alertSeverity: 'success'
        });
        setLoading(false);
    };
    return (
        <>
            {loading && <Loader />}
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography variant="h3">{`${ahawalResponse?.location_name || ''} ${ahawalResponse?.name_suffix || ''} अहवाल`}</Typography>
                <Button variant="contained" color="success" sx={{ m: '10px' }} onClick={generateExcelFile}>
                    <FormattedMessage id="menu.download.ahawal" />
                </Button>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell" align="center" colSpan={2}>
                                उपखंड नगर
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={2}>
                                उपखंड
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={4}>
                                एकूण संकल्पित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={4}>
                                एकूण उपस्थित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={6}>
                                उपस्थिती (स्वयंसेवक)
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="tableCell" align="center">
                                एकूण उपखंड नगरे
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सांघिक झालेली उपखंड नगरे
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण उपखंड
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सांघिक झालेली उपखंड
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा. मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                संघमंडळी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा. मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                संघमंडळी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                प्रौढ
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                तरुण व्यवसायी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                महाविद्यालयीन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                बाल
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शिशु
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className="tableCell" align="center">
                                {tableData.totalUpakhandNagar || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentUpakhandNagar || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.totalUpakhand || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentUpakhand || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.sankalpitShakha || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.sankalpitMilan || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.sankalpitSanghMandali || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.sankalpitTotal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentSankalpitShakha || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentSankalpitMilan || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentSankalpitSanghMandali || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentSankalpitTotal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.praudh || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.tvk || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.mahavidyalayin || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.baal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.totalUpasthit || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.shishu || 0}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
