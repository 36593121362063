import {
    Autocomplete,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    TextField,
    Typography
} from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    filterReport,
    getAllDayitvTree,
    getAllSanghShikshan,
    getAllShreniAndKhateTree,
    getAllVyavsayTree
} from 'services/swayamsevak-service';
import MainCard from 'ui-component/cards/MainCard';
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone';
import GeoTreeDialog from 'ui-component/pmp-components/GeoTreeDialog';
import { useDispatch, useSelector } from 'react-redux';
import { SET_GEO_STRUCTURE, SNACKBAR_OPEN } from 'store/actions';
import { utils, writeFileXLSX } from 'xlsx';

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Report() {
    const selectedgeoStructure = useSelector((state) => state?.centrallySelectedgeoStructure?.selectedGeoStructure);
    const [fieldList, setFieldList] = useState([]);
    const [filterdData, setFilterdData] = useState([]);
    const [vyavsayTree, setVyavsayTree] = useState([]);
    const [vyavsay1Tree, setVyavsay1Tree] = useState([]);
    const [vyavsay2Tree, setVyavsay2Tree] = useState([]);
    const [occupation, setOccupation] = useState([]);
    const [occupation1, setOccupation1] = useState([]);
    const [occupation2, setOccupation2] = useState([]);
    const [shreni, setShreni] = useState([]);
    const [category, setCategory] = useState([]);
    const [khate, setKhate] = useState([]);
    const [account, setAccount] = useState([]);
    const [dayitvTree, setDayitvTree] = useState([]);
    const [responsibilityLevel, setResponsibilityLevel] = useState([]);
    const [dayitv, setDayitv] = useState([]);
    const [responsibility, setResponsibility] = useState([]);
    const bloodGroupData = ['A +', 'B +', 'AB +', 'O +', 'A -', 'B -', 'AB -', 'O -'];
    const [bloodGroup, setBloodGroup] = useState([]);
    const dispatch = useDispatch();
    const [disableBtn, setDisableBtn] = useState(false);
    const [uniform, setUniform] = useState(false);
    const [other, setOther] = useState(false);
    const [sanghShikshan, setSanghShikshan] = useState([]);
    const [trainingInOrganization, setTrainingInOrganization] = useState([]);

    // geo location-------------------
    const [geoTreeDialogOpen, setGeoTreeDialogOpen] = useState(false);
    const [selectedGeo, setSelectedGeo] = useState(selectedgeoStructure);
    const [payload, setPayload] = useState({
        karyalay_pramukh: true
    });
    const proceedAfterGeoSelection = () => {
        setPayload({ ...payload, selectedGeoLevel: selectedGeo });
        //
    };

    const fieldsNameData = [
        {
            ui_name: 'वडिलांचे नांव',
            backend_name: 'fatherName',
            type: 'input',
            value: false
        },
        {
            ui_name: 'जन्म तारीख',
            backend_name: 'dateOfBirth',
            type: 'input',
            value: false
        },
        // {
        //     ui_name: 'रक्त गट',
        //     backend_name: 'bloodGroup',
        //     type: 'selector',
        //     value: false,
        //     defaultValues: [...bloodGroupData]
        // },
        // {
        //     ui_name: 'व्यवसाय',
        //     backend_name: 'occupation',
        //     type: 'selector',
        //     value: false,
        //     defaultValues: [...vyavsayTree]
        // },
        // {
        //     ui_name: 'व्यवसाय तपशील 1',
        //     backend_name: 'occupationDetails1',
        //     type: 'selector',
        //     value: false,
        //     defaultValues: []
        // },
        // {
        //     ui_name: 'व्यवसाय तपशील 2',
        //     backend_name: 'occupationDetails2',
        //     type: 'selector',
        //     value: false,
        //     defaultValues: []
        // },
        {
            ui_name: 'पूर्ण पत्ता',
            backend_name: 'address',
            type: 'input',
            value: false
        },
        {
            ui_name: 'पिन कोड',
            backend_name: 'pinCode',
            type: 'input',
            value: false
        },
        {
            ui_name: 'दूरभाष',
            backend_name: 'phone',
            type: 'input',
            value: false
        },
        {
            ui_name: 'भ्रमणभाष',
            backend_name: 'mobile',
            type: 'input',
            value: false
        },
        {
            ui_name: 'इ-मेल आयडी',
            backend_name: 'email',
            type: 'input',
            value: false
        },
        // {
        //     ui_name: 'श्रेणी',
        //     backend_name: 'category',
        //     type: 'selector',
        //     value: false,
        //     defaultValues: []
        // },
        // {
        //     ui_name: 'खाते',
        //     backend_name: 'account',
        //     type: 'selector',
        //     value: false,
        //     defaultValues: []
        // },
        // {
        //     ui_name: 'दायित्व स्तर',
        //     backend_name: 'responsibilityLevel',
        //     type: 'selector',
        //     value: false,
        //     defaultValues: []
        // },
        // {
        //     ui_name: 'दायित्व',
        //     backend_name: 'responsibility',
        //     type: 'selector',
        //     value: false,
        //     defaultValues: []
        // },
        {
            ui_name: 'दायित्व अन्य',
            backend_name: 'otherResponsibility',
            type: 'input',
            value: false
        },
        {
            ui_name: 'विशेष नैपुण्य',
            backend_name: 'specialSkills',
            type: 'input',
            value: false
        },
        // {
        //     ui_name: 'संघ शिक्षण',
        //     backend_name: 'trainingInOrganization',
        //     type: 'selector',
        //     value: false
        // },
        {
            ui_name: 'प्रतिज्ञा',
            backend_name: 'isPledge',
            type: 'selector',
            value: false
        },
        {
            ui_name: 'टोपी',
            backend_name: 'isCap',
            type: 'selector',
            value: false,
            category: 'uniform'
        },
        {
            ui_name: 'सदरा',
            backend_name: 'isSadara',
            type: 'selector',
            value: false,
            category: 'uniform'
        },
        {
            ui_name: 'विजार',
            backend_name: 'isWizar',
            type: 'selector',
            value: false,
            category: 'uniform'
        },
        {
            ui_name: 'पदवेष',
            backend_name: 'isPosition',
            type: 'selector',
            value: false,
            category: 'uniform'
        },
        {
            ui_name: 'पट्टा',
            backend_name: 'isPatta',
            type: 'selector',
            value: false,
            category: 'uniform'
        },
        {
            ui_name: 'दंड',
            backend_name: 'isPunishment',
            type: 'selector',
            value: false,
            category: 'uniform'
        },
        {
            ui_name: 'दुचाकी आहे?',
            backend_name: 'isDuchaki',
            type: 'selector',
            value: false,
            category: 'other'
        },
        {
            ui_name: 'चार चाकी आहे?',
            backend_name: 'isCharChaki',
            type: 'selector',
            value: false,
            category: 'other'
        }
    ];

    const fetchAllVyavsayTree = () => {
        getAllVyavsayTree()
            .then((resp) => {
                if (resp && resp?.length > 0) {
                    setVyavsayTree(resp);
                } else {
                    setVyavsayTree([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchAllShreniAndDayitv = async () => {
        getAllShreniAndKhateTree()
            .then((resp) => {
                if (resp && resp?.length > 0) {
                    setShreni(resp);
                } else {
                    setShreni([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        getAllDayitvTree()
            .then((resp) => {
                if (resp && resp?.length > 0) {
                    setDayitvTree(resp);
                } else {
                    setDayitvTree([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchAllSanghShikshan = () => {
        getAllSanghShikshan()
            .then((resp) => {
                if (resp && resp?.length > 0) {
                    setSanghShikshan(resp);
                } else {
                    setSanghShikshan([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(async () => {
        setFieldList(fieldsNameData);
        fetchAllVyavsayTree();
        fetchAllShreniAndDayitv();
        fetchAllSanghShikshan();
        // fetchAllSuchiLocationWise();
        // setVyavsayTree(vyavsayTreeData);
    }, []);

    const exportReport = (data) => {
        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Data');
        writeFileXLSX(wb, 'Swayam suchi Report.xlsx');
    };

    const generateReportHandle = () => {
        if (!selectedGeo?.location_id) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'कृपया प्रथम भौगोलिक रचना निवडा',
                variant: 'alert',
                alertSeverity: 'error'
            });
            return;
        }

        if (selectedGeo?.level_id === '1' || selectedGeo?.level_id === '2') {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'कृपया अधिक अचूक स्थान निवडा.',
                variant: 'alert',
                alertSeverity: 'warning'
            });
            return;
        }

        let payload = {};
        // let checkedFields = [];
        let checkedFields = fieldList?.filter((item) => item?.value === true);
        const uniformFields = fieldList?.filter((item) => item?.category === 'uniform');
        const otherFields = fieldList?.filter((item) => item?.category === 'other');
        if (uniform) {
            checkedFields = checkedFields.concat(uniformFields);
        }
        if (other) {
            checkedFields = checkedFields.concat(otherFields);
        }
        // let checkedPayload = {};
        // checkedFields?.map((item) => {
        //     const tempObj = {
        //         ...checkedPayload,
        //         [item?.backend_name]: item.value
        //     };
        //     checkedPayload = tempObj;
        //     return true;
        // });
        // console.log(checkedPayload);
        // setSelectedFields(checkedPayload);
        if (bloodGroup?.length > 0) {
            payload = {
                // ...checkedPayload,
                bloodGroup: [...bloodGroup]
            };
            checkedFields.push({
                ui_name: 'रक्त गट',
                backend_name: 'bloodGroup'
            });
        }

        if (occupation && occupation?.length > 0) {
            const occName = occupation?.map((item) => item?.localized_name);
            // console.log(occName);
            payload = {
                ...payload,
                occupation: [...occName]
            };
            checkedFields.push({
                ui_name: 'व्यवसाय',
                backend_name: 'occupation'
            });
        }
        if (occupation1 && occupation1?.length > 0) {
            const occName = occupation1?.map((item) => item?.localized_name);
            // console.log(occName);
            payload = {
                ...payload,
                occupationDetails1: [...occName]
            };
            checkedFields.push({
                ui_name: 'व्यवसाय तपशील 1',
                backend_name: 'occupationDetails1'
            });
        }

        if (occupation2 && occupation2?.length > 0) {
            const occName = occupation2?.map((item) => item?.localized_name);
            // console.log(occName);
            payload = {
                ...payload,
                occupationDetails2: [...occName]
            };
            checkedFields.push({
                ui_name: 'व्यवसाय तपशील 2',
                backend_name: 'occupationDetails2'
            });
        }

        if (category && category?.length > 0) {
            const shreni = category?.map((item) => item?.localized_name);
            // console.log(shreni);
            payload = {
                ...payload,
                category: [...shreni]
            };
            checkedFields.push({
                ui_name: 'श्रेणी',
                backend_name: 'category'
            });
        }

        if (account && account?.length > 0) {
            const khate = account?.map((item) => item?.localized_name);
            // console.log(khate);
            payload = {
                ...payload,
                account: [...khate]
            };
            checkedFields.push({
                ui_name: 'खाते',
                backend_name: 'account'
            });
        }

        if (responsibilityLevel && responsibilityLevel?.length > 0) {
            const dayitvStar = responsibilityLevel?.map((item) => item?.localized_name);
            // console.log(dayitvStar);
            payload = {
                ...payload,
                responsibilityLevel: [...dayitvStar]
            };
            checkedFields.push({
                ui_name: 'दायित्व स्तर',
                backend_name: 'responsibilityLevel'
            });
        }

        if (responsibility && responsibility?.length > 0) {
            const dayitv = responsibility?.map((item) => item?.localized_name);
            // console.log(dayitv);
            payload = {
                ...payload,
                responsibility: [...dayitv]
            };
            checkedFields.push({
                ui_name: 'दायित्व',
                backend_name: 'responsibility'
            });
        }

        if (trainingInOrganization && trainingInOrganization?.length > 0) {
            const sanghShikshan = trainingInOrganization?.map((item) => item?.localized_name);
            // console.log(sanghShikshan);
            payload = {
                ...payload,
                trainingInOrganization: [...sanghShikshan]
            };
            checkedFields.push({
                ui_name: 'संघ शिक्षण',
                backend_name: 'trainingInOrganization'
            });
        }

        const checkBooleanData = (value) => {
            let status = 'नाही';
            if (value === true || value === 'हो') {
                status = 'हो';
            } else {
                status = 'नाही';
            }
            return status;
        };

        payload = {
            ...payload,
            location_id: selectedGeo?.location_id
        };

        setFilterdData([]);
        setDisableBtn(true);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'कृपया काही क्षण प्रतीक्षा करा...',
            variant: 'alert',
            alertSeverity: 'warning'
        });

        filterReport(payload)
            .then((resp) => {
                setDisableBtn(false);
                console.log('resp', resp);
                if (resp && resp?.length > 0) {
                    // const fieldsName = Object.keys(checkedPayload);

                    const tempResp = resp?.map((item) => {
                        let dataFilter = {};
                        checkedFields?.map((itemValue) => {
                            dataFilter = {
                                ...dataFilter,
                                [itemValue.ui_name]:
                                    itemValue?.type === 'selector'
                                        ? checkBooleanData(item[itemValue.backend_name])
                                        : item[itemValue.backend_name]
                            };
                            return true;
                        });
                        dataFilter = {
                            'पहिले नांव': item?.firstName,
                            आडनांव: item?.surname,
                            ...dataFilter
                        };

                        return dataFilter;
                    });

                    console.log('tempResp', tempResp);

                    setFilterdData(tempResp);
                    exportReport(tempResp);
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: 'माहिती उपलब्ध नाही',
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
                    setFilterdData([]);
                }
            })
            .catch((error) => {
                setDisableBtn(false);
                console.log(error);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'माहिती उपलब्ध नाही. कृपया नंतर पुन्हा प्रयत्न करा.',
                    variant: 'alert',
                    alertSeverity: 'error'
                });
            });

        // const checkedPayload = {};
    };

    const clearAllFields = () => {
        setFieldList(fieldsNameData);
        setOccupation([]);
        setOccupation1([]);
        setOccupation2([]);
        setCategory([]);
        setAccount([]);
        setResponsibilityLevel([]);
        setResponsibility([]);
        setBloodGroup([]);
        setUniform(false);
        setVyavsay1Tree([]);
        setVyavsay2Tree([]);
        setKhate([]);
        setDayitv([]);
        setTrainingInOrganization([]);
        setDisableBtn(false);
    };

    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h3">
                                <FormattedMessage id="menu.report" />
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={12} md={9}>
                            <Stack direction="row" flexDirection="row-reverse" alignItems="flex-end">
                                <Button
                                    variant="outlined"
                                    color={selectedGeo ? 'primary' : 'error'}
                                    onClick={() => setGeoTreeDialogOpen(true)}
                                    sx={{ mr: '10px' }}
                                    endIcon={<LocationOnTwoToneIcon />}
                                >
                                    {selectedGeo ? `"${selectedGeo?.localized_name} ${selectedGeo?.name_suffix}"` : 'भौगोलिक रचना निवडा!'}
                                </Button>
                            </Stack>
                        </Grid> */}
                    </Grid>
                }
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center">
                            <Button
                                variant="outlined"
                                color={selectedGeo ? 'primary' : 'error'}
                                onClick={() => setGeoTreeDialogOpen(true)}
                                sx={{ mr: 3 }}
                                endIcon={<LocationOnTwoToneIcon />}
                            >
                                {selectedGeo ? `"${selectedGeo?.localized_name} ${selectedGeo?.name_suffix}"` : 'भौगोलिक रचना निवडा!'}
                            </Button>
                            <Button variant="contained" color="primary" onClick={generateReportHandle} disabled={disableBtn}>
                                अहवाल तयार करा
                            </Button>
                            <Button sx={{ ml: 1 }} variant="" color="error" onClick={clearAllFields}>
                                रद्द करा
                            </Button>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{ my: 1 }} />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked />}
                                // checked={item.value}
                                label="पहिले नांव"
                                name="पहिले नांव"
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked />}
                                // checked={item.value}
                                label="आडनांव"
                                name="आडनांव"
                            />
                        </FormGroup>
                    </Grid>

                    {fieldList?.map((item, index) =>
                        item?.category !== 'uniform' && item?.category !== 'other' ? (
                            <Grid item xs={12} sm={4} md={3}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        checked={item.value}
                                        label={item.ui_name}
                                        name={item?.ui_name}
                                        onChange={(e) => {
                                            const tempObj = [...fieldList];
                                            tempObj[index].value = e.target.checked;
                                            setFieldList(tempObj);
                                        }}
                                    />
                                </FormGroup>
                            </Grid>
                        ) : null
                    )}

                    <Grid item xs={12} sm={4} md={3}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox />}
                                checked={uniform}
                                onChange={(e) => {
                                    setUniform(e.target.checked);
                                }}
                                // checked={item.value}
                                label="गणवेश"
                                name="गणवेश"
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox />}
                                checked={other}
                                onChange={(e) => {
                                    setOther(e.target.checked);
                                }}
                                label="इतर"
                                name="इतर"
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{ my: 1 }} />
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={bloodGroupData || []}
                                value={bloodGroup}
                                disableCloseOnSelect
                                getOptionLabel={(bloodGroup) => bloodGroup}
                                onChange={(preValues, values) => {
                                    setBloodGroup([...values]);
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => <TextField {...params} label="रक्त गट निवडा" placeholder="रक्त गट निवडा" />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={sanghShikshan || []}
                                value={trainingInOrganization}
                                disableCloseOnSelect
                                getOptionLabel={(trainingInOrganization) => trainingInOrganization?.localized_name}
                                onChange={(preValues, values) => {
                                    setTrainingInOrganization([...values]);
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                        {option.localized_name}
                                    </li>
                                )}
                                renderInput={(params) => <TextField {...params} label="संघ शिक्षण निवडा" placeholder="संघ शिक्षण निवडा" />}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{ my: 1 }} />
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={vyavsayTree || []}
                                value={occupation}
                                disableCloseOnSelect
                                getOptionLabel={(occupation) => occupation?.localized_name}
                                onChange={(preValues, values) => {
                                    setOccupation([...values]);
                                    const occup1 = values?.map((vValue) => vValue.VyavasayKshetras);
                                    const merge3 = occup1.flat(1);
                                    const removeDuplicate = merge3.filter(
                                        (obj, index, self) => index === self.findIndex((o) => o?.localized_name === obj?.localized_name)
                                    );

                                    if (removeDuplicate && removeDuplicate?.length > 0) {
                                        setVyavsay1Tree([...removeDuplicate]);
                                    } else {
                                        setVyavsay1Tree([]);
                                        setVyavsay2Tree([]);
                                        setOccupation1([]);
                                        setOccupation2([]);
                                    }
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                        {option?.localized_name}
                                    </li>
                                )}
                                renderInput={(params) => <TextField {...params} label="व्यवसाय निवडा" placeholder="व्यवसाय निवडा" />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={vyavsay1Tree || []}
                                value={occupation1}
                                disableCloseOnSelect
                                getOptionLabel={(occupation1) => occupation1?.localized_name}
                                onChange={(preValues, values) => {
                                    setOccupation1([...values]);
                                    const occup1 = values?.map((vValue) => vValue.vyavasay_tapshils);
                                    const merge3 = occup1.flat(1);
                                    const removeDuplicate = merge3.filter(
                                        (obj, index, self) => index === self.findIndex((o) => o?.localized_name === obj?.localized_name)
                                    );

                                    if (removeDuplicate && removeDuplicate?.length > 0) {
                                        setVyavsay2Tree([...removeDuplicate]);
                                    } else {
                                        setVyavsay2Tree([]);
                                        setOccupation2([]);
                                    }
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                        {option?.localized_name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="व्यवसाय तपशील 1 निवडा" placeholder="व्यवसाय तपशील 1 निवडा" />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={vyavsay2Tree || []}
                                value={occupation2}
                                disableCloseOnSelect
                                getOptionLabel={(occupation2) => occupation2?.localized_name}
                                onChange={(preValues, values) => {
                                    setOccupation2([...values]);
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                        {option?.localized_name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="व्यवसाय तपशील 2 निवडा" placeholder="व्यवसाय तपशील 2 निवडा" />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ my: 1 }} />
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={shreni || []}
                                value={category}
                                disableCloseOnSelect
                                getOptionLabel={(category) => category?.localized_name}
                                onChange={(preValues, values) => {
                                    setCategory([...values]);
                                    // console.log(values);
                                    const khate = values?.map((vValue) => vValue.khateData);
                                    const merge3 = khate.flat(1);
                                    const removeDuplicate = merge3.filter(
                                        (obj, index, self) => index === self.findIndex((o) => o?.localized_name === obj?.localized_name)
                                    );
                                    // console.log('removeDuplicate', removeDuplicate);
                                    if (removeDuplicate && removeDuplicate?.length > 0) {
                                        setKhate([...removeDuplicate]);
                                    } else {
                                        setKhate([]);
                                        setAccount([]);
                                    }
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                        {option?.localized_name}
                                    </li>
                                )}
                                renderInput={(params) => <TextField {...params} label="श्रेणी निवडा" placeholder="श्रेणी निवडा" />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={khate || []}
                                value={account}
                                disableCloseOnSelect
                                getOptionLabel={(account) => account?.localized_name}
                                onChange={(preValues, values) => {
                                    setAccount([...values]);
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                        {option?.localized_name}
                                    </li>
                                )}
                                renderInput={(params) => <TextField {...params} label="खाते निवडा" placeholder="खाते निवडा" />}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{ my: 1 }} />
                    </Grid>

                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={dayitvTree || []}
                                value={responsibilityLevel}
                                disableCloseOnSelect
                                getOptionLabel={(responsibilityLevel) => responsibilityLevel?.localized_name}
                                onChange={(preValues, values) => {
                                    setResponsibilityLevel([...values]);
                                    const dayitvData = values?.map((vValue) => vValue.dayitvaData);
                                    const merge3 = dayitvData.flat(1);
                                    const removeDuplicate = merge3.filter(
                                        (obj, index, self) => index === self.findIndex((o) => o?.localized_name === obj?.localized_name)
                                    );
                                    // console.log('removeDuplicate', removeDuplicate);
                                    if (removeDuplicate && removeDuplicate?.length > 0) {
                                        setDayitv([...removeDuplicate]);
                                    } else {
                                        setDayitv([]);
                                        setResponsibility([]);
                                    }
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                        {option?.localized_name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="दायित्व स्तर निवडा" placeholder="दायित्व स्तर निवडा" />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={dayitv || []}
                                value={responsibility}
                                disableCloseOnSelect
                                getOptionLabel={(responsibility) => responsibility?.localized_name}
                                onChange={(preValues, values) => {
                                    setResponsibility([...values]);
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                        {option?.localized_name}
                                    </li>
                                )}
                                renderInput={(params) => <TextField {...params} label="दायित्व निवडा" placeholder="दायित्व निवडा" />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>

            {/* geo tree model */}
            <GeoTreeDialog
                selectedGeo={selectedGeo}
                geoTreeDialogOpen={geoTreeDialogOpen}
                handleGeoDialogClose={(event, reason) => {
                    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
                        return;
                    }
                    setGeoTreeDialogOpen(false);
                }}
                handleCancelGeoSelection={() => {
                    setSelectedGeo(undefined);
                    setGeoTreeDialogOpen(false);
                    dispatch({ type: SET_GEO_STRUCTURE, payload: { selectedGeoStructure: undefined } });
                }}
                handleGeoLevelSelection={(selectedGeoLevel) => {
                    setSelectedGeo(selectedGeoLevel);
                    dispatch({ type: SET_GEO_STRUCTURE, payload: { selectedGeoStructure: selectedGeoLevel } });
                }}
                handleConfirmGeoSelection={() => {
                    // fetchAllSuchiLocationWise();
                    setGeoTreeDialogOpen(false);
                    proceedAfterGeoSelection();
                }}
                skipLeafLevel
            />
        </>
    );
}
