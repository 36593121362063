import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconDeviceAnalytics };

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const pmpDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'pmp-default',
            title: <FormattedMessage id="pmp-default" />,
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        }
    ]
};

export default pmpDashboard;
