import React, { useEffect, useState } from 'react';
import './index.css';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { utils, writeFile } from 'xlsx';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import Loader from 'ui-component/Loader';

export default function SanghikUpkhandNagarAhawal({ ahawalResponse, isUpkhand }) {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const defaultUpakhandAhawal = {
        totalVasti: 0,
        presentVasti: 0,
        sankalpitShakha: 0,
        sankalpitMilan: 0,
        sankalpitSanghMandali: 0,
        sankalpitTotal: 0,
        presentSankalpitShakha: 0,
        presentSankalpitMilan: 0,
        presentSankalpitSanghMandali: 0,
        presentSankalpitTotal: 0,
        praudh: 0,
        tvk: 0,
        mahavidyalayin: 0,
        baal: 0,
        totalUpasthit: 0,
        shishu: 0
    };
    const [tableData, setTableData] = useState(defaultUpakhandAhawal);

    const prepareAhawalData = () => {
        const obj = {
            totalVasti: ahawalResponse?.Vasti?.total_vasti,
            presentVasti: ahawalResponse?.Vasti?.present_vasti,
            sankalpitShakha: ahawalResponse?.ekunSankalpit?.['शाखा'] || 0,
            sankalpitMilan: ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] || 0,
            sankalpitTotal: ahawalResponse?.ekunSankalpit?.['शाखा'] + ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'],
            presentSankalpitShakha: ahawalResponse?.present_Sankalpit?.['शाखा'] || 0,
            presentSankalpitMilan: ahawalResponse?.present_Sankalpit?.['साप्ताहिक मिलन'] || 0,
            presentSankalpitTotal: ahawalResponse?.present_Sankalpit?.['शाखा'] + ahawalResponse?.present_Sankalpit?.['साप्ताहिक मिलन'],
            praudh: ahawalResponse?.present_sayamsevak_upastiti?.['प्रौढ'] || 0,
            tvk: ahawalResponse?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'] || 0,
            mahavidyalayin: ahawalResponse?.present_sayamsevak_upastiti?.['महाविद्यालयीन'] || 0,
            baal: ahawalResponse?.present_sayamsevak_upastiti?.['बाल'] || 0,
            totalUpasthit: ahawalResponse?.present_sayamsevak_upastiti?.['एकूण'] || 0,
            shishu: ahawalResponse?.present_sayamsevak_upastiti?.['शिशु'] || 0
        };
        setTableData(obj);
    };
    const fetchNagarAhawalDS = () => {
        const sheetArray = [];
        const allVasti = ahawalResponse?.children_locations;
        if (allVasti === undefined) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'फाईल डाउनलोड होऊ शकली नाही..',
                variant: 'alert',
                alertSeverity: 'error'
            });
            return false;
        }
        allVasti.forEach((ele) => {
            const obj = {
                vastiName: ele.location_name,
                sankalpit_shakha_praudh: ele?.sankalp_total?.['शाखा']?.sub_types?.['प्रौढ'] || 0,
                sankalpit_shakha_tvk: ele?.sankalp_total?.['शाखा']?.sub_types?.['तरुण व्यवसायी'] || 0,
                sankalpit_shakha_mahavidyalayin: ele?.sankalp_total?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
                sankalpit_shakha_sanyuktaVidyarthi: ele?.sankalp_total?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
                sankalpit_milan_praudh: ele?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'] || 0,
                sankalpit_milan_tvk: ele?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'] || 0,
                sankalpit_milan_mahavidyalayin: ele?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
                sankalpit_milan_sanyuktaVidyarthi: ele?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
                sankalpit_sanghMandali: ele?.sankalp_total?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'] || 0,

                upasthit_shakha_praudh: ele?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['प्रौढ'] || 0,
                upasthit_shakha_tvk: ele?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['तरुण व्यवसायी'] || 0,
                upasthit_shakha_mahavidyalayin: ele?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
                upasthit_shakha_sanyuktaVidyarthi: ele?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
                upasthit_milan_praudh: ele?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'] || 0,
                upasthit_milan_tvk: ele?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'] || 0,
                upasthit_milan_mahavidyalayin:
                    ele?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
                upasthit_milan_sanyuktaVidyarthi:
                    ele?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
                upasthit_sanghMandali: ele?.present_Sankalpit_age_wise?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'] || 0,

                upasthit_praudh: ele?.present_sayamsevak_upastiti?.['प्रौढ'],
                upasthit_tvk: ele?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'],
                upasthit_mahavidyalayin: ele?.present_sayamsevak_upastiti?.['महाविद्यालयीन'],
                upasthit_baal: ele?.present_sayamsevak_upastiti?.['बाल'],
                upasthit_ekun: ele?.present_sayamsevak_upastiti?.['एकूण'],
                upasthit_shishu: ele?.present_sayamsevak_upastiti?.['शिशु']
            };
            obj.totalSankalpitEkake =
                +obj.sankalpit_shakha_praudh +
                +obj.sankalpit_shakha_tvk +
                +obj.sankalpit_shakha_mahavidyalayin +
                +obj.sankalpit_shakha_sanyuktaVidyarthi +
                +obj.sankalpit_milan_praudh +
                +obj.sankalpit_milan_tvk +
                +obj.sankalpit_milan_mahavidyalayin +
                +obj.sankalpit_milan_sanyuktaVidyarthi +
                +obj.sankalpit_sanghMandali;
            obj.totalUpasthitEkake =
                +obj.upasthit_shakha_praudh +
                +obj.upasthit_shakha_tvk +
                +obj.upasthit_shakha_mahavidyalayin +
                +obj.upasthit_shakha_sanyuktaVidyarthi +
                +obj.upasthit_milan_praudh +
                +obj.upasthit_milan_tvk +
                +obj.upasthit_milan_mahavidyalayin +
                +obj.upasthit_milan_sanyuktaVidyarthi +
                +obj.upasthit_sanghMandali;
            sheetArray.push([
                obj.vastiName,
                obj.sankalpit_shakha_praudh,
                obj.sankalpit_shakha_tvk,
                obj.sankalpit_shakha_mahavidyalayin,
                obj.sankalpit_shakha_sanyuktaVidyarthi,
                obj.sankalpit_milan_praudh,
                obj.sankalpit_milan_tvk,
                obj.sankalpit_milan_mahavidyalayin,
                obj.sankalpit_milan_sanyuktaVidyarthi,
                obj.sankalpit_sanghMandali,
                obj.totalSankalpitEkake,
                obj.upasthit_shakha_praudh,
                obj.upasthit_shakha_tvk,
                obj.upasthit_shakha_mahavidyalayin,
                obj.upasthit_shakha_sanyuktaVidyarthi,
                obj.upasthit_milan_praudh,
                obj.upasthit_milan_tvk,
                obj.upasthit_milan_mahavidyalayin,
                obj.upasthit_milan_sanyuktaVidyarthi,
                obj.upasthit_sanghMandali,
                obj.totalUpasthitEkake,
                obj.upasthit_praudh,
                obj.upasthit_tvk,
                obj.upasthit_mahavidyalayin,
                obj.upasthit_baal,
                obj.upasthit_ekun,
                obj.upasthit_shishu
            ]);
        });
        const ekunSankalpit =
            +ahawalResponse?.ekunSankalpit?.['शाखा'] +
            +ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] +
            +ahawalResponse?.ekunSankalpit?.['संघ मंडळी'];
        const ekunUpasthitEkake =
            +ahawalResponse?.Total_present?.['प्रौढ'] +
            +ahawalResponse?.Total_present?.['तरुण व्यवसायी'] +
            +ahawalResponse?.Total_present?.['महाविद्यालयीन विद्यार्थी'] +
            +ahawalResponse?.Total_present?.['संयुक्त विद्यार्थी'] +
            +ahawalResponse?.Total_present?.['संघ मंडळी'];
        sheetArray.push([
            'एकूण',
            ahawalResponse?.sankalp_total?.['शाखा']?.praudh,
            ahawalResponse?.sankalp_total?.['शाखा']?.tvk,
            ahawalResponse?.sankalp_total?.['शाखा']?.maha_vidyarthi,
            ahawalResponse?.sankalp_total?.['शाखा']?.baal,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.praudh,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.tvk,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.maha_vidyarthi,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.baal,
            ahawalResponse?.sankalp_total?.['संघ मंडळी'],
            ekunSankalpit,
            ahawalResponse?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['प्रौढ'],
            ahawalResponse?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['तरुण व्यवसायी'],
            ahawalResponse?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'],
            ahawalResponse?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'],
            ahawalResponse?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'],
            ahawalResponse?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'],
            ahawalResponse?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'],
            ahawalResponse?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'],
            ahawalResponse?.present_Sankalpit_age_wise?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'],
            ekunUpasthitEkake,
            ahawalResponse?.present_sayamsevak_upastiti?.['प्रौढ'],
            ahawalResponse?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'],
            ahawalResponse?.present_sayamsevak_upastiti?.['महाविद्यालयीन'],
            ahawalResponse?.present_sayamsevak_upastiti?.['बाल'],
            ahawalResponse?.present_sayamsevak_upastiti?.['एकूण'],
            ahawalResponse?.present_sayamsevak_upastiti?.['शिशु']
        ]);
        sheetArray.unshift([
            '',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'संघमंडळी',
            'एकूण एकके',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'संघमंडळी',
            'एकूण एकके',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'बाल',
            'एकूण',
            'शिशु'
        ]);
        sheetArray.unshift(['', '', '', 'शाखा', '', '', '', 'सा. मिलन', '', '', '', '', '', 'शाखा', '', '', '', 'सा. मिलन']);
        sheetArray.unshift([
            'वस्ती',
            '',
            '',
            '',
            '',
            '',
            'एकूण संकल्पित एकके',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'एकूण उपस्थित एकके',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'उपस्थिती (स्वयंसेवक)'
        ]);
        return sheetArray;
    };
    const generateExcelFile = () => {
        setLoading(true);
        const wb = utils.book_new();
        if (!fetchNagarAhawalDS()) {
            setLoading(false);
            return;
        }
        const ws1 = utils.json_to_sheet(fetchNagarAhawalDS());
        utils.book_append_sheet(wb, ws1, 'अहवाल');

        writeFile(wb, `${ahawalResponse?.location_name || ''} ${ahawalResponse?.name_suffix || ''} अहवाल.xlsx`);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'यशस्वीरितीने फाईल डाउनलोड झालेली आहे!',
            variant: 'alert',
            alertSeverity: 'success'
        });
        setLoading(false);
    };
    useEffect(() => {
        if ((ahawalResponse && ahawalResponse.length > 0) || Object.keys(ahawalResponse).length > 0) {
            prepareAhawalData();
        }
    }, [ahawalResponse]);
    return (
        <>
            {loading && <Loader />}
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography variant="h3">{`${ahawalResponse?.location_name || ''} ${ahawalResponse?.name_suffix || ''} अहवाल`}</Typography>
                <Button variant="contained" color="success" sx={{ m: '10px' }} onClick={generateExcelFile}>
                    <FormattedMessage id="menu.download.ahawal" />
                </Button>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell" align="center" colSpan={2}>
                                वस्ती संख्या
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={3}>
                                एकूण संकल्पित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={3}>
                                एकूण उपस्थित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={6}>
                                उपस्थिती (स्वयंसेवक)
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="tableCell" align="center">
                                एकूण वस्त्या
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                उपस्थित वस्त्या
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा. मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा. मिलन
                            </TableCell>

                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                प्रौढ
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                तरुण व्यवसायी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                महाविद्यालयीन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                बाल
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शिशु
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className="tableCell" align="center">
                                {tableData.totalVasti || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentVasti || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.sankalpitShakha || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.sankalpitMilan || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.sankalpitTotal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentSankalpitShakha || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentSankalpitMilan || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentSankalpitTotal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.praudh || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.tvk || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.mahavidyalayin || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.baal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.totalUpasthit || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.shishu || 0}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
