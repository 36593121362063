import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { utils, writeFile } from 'xlsx';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import Loader from 'ui-component/Loader';

function SanghikGraminJilhaAhawal({ ahawalResponse }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const fetchJilhaAhawalDS = () => {
        const sheetArray = [];
        const allTalukas = ahawalResponse?.talukas;
        allTalukas.forEach((ele) => {
            const obj = {
                taluka: ele.location_name,
                totalUpkhandNagar: ele.Total_upkhand_nagar,
                presentUpkhandNagar: ele.present_upkhand_nagar,
                totalUpkhand: ele.Total_upkhand,
                presentUpkhand: ele.present_upkhand,
                sankalpit_shakha_praudh: ele?.sankalp_total?.['शाखा']?.praudh || 0,
                sankalpit_shakha_tvk: ele?.sankalp_total?.['शाखा']?.tvk || 0,
                sankalpit_shakha_mahavidyalayin: ele?.sankalp_total?.['शाखा']?.maha_vidyarthi || 0,
                sankalpit_shakha_sanyuktaVidyarthi: ele?.sankalp_total?.['शाखा']?.baal || 0,

                sankalpit_milan_praudh: ele?.sankalp_total?.['साप्ताहिक मिलन']?.praudh || 0,
                sankalpit_milan_tvk: ele?.sankalp_total?.['साप्ताहिक मिलन']?.tvk || 0,
                sankalpit_milan_mahavidyalayin: ele?.sankalp_total?.['साप्ताहिक मिलन']?.maha_vidyarthi || 0,
                sankalpit_milan_sanyuktaVidyarthi: ele?.sankalp_total?.['साप्ताहिक मिलन']?.baal || 0,
                sankalpit_sanghMandali: ele?.sankalp_total?.['संघ मंडळी'] || 0,

                upasthit_shakha_praudh: ele?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['प्रौढ'] || 0,
                upasthit_shakha_tvk: ele?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['तरुण व्यवसायी'] || 0,
                upasthit_shakha_mahavidyalayin: ele?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
                upasthit_shakha_sanyuktaVidyarthi: ele?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
                upasthit_milan_praudh: ele?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'] || 0,
                upasthit_milan_tvk: ele?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'] || 0,
                upasthit_milan_mahavidyalayin:
                    ele?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
                upasthit_milan_sanyuktaVidyarthi:
                    ele?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
                upasthit_sanghMandali: ele?.present_Sankalpit_age_wise?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'] || 0,

                upasthit_praudh: ele?.present_sayamsevak_upastiti?.['प्रौढ'],
                upasthit_tvk: ele?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'],
                upasthit_mahavidyalayin: ele?.present_sayamsevak_upastiti?.['महाविद्यालयीन'],
                upasthit_baal: ele?.present_sayamsevak_upastiti?.['बाल'],
                upasthit_ekun: ele?.present_sayamsevak_upastiti?.['एकूण'],
                upasthit_shishu: ele?.present_sayamsevak_upastiti?.['शिशु']
            };
            obj.totalSankalpitEkake =
                +obj.sankalpit_shakha_praudh +
                +obj.sankalpit_shakha_tvk +
                +obj.sankalpit_shakha_mahavidyalayin +
                +obj.sankalpit_shakha_sanyuktaVidyarthi +
                +obj.sankalpit_milan_praudh +
                +obj.sankalpit_milan_tvk +
                +obj.sankalpit_milan_mahavidyalayin +
                +obj.sankalpit_milan_sanyuktaVidyarthi +
                +obj.sankalpit_sanghMandali;
            obj.totalUpasthitEkake =
                +obj.upasthit_shakha_praudh +
                +obj.upasthit_shakha_tvk +
                +obj.upasthit_shakha_mahavidyalayin +
                +obj.upasthit_shakha_sanyuktaVidyarthi +
                +obj.upasthit_milan_praudh +
                +obj.upasthit_milan_tvk +
                +obj.upasthit_milan_mahavidyalayin +
                +obj.upasthit_milan_sanyuktaVidyarthi +
                +obj.upasthit_sanghMandali;
            sheetArray.push([
                obj.nagarName,
                obj.totalUpkhandNagar,
                obj.presentUpkhandNagar,
                obj.totalUpkhand,
                obj.presentUpkhand,
                obj.presentVasti,
                obj.sankalpit_shakha_praudh,
                obj.sankalpit_shakha_tvk,
                obj.sankalpit_shakha_mahavidyalayin,
                obj.sankalpit_shakha_sanyuktaVidyarthi,
                obj.sankalpit_milan_praudh,
                obj.sankalpit_milan_tvk,
                obj.sankalpit_milan_mahavidyalayin,
                obj.sankalpit_milan_sanyuktaVidyarthi,
                obj.sankalpit_sanghMandali,
                obj.totalSankalpitEkake,
                obj.upasthit_shakha_praudh,
                obj.upasthit_shakha_tvk,
                obj.upasthit_shakha_mahavidyalayin,
                obj.upasthit_shakha_sanyuktaVidyarthi,
                obj.upasthit_milan_praudh,
                obj.upasthit_milan_tvk,
                obj.upasthit_milan_mahavidyalayin,
                obj.upasthit_milan_sanyuktaVidyarthi,
                obj.upasthit_sanghMandali,
                obj.totalUpasthitEkake,
                obj.upasthit_praudh,
                obj.upasthit_tvk,
                obj.upasthit_mahavidyalayin,
                obj.upasthit_baal,
                obj.upasthit_ekun,
                obj.upasthit_shishu
            ]);
        });
        const ekunSankalpit =
            +ahawalResponse?.ekunSankalpit?.['शाखा'] +
            +ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] +
            +ahawalResponse?.ekunSankalpit?.['संघ मंडळी'];
        const ekunUpasthitEkake =
            +ahawalResponse?.total_present?.['प्रौढ'] +
            +ahawalResponse?.total_present?.['तरुण व्यवसायी'] +
            +ahawalResponse?.total_present?.['महाविद्यालयीन विद्यार्थी'] +
            +ahawalResponse?.total_present?.['संयुक्त विद्यार्थी'] +
            +ahawalResponse?.total_present?.['संघ मंडळी'];
        sheetArray.push([
            'एकूण',
            '',
            '',
            '',
            '',
            ahawalResponse?.sankalp_total?.['शाखा']?.praudh,
            ahawalResponse?.sankalp_total?.['शाखा']?.tvk,
            ahawalResponse?.sankalp_total?.['शाखा']?.maha_vidyarthi,
            ahawalResponse?.sankalp_total?.['शाखा']?.baal,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.praudh,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.tvk,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.maha_vidyarthi,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.baal,
            ahawalResponse?.sankalp_total?.['संघ मंडळी'],
            ekunSankalpit,
            ahawalResponse?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['प्रौढ'],
            ahawalResponse?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['तरुण व्यवसायी'],
            ahawalResponse?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'],
            ahawalResponse?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'],
            ahawalResponse?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'],
            ahawalResponse?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'],
            ahawalResponse?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'],
            ahawalResponse?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'],
            ahawalResponse?.present_sankalpit_age_wise?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'],
            ekunUpasthitEkake,
            ahawalResponse?.present_sayamsevak_upastiti?.['प्रौढ'],
            ahawalResponse?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'],
            ahawalResponse?.present_sayamsevak_upastiti?.['महाविद्यालयीन'],
            ahawalResponse?.present_sayamsevak_upastiti?.['बाल'],
            ahawalResponse?.present_sayamsevak_upastiti?.['एकूण'],
            ahawalResponse?.present_sayamsevak_upastiti?.['शिशु']
        ]);
        sheetArray.unshift([
            '',
            'एकूण',
            'सांघिक झालेली',
            'एकूण',
            'सांघिक झालेली',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'संघमंडळी',
            'एकूण एकके',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'संघमंडळी',
            'एकूण एकके',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'बाल',
            'एकूण',
            'शिशु'
        ]);
        sheetArray.unshift([
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'शाखा',
            '',
            '',
            '',
            'सा. मिलन',
            '',
            '',
            '',
            '',
            '',
            'शाखा',
            '',
            '',
            '',
            'सा. मिलन'
        ]);
        sheetArray.unshift([
            'तालुके',
            'उपखंड नगर',
            '',
            'उपखंड',
            '',
            '',
            '',
            '',
            '',
            'एकूण संकल्पित एकके',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'एकूण उपस्थित एकके',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'उपस्थिती (स्वयंसेवक)'
        ]);
        return sheetArray;
    };
    const generateExcelFile = () => {
        setLoading(true);
        const wb = utils.book_new();
        const ws1 = utils.json_to_sheet(fetchJilhaAhawalDS());
        utils.book_append_sheet(wb, ws1, 'अहवाल');

        writeFile(wb, `${ahawalResponse?.location_name || ''} ${ahawalResponse?.name_suffix || ''} अहवाल.xlsx`);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'यशस्वीरितीने फाईल डाउनलोड झालेली आहे!',
            variant: 'alert',
            alertSeverity: 'success'
        });
        setLoading(false);
    };
    const renderSecondReport = () => {
        const shakhaSankalpTotal =
            ahawalResponse?.ekunSankalpit?.['शाखा'] +
            ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] +
            ahawalResponse?.ekunSankalpit?.['संघ मंडळी'];
        const presentSankalpTotal =
            ahawalResponse?.present_Sankalpit?.['शाखा'] +
            ahawalResponse?.present_Sankalpit?.['साप्ताहिक मिलन'] +
            ahawalResponse?.present_Sankalpit?.['संघ मंडळी'];

        return (
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell" align="center" colSpan={2}>
                                तालुके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={4}>
                                एकूण संकल्पित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={4}>
                                एकूण उपस्थित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={6}>
                                उपस्थिती (स्वयंसेवक)
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="tableCell" align="center">
                                एकूण तालुके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सांघिक झालेले तालुके
                            </TableCell>

                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा.मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                संघमंडळी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा.मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                संघमंडळी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                प्रौढ
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                तरुण व्यवसायी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                महाविद्यालयीन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                बाल
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शिशु
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse.Total_taluka || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse.present_taluka || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.ekunSankalpit?.['शाखा'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.ekunSankalpit?.['संघ मंडळी'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {shakhaSankalpTotal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_Sankalpit?.['शाखा'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_Sankalpit?.['साप्ताहिक मिलन'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_Sankalpit?.['संघ मंडळी'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {presentSankalpTotal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['प्रौढ'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['महाविद्यालयीन'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['बाल'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['एकूण'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['शिशु'] || 0}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderFirstReport = () => {
        const tableRows = [];
        ahawalResponse?.talukas.forEach((r) => {
            const shakhaSankalpTotal = r?.ekunSankalpit?.['शाखा'] + r?.ekunSankalpit?.['साप्ताहिक मिलन'] + r?.ekunSankalpit?.['संघ मंडळी'];
            const presentSankalpTotal =
                r?.present_Sankalpit?.['शाखा'] + r?.present_Sankalpit?.['साप्ताहिक मिलन'] + r?.present_Sankalpit?.['संघ मंडळी'];

            tableRows.push(
                <TableRow>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r.location_name || '-'}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r.Total_upkhand_nagar || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r.present_upkhand_nagar || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r.Total_upkhand || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r.present_upkhand || 0}
                    </TableCell>

                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.ekunSankalpit?.['शाखा'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.ekunSankalpit?.['साप्ताहिक मिलन'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.ekunSankalpit?.['संघ मंडळी'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {shakhaSankalpTotal || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_Sankalpit?.['शाखा'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_Sankalpit?.['साप्ताहिक मिलन'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_Sankalpit?.['संघ मंडळी'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {presentSankalpTotal || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['प्रौढ'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['महाविद्यालयीन'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['बाल'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['एकूण'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['शिशु'] || 0}
                    </TableCell>
                </TableRow>
            );
        });
        return (
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                तालुके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={2}>
                                उपखंड नगर
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={2}>
                                उपखंड
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={4}>
                                एकूण संकल्पित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={4}>
                                एकूण उपस्थित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={6}>
                                उपस्थिती (स्वयंसेवक)
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="tableCell" align="center" />
                            <TableCell className="tableCell" align="center">
                                एकूण उपखंड नगरे
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सांघिक झालेली उपखंड नगरे
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण उपखंड
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सांघिक झालेली उपखंड
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा. मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                संघमंडळी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा. मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                संघमंडळी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                प्रौढ
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                तरुण व्यवसायी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                महाविद्यालयीन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                बाल
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शिशु
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{tableRows}</TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <>
            {loading && <Loader />}
            <div style={{ margin: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Typography variant="h3">{`${ahawalResponse?.location_name || ''} ${
                        ahawalResponse?.name_suffix || ''
                    } अहवाल`}</Typography>
                    <Button variant="contained" color="success" sx={{ m: '10px' }} onClick={generateExcelFile}>
                        <FormattedMessage id="menu.download.ahawal" />
                    </Button>
                </div>
                {renderFirstReport()}
            </div>
            <div style={{ margin: '10px' }}>{renderSecondReport()}</div>
        </>
    );
}

export default SanghikGraminJilhaAhawal;
