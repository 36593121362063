import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, AlertTitle, Box, Button, Grid, IconButton, Stack, Tooltip, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import MainCard from 'ui-component/cards/MainCard';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import {
    addSwayamSevak,
    deleteSwayamSevak,
    getAllDayitvTree,
    getAllListLocationWise,
    getAllSanghShikshan,
    getAllShreniAndKhateTree,
    getAllVyavsayTree
} from 'services/swayamsevak-service';
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone';
import GeoTreeDialog from 'ui-component/pmp-components/GeoTreeDialog';
import { useDispatch, useSelector } from 'react-redux';
import { SET_GEO_STRUCTURE, SNACKBAR_OPEN } from 'store/actions';
import DownloadForOfflineTwoToneIcon from '@material-ui/icons/DownloadForOfflineTwoTone';
// import { vyavsayTreeData } from './vyavsayTempData';
import { utils, writeFileXLSX } from 'xlsx';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import EditSuchiDialog from './EditSuchiDialog';
import MUIDataTable from 'mui-datatables';
import HistoryIcon from '@material-ui/icons/History';
import useAuth from 'hooks/useAuth';
import { AddTwoTone } from '@material-ui/icons';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default function SwayamSuchakList() {
    // ------------------states and variables---------------------------
    const selectedgeoStructure = useSelector((state) => state?.centrallySelectedgeoStructure?.selectedGeoStructure);

    const [rawData, setRawData] = useState([]);
    const [loaderUploadFile, setLoaderUploadFile] = useState(false);
    const [errorListData, setErrorListData] = useState([]);
    const [selecteFileName, setSelecteFileName] = useState('');
    const [geoTreeDialogOpen, setGeoTreeDialogOpen] = useState(false);
    const [selectedGeo, setSelectedGeo] = useState(selectedgeoStructure);
    const [vyavsayTree, setVyavsayTree] = useState([]);
    // const [shreni, setShreni] = useState([]);
    // const [khate, setKhate] = useState([]);
    // const [dayitwStar, setDayitwStar] = useState([]);
    // const [dayitw, setDayitw] = useState([]);
    const [shreniAndDayitv, setShreniAndDayitv] = useState([]);
    const [dayitvTree, setDayitvTree] = useState([]);

    const [btnDisable, setBtnDisable] = useState(false);
    const [payload, setPayload] = useState({
        karyalay_pramukh: true
    });
    const bloodGroupData = ['A +', 'B +', 'AB +', 'O +', 'A -', 'B -', 'AB -', 'O -'];
    const dispatch = useDispatch();
    const [editSuchiModel, setEditSuchiModel] = useState(false);
    const [selectedSuchiRow, setSelectedSuchiRow] = useState({});
    const [locationWiseList, setLocationWiseList] = useState([]);
    const [locationWiseListLoader, setLocationWiseListLoader] = useState(false);
    const [sanghShikshan, setSanghShikshan] = useState([]);
    const [disableAllFields, setDisableAllFields] = useState(true);

    const uploadBox = {
        // borderColor: '#000',
        // color: '#000',

        borderColor: 'rgb(0 0 0)',
        color: 'rgb(0 0 0)',
        flex: '1 1 0%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderStyle: 'dashed',
        backgroundColor: 'rgb(250, 250, 250)',
        outline: 'none',
        transition: 'border 0.24s ease-in-out 0s'
    };

    const xlsvDateFormat = (serial) => {
        const utcDays = Math.floor(serial - 25569);
        const utcValue = utcDays * 86400;
        const dateInfo = new Date(utcValue * 1000);

        const fractionalDay = serial - Math.floor(serial) + 0.0000001;

        // let totalSeconds = Math.floor(86400 * fractionalDay);

        // const seconds = totalSeconds % 60;

        // totalSeconds -= seconds;

        // const hours = Math.floor(totalSeconds / (60 * 60));
        // const minutes = Math.floor(totalSeconds / 60) % 60;

        return new Date(dateInfo.getFullYear(), dateInfo.getMonth(), dateInfo.getDate());
        // return 'hello';
    };

    const validateData = (data) => {
        let status = true;
        const errorList = [];
        setErrorListData([]);

        if (data && data?.length > 0) {
            // const emailIdData = rawData?.map((value) => value['इ-मेल आयडी']?.trim());
            // console.log('emailIdData', emailIdData);
            data?.map((item, index) => {
                const rowNumber = index + 2;
                if (_.isEmpty(item['पहिले नांव']?.trim()) || typeof item['पहिले नांव'] === 'undefined') {
                    status = false;
                    errorList.push({
                        errorIn: 'पहिले नांव',
                        errorType: 'mandatory',
                        message: '"पहिले नांव" आवश्यक आहे',
                        row: rowNumber
                    });
                }
                if (_.isEmpty(item['आडनांव']?.trim()) || typeof item['आडनांव'] === 'undefined') {
                    status = false;
                    errorList.push({
                        errorIn: 'आडनांव',
                        errorType: 'mandatory',
                        message: '"आडनांव" आवश्यक आहे',
                        row: rowNumber
                    });
                }

                if (!_.isEmpty(item['दूरभाष']?.toString()?.trim()) && !_.isNumber(item['दूरभाष'])) {
                    status = false;
                    errorList.push({
                        errorIn: 'दूरभाष',
                        errorType: 'number',
                        message: '"दूरभाष" संख्या असणे आवश्यक आहे',
                        row: rowNumber
                    });
                }

                if (_.isEmpty(item['भ्रमणभाष']?.toString()?.trim()) || typeof item['भ्रमणभाष'] === 'undefined') {
                    status = false;
                    errorList.push({
                        errorIn: 'भ्रमणभाष',
                        errorType: 'mandatory',
                        message: '"भ्रमणभाष" आवश्यक आहे',
                        row: rowNumber
                    });
                }

                if (!_.isNumber(item['भ्रमणभाष'])) {
                    status = false;
                    errorList.push({
                        errorIn: 'भ्रमणभाष',
                        errorType: 'number',
                        message: '"भ्रमणभाष" संख्या असणे आवश्यक आहे',
                        row: rowNumber
                    });
                }

                if (_.isNumber(item['भ्रमणभाष']) && item['भ्रमणभाष']?.toString()?.length !== 10) {
                    status = false;
                    errorList.push({
                        errorIn: 'भ्रमणभाष',
                        errorType: 'number length',
                        message: '"भ्रमणभाष" संख्या १० अंकी असणे आवश्यक आहे',
                        row: rowNumber
                    });
                }

                if (_.isEmpty(item['इ-मेल आयडी']?.trim()) || typeof item['इ-मेल आयडी'] === 'undefined') {
                    status = false;
                    errorList.push({
                        errorIn: 'इ-मेल आयडी',
                        errorType: 'mandatory',
                        message: '"इ-मेल आयडी" आवश्यक आहे',
                        row: rowNumber
                    });
                }

                // const duplicateEmail = data?.filter((fvalue) => fvalue['इ-मेल आयडी']?.trim() === item['इ-मेल आयडी']?.trim());
                // if (duplicateEmail?.length > 1) {
                //     status = false;
                //     errorList.push({
                //         errorIn: 'इ-मेल आयडी',
                //         errorType: 'duplicate',
                //         message: 'इ-मेल आयडी वेगळी असावी',
                //         row: rowNumber
                //     });
                // }

                if (_.isEmpty(item['पिन कोड']?.toString()?.trim()) || typeof item['पिन कोड'] === 'undefined') {
                    status = false;
                    errorList.push({
                        errorIn: 'पिन कोड',
                        errorType: 'mandatory',
                        message: '"पिन कोड" आवश्यक आहे',
                        row: rowNumber
                    });
                }

                if (!_.isNumber(item['पिन कोड'])) {
                    status = false;
                    errorList.push({
                        errorIn: 'पिन कोड',
                        errorType: 'number',
                        message: '"पिन कोड" संख्या असणे आवश्यक आहे',
                        row: rowNumber
                    });
                }

                if (_.isNumber(item['पिन कोड']) && item['पिन कोड']?.toString()?.length !== 6) {
                    status = false;
                    errorList.push({
                        errorIn: 'पिन कोड',
                        errorType: 'number length',
                        message: '"पिन कोड" संख्या 6 अंकी असणे आवश्यक आहे',
                        row: rowNumber
                    });
                }
                if (item['जन्म तारीख\n(DD-MM-YYYY)'] && typeof item['जन्म तारीख\n(DD-MM-YYYY)'] !== 'number') {
                    const dob = item['जन्म तारीख\n(DD-MM-YYYY)'].replaceAll('/[^ws]/gi', '-');
                    // const dob = item['जन्म तारीख\n(DD-MM-YYYY)'];

                    const parts = dob.split('-');
                    const day = parts[0];
                    const month = parts[1];
                    const year = parts[2];

                    const formattedDate = `${month}-${day}-${year}`;
                    const currentDate = new Date();

                    const userDate = new Date(formattedDate);
                    // const userDate = xlsvDateFormat(item['जन्म तारीख\n(DDMMYYYY)']);
                    // console.log('dob...........', item['जन्म तारीख\n(DD-MM-YYYY)']);
                    // const currentDate = new Date();
                    if (userDate >= currentDate) {
                        errorList.push({
                            errorIn: 'जन्म तारीख\n(DD-MM-YYYY)',
                            errorType: 'invalid dob',
                            message: 'कृपया वैध "जन्म तारीख" निवडा',
                            row: rowNumber
                        });
                        // console.log(userDate.toLocaleDateString());
                    }
                    if (JSON.stringify(userDate) === 'null' || JSON.stringify(userDate) === null) {
                        errorList.push({
                            errorIn: 'जन्म तारीख\n(DD-MM-YYYY)',
                            errorType: 'invalid dob',
                            message: 'कृपया वैध "जन्म तारीख" निवडा',
                            row: rowNumber
                        });
                        // console.log(userDate.toLocaleDateString());
                    }
                } else {
                    errorList.push({
                        errorIn: 'जन्म तारीख\n(DD-MM-YYYY)',
                        errorType: 'invalid dob',
                        message: 'कृपया वैध "जन्म तारीख" निवडा',
                        row: rowNumber
                    });
                }

                // console.log(xlsvDateFormat(item['जन्म तारीख\n(DDMMYYYY)']).toISOString('dd/MM/yyyy'));
                // console.log(xlsvDateFormat(item['जन्म तारीख\n(DDMMYYYY)']).toLocaleDateString());

                // if (_.isEmpty(item['रक्त गट']?.toString()?.trim()) || typeof item['रक्त गट'] === 'undefined') {
                //     status = false;
                //     errorList.push({
                //         errorIn: 'रक्त गट',
                //         errorType: 'mandatory',
                //         message: 'रक्त गट आवश्यक आहे',
                //         row: rowNumber
                //     });
                // }

                // occupation validation
                const occupation = vyavsayTree?.find((value) => value.localized_name?.trim() === item['व्यवसाय']?.trim());
                // console.log('v', item['व्यवसाय']);
                // console.log('occupation', occupation);
                if (item['व्यवसाय'] && occupation) {
                    const occupation2 = occupation?.VyavasayKshetras?.find((value) => value.localized_name === item['व्यवसाय तपशील 1']);
                    // console.log('occupation2', occupation2);
                    // if (occupation?.VyavasayKshetras && (occupation?.VyavasayKshetras.length > 0) & item['व्यवसाय तपशील 1']) {
                    //     errorList.push({
                    //         errorIn: 'व्यवसाय तपशील 1',
                    //         errorType: 'invalid input',
                    //         message: 'व्यवसाय तपशील 1 चुकीची निवड केलीय   ',
                    //         row: rowNumber
                    //     });
                    // }
                    if (occupation?.VyavasayKshetras && occupation?.VyavasayKshetras.length > 0 && item['व्यवसाय तपशील 1']) {
                        if (occupation2) {
                            const occupation3 = occupation2?.vyavasay_tapshils?.find(
                                (value) => value.localized_name === item['व्यवसाय तपशील 2']
                            );

                            if (occupation2?.vyavasay_tapshils && occupation2?.vyavasay_tapshils.length > 0 && item['व्यवसाय तपशील 2']) {
                                if (item['व्यवसाय तपशील 2']) {
                                    if (!occupation3) {
                                        // console.log('v3', item['व्यवसाय तपशील 2']);
                                        // console.log('wrong occupation 3');
                                        errorList.push({
                                            errorIn: 'व्यवसाय तपशील 1',
                                            errorType: 'invalid input',
                                            message: '"व्यवसाय तपशील 2" चुकीची निवड केलीय   ',
                                            row: rowNumber
                                        });
                                    }
                                }
                                // console.log('occupation3', occupation3);
                            } else if (
                                occupation2?.vyavasay_tapshils &&
                                occupation2?.vyavasay_tapshils.length <= 0 &&
                                item['व्यवसाय तपशील 2']
                            ) {
                                errorList.push({
                                    errorIn: 'व्यवसाय तपशील 2',
                                    errorType: 'invalid input',
                                    message: '"व्यवसाय तपशील 2" चुकीची निवड केलीय   ',
                                    row: rowNumber
                                });
                            } else if (
                                occupation2?.vyavasay_tapshils &&
                                occupation2?.vyavasay_tapshils.length > 0 &&
                                !item['व्यवसाय तपशील 2']
                            ) {
                                errorList.push({
                                    errorIn: 'व्यवसाय तपशील 2',
                                    errorType: 'invalid input',
                                    message: '"व्यवसाय तपशील 2" निवडणे आवश्यक आहे',
                                    row: rowNumber
                                });
                            }
                        } else {
                            // console.log('v2', item['व्यवसाय तपशील 1']);
                            // console.log('wrong occupation 2');
                            errorList.push({
                                errorIn: 'व्यवसाय तपशील 1',
                                errorType: 'invalid input',
                                message: '"व्यवसाय तपशील 1" चुकीची निवड केलीय   ',
                                row: rowNumber
                            });
                        }
                    } else if (occupation?.VyavasayKshetras && occupation?.VyavasayKshetras.length <= 0 && item['व्यवसाय तपशील 1']) {
                        errorList.push({
                            errorIn: 'व्यवसाय तपशील 1',
                            errorType: 'invalid input',
                            message: '"व्यवसाय तपशील 1" चुकीची निवड केलीय   ',
                            row: rowNumber
                        });
                    } else if (occupation?.VyavasayKshetras && occupation?.VyavasayKshetras.length > 0 && !item['व्यवसाय तपशील 1']) {
                        errorList.push({
                            errorIn: 'व्यवसाय तपशील 1',
                            errorType: 'invalid input',
                            message: '"व्यवसाय तपशील 1" निवडणे आवश्यक आहे',
                            row: rowNumber
                        });
                    }
                } else if (item['व्यवसाय'] && !occupation) {
                    errorList.push({
                        errorIn: 'व्यवसाय',
                        errorType: 'invalid input',
                        message: '"व्यवसाय" चुकीची निवड केलीय   ',
                        row: rowNumber
                    });
                }

                // shreni and dayitv validation
                const shreniData = shreniAndDayitv?.find((value) => value.localized_name?.trim() === item['श्रेणी']?.trim());
                if (item['श्रेणी'] && shreniData) {
                    // console.log('shreniData............', shreniData);

                    const khateData = shreniData?.khateData?.find((value) => value.localized_name?.trim() === item['खाते']?.trim());
                    if (shreniData?.khateData && shreniData?.khateData.length > 0 && item['खाते']) {
                        if (item['खाते']) {
                            if (!khateData) {
                                errorList.push({
                                    errorIn: 'खाते',
                                    errorType: 'invalid input',
                                    message: '"खाते" चुकीची निवड केलीय   ',
                                    row: rowNumber
                                });
                            }
                        }
                        // console.log('occupation3', occupation3);
                    } else if (shreniData?.khateData && shreniData?.khateData.length <= 0 && item['खाते']) {
                        errorList.push({
                            errorIn: 'खाते',
                            errorType: 'invalid input',
                            message: '"खाते" चुकीची निवड केलीय   ',
                            row: rowNumber
                        });
                    } else if (shreniData?.khateData && shreniData?.khateData.length > 0 && !item['खाते']) {
                        errorList.push({
                            errorIn: 'खाते',
                            errorType: 'invalid input',
                            message: '"खाते" निवडणे आवश्यक आहे',
                            row: rowNumber
                        });
                    }
                } else if (item['श्रेणी'] && !shreniData) {
                    errorList.push({
                        errorIn: 'श्रेणी',
                        errorType: 'invalid input',
                        message: '"श्रेणी" चुकीची निवड केलीय   ',
                        row: rowNumber
                    });
                }

                const dayatvStarData = dayitvTree?.find(
                    (value) => value.localized_name?.trim()?.replaceAll(' ', '') === item['दायित्व स्तर']?.trim()?.replaceAll(' ', '')
                );
                // console.log('दायित्व स्तर', item['दायित्व स्तर']);
                // console.log('dayitvTree............', dayitvTree);

                if (item['दायित्व स्तर'] && dayatvStarData) {
                    // console.log('dayatvStarData............', dayatvStarData);

                    const dayitvaData = dayatvStarData?.dayitvaData?.find(
                        (value) => value.localized_name?.trim() === item['दायित्व']?.trim()
                    );

                    // console.log('दायित्व', item['दायित्व']);
                    // console.log('dayitvaData............', dayitvaData);

                    if (dayatvStarData?.dayitvaData && dayatvStarData?.dayitvaData.length > 0 && item['दायित्व']) {
                        if (item['दायित्व']) {
                            if (!dayitvaData) {
                                errorList.push({
                                    errorIn: 'दायित्व',
                                    errorType: 'invalid input',
                                    message: '"दायित्व" चुकीची निवड केलीय   ',
                                    row: rowNumber
                                });
                            }
                        }
                        // console.log('occupation3', occupation3);
                    } else if (dayatvStarData?.dayitvaData && dayatvStarData?.dayitvaData.length <= 0 && item['दायित्व']) {
                        errorList.push({
                            errorIn: 'दायित्व',
                            errorType: 'invalid input',
                            message: '"दायित्व" चुकीची निवड केलीय   ',
                            row: rowNumber
                        });
                    } else if (dayatvStarData?.dayitvaData && dayatvStarData?.dayitvaData.length > 0 && !item['दायित्व']) {
                        errorList.push({
                            errorIn: 'दायित्व',
                            errorType: 'invalid input',
                            message: '"दायित्व" निवडणे आवश्यक आहे',
                            row: rowNumber
                        });
                    }
                } else if (item['दायित्व स्तर'] && !dayatvStarData) {
                    errorList.push({
                        errorIn: 'दायित्व स्तर',
                        errorType: 'invalid input',
                        message: '"दायित्व स्तर" चुकीची निवड केलीय   ',
                        row: rowNumber
                    });
                }

                // sangh shikshan
                const sanghShikshanData = sanghShikshan?.find((value) => value.localized_name?.trim() === item['संघ शिक्षण']?.trim());
                if (item['संघ शिक्षण'] && !sanghShikshanData) {
                    errorList.push({
                        errorIn: 'संघ शिक्षण',
                        errorType: 'invalid input',
                        message: '"संघ शिक्षण" चुकीची निवड केलीय   ',
                        row: rowNumber
                    });
                }

                return true;
            });
        }
        setErrorListData(errorList);
    };

    const onDrop = useCallback(async (acceptedFiles) => {
        if (!selectedGeo?.location_id) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'कृपया प्रथम भौगोलिक रचना निवडा',
                variant: 'alert',
                alertSeverity: 'error'
            });
            return;
        }
        setRawData([]);
        setLoaderUploadFile(true);
        setErrorListData([]);
        setBtnDisable(true);
        setSelecteFileName(acceptedFiles[0].name);

        acceptedFiles.forEach(async (file) => {
            const reader = new FileReader();

            reader.onload = async (event) => {
                // Do whatever you want with the file contents
                const wb = XLSX.read(event.target.result); // parse the array buffer
                const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
                const rawList = XLSX.utils.sheet_to_json(ws);
                const addedData = rawList;
                console.log('addedData', addedData);
                if (addedData && addedData?.length > 0) {
                    setRawData(addedData);
                    setLoaderUploadFile(false);
                    setBtnDisable(false);
                    validateData(addedData);
                } else {
                    setLoaderUploadFile(false);
                    // setBtnDisable(false);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: 'कृपया फाइलमध्ये काही डेटा घाला',
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
                }
            };
            reader.readAsArrayBuffer(file);
        });
    });
    const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({ onDrop });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const filterError = () => {
        let filterdData = [];
        if (errorListData && errorListData.length > 0) {
            const result = Object.values(
                errorListData.reduce((c, { row, message }) => {
                    c[row] = c[row] || { row, message: [] };
                    c[row].message = c[row].message.concat(Array.isArray(message) ? message : [message]);
                    return c;
                }, {})
            );
            filterdData = result;
        } else {
            filterdData = [];
        }
        return filterdData;
    };
    const filterdErrorList = filterError();

    const getBoolean = (data) => {
        let status = false;
        if (data && data === 'हो') {
            status = true;
        } else {
            status = false;
        }
        return status;
    };

    const getArrayToString = (data) => {
        const stringData = data.toString();
        return stringData?.replaceAll(',', ', ');
    };

    const fetchAllVyavsayTree = () => {
        getAllVyavsayTree()
            .then((resp) => {
                if (resp && resp?.length > 0) {
                    setVyavsayTree(resp);
                } else {
                    setVyavsayTree([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchAllShreniAndDayitv = async () => {
        // await getShreniData()
        //     .then((resp) => {
        //         if (resp && resp?.length > 0) {
        //             shreniData.push(resp);
        //             setShreni(resp);
        //         } else {
        //             setShreni([]);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

        // await getKhateData()
        //     .then((resp) => {
        //         if (resp && resp?.length > 0) {
        //             khateData.push(resp);
        //             setKhate(resp);
        //         } else {
        //             setKhate([]);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

        // await getDayitwStarData()
        //     .then((resp) => {
        //         if (resp && resp?.length > 0) {
        //             setDayitwStar(resp);
        //         } else {
        //             setDayitwStar([]);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

        // await getDayitwData()
        //     .then((resp) => {
        //         if (resp && resp?.length > 0) {
        //             setDayitw(resp);
        //         } else {
        //             setDayitw([]);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

        getAllShreniAndKhateTree()
            .then((resp) => {
                if (resp && resp?.length > 0) {
                    setShreniAndDayitv(resp);
                } else {
                    setShreniAndDayitv([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        getAllDayitvTree()
            .then((resp) => {
                if (resp && resp?.length > 0) {
                    setDayitvTree(resp);
                } else {
                    setDayitvTree([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchAllSuchiLocationWise = () => {
        // console.log(selectedGeo);
        if (selectedGeo?.level_id === '1' || selectedGeo?.level_id === '2') {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'कृपया अधिक अचूक स्थान निवडा.',
                variant: 'alert',
                alertSeverity: 'warning'
            });
            return;
        }
        const payload = {
            location_id: selectedGeo?.location_id
            // location_id: 9937
        };
        // setLocationWiseList([]);
        setLocationWiseListLoader(true);
        getAllListLocationWise(payload)
            .then((resp) => {
                setLocationWiseListLoader(false);
                if (resp && resp?.length > 0) {
                    // console.log('resp', resp);
                    const temp = _.orderBy(resp, ['updatedAt'], ['desc']);

                    const convertedData =
                        temp &&
                        temp?.map((item) => {
                            const data = {
                                id: item?.id,
                                level: item?.level,
                                location: item?.location,
                                'पहिले नांव': item?.firstName,
                                'वडिलांचे नांव': item?.fatherName,
                                आडनांव: item?.surname,

                                'जन्म तारीख': item?.dateOfBirth,
                                // bloodGroup: bloodGroupData?.includes(item['रक्त गट']?.toString()?.trim()) ? item['रक्त गट'] : 'माहीत नाही',
                                'रक्त गट': item?.bloodGroup,
                                व्यवसाय: item?.occupation,
                                'व्यवसाय तपशील 1': item?.occupationDetails1,
                                'व्यवसाय तपशील 2': item?.occupationDetails2,
                                'पूर्ण पत्ता': item?.address,
                                'पिन कोड': item?.pinCode,
                                दूरभाष: item?.phone,
                                भ्रमणभाष: item?.mobile,
                                'इ-मेल आयडी': item?.email,
                                श्रेणी: item?.category,
                                खाते: item?.account,
                                'दायित्व स्तर': item?.responsibilityLevel,
                                दायित्व: item?.responsibility,
                                'दायित्व अन्य': item?.otherResponsibility,
                                'विशेष नैपुण्य': item?.specialSkills,
                                'संघ शिक्षण': item?.trainingInOrganization,
                                प्रतिज्ञा: item?.isPledge,
                                टोपी: item?.isCap,
                                सदरा: item?.isSadara,
                                विजार: item?.isWizar,
                                पदवेष: item?.isPosition,
                                पट्टा: item?.isPatta,
                                दंड: item?.isPunishment,
                                'दुचाकी आहे?': item?.isDuchaki,
                                'चार चाकी आहे?': item?.isCharChaki
                            };
                            return data;
                        });
                    // console.log('convertedData', convertedData);
                    setLocationWiseList(convertedData);
                } else {
                    setLocationWiseList([]);
                }
            })
            .catch((error) => {
                setLocationWiseListLoader(false);
                console.log(error);
                setLocationWiseList([]);
            });
    };

    const fetchAllSanghShikshan = () => {
        getAllSanghShikshan()
            .then((resp) => {
                if (resp && resp?.length > 0) {
                    setSanghShikshan(resp);
                } else {
                    setSanghShikshan([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // const filterOccupationData = () => {
    //     console.log('rawData', rawData);
    //     console.log('vyavsayTree', vyavsayTree);

    //     const errorList = [];

    //     // errorList.push({
    //     //     errorIn: 'पहिले नांव',
    //     //     errorType: 'mandatory',
    //     //     message: 'पहिले नांव आवश्यक आहे',
    //     //     row: rowNumber
    //     // });

    //     rawData?.map((item) => {
    //         const occupation = vyavsayTree?.find((value) => value.localized_name === item['व्यवसाय']);
    //         console.log('occupation', occupation);
    //         if (item['व्यवसाय'] && occupation) {
    //             const occupation2 = occupation?.VyavasayKshetras?.find((value) => value.localized_name === item['व्यवसाय तपशील 1']);
    //             console.log('occupation2', occupation2);

    //             if (item['व्यवसाय तपशील 1']) {
    //                 if (occupation2) {
    //                     const occupation3 = occupation2?.vyavasay_tapshils?.find(
    //                         (value) => value.localized_name === item['व्यवसाय तपशील 2']
    //                     );
    //                     if (item['व्यवसाय तपशील 2']) {
    //                         if (!occupation3) {
    //                             console.log('v3', item['व्यवसाय तपशील 2']);
    //                             console.log('wrong occupation 3');
    //                             errorList.push({
    //                                 errorIn: 'व्यवसाय तपशील 1',
    //                                 errorType: 'invalid input',
    //                                 message: 'व्यवसाय तपशील 2 चुकीची निवड आहे '
    //                             });
    //                         }
    //                     }
    //                     console.log('occupation3', occupation3);
    //                 } else {
    //                     console.log('v2', item['व्यवसाय तपशील 1']);
    //                     console.log('wrong occupation 2');
    //                     errorList.push({
    //                         errorIn: 'व्यवसाय तपशील 1',
    //                         errorType: 'invalid input',
    //                         message: 'व्यवसाय तपशील 1 चुकीची निवड आहे '
    //                     });
    //                 }
    //             }
    //         }
    //         return true;
    //     });
    // };

    const clearAllFields = () => {
        setRawData([]);
        setErrorListData([]);
        setSelecteFileName('');
    };

    const submitFile = () => {
        // console.log(rawData);
        // filterOccupationData();

        // fetchAllVyavsayTree();
        if (!selecteFileName) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'कृपया प्रथम फाईल अपलोड करा.',
                variant: 'alert',
                alertSeverity: 'error'
            });
            return;
        }
        if (
            payload.selectedGeoLevel &&
            +payload.selectedGeoLevel.level.level_id !== 10 && // Gram
            +payload.selectedGeoLevel.level.level_id !== 11 // Vasti
        ) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'स्वयंसेवक केवळ गाव/वस्ती स्तरावर भरला जाऊ शकतो. ',
                variant: 'alert',
                alertSeverity: 'error'
            });
            return;
        }
        if (filterdErrorList && filterdErrorList.length <= 0) {
            if (selectedGeo?.location_id) {
                // const dob = 'जन्म तारीख\n(DDMMYYYY)';
                // rawData?.map((item) => {
                //     console.log(Object.keys(item));
                //     // console.log(item);
                //     // console.log(item[dob]);
                //     return true;
                // });
                // console.log(rawData);

                const convertedData =
                    rawData &&
                    rawData?.map((item) => {
                        const data = {
                            firstName: item['पहिले नांव']?.trim(),
                            fatherName: item['वडिलांचे नांव']?.trim(),

                            surname: item['आडनांव']?.trim(),

                            // dateOfBirth: xlsvDateFormat(item['जन्म तारीख\n(DDMMYYYY)']).toLocaleDateString() || '',
                            // dateOfBirth: item['जन्म तारीख\n(DDMMYYYY)'],
                            dateOfBirth: item['जन्म तारीख\n(DD-MM-YYYY)'],
                            bloodGroup: bloodGroupData?.includes(item['रक्त गट']?.toString()?.trim()) ? item['रक्त गट'] : 'माहीत नाही',
                            occupation: item['व्यवसाय']?.trim(),
                            occupationDetails1: item['व्यवसाय तपशील 1']?.trim(),
                            occupationDetails2: item['व्यवसाय तपशील 2']?.trim(),
                            address: item['पूर्ण पत्ता'],
                            pinCode: item['पिन कोड'],
                            phone: item['दूरभाष'],
                            mobile: item['भ्रमणभाष'],
                            email: item['इ-मेल आयडी'],
                            category: item['श्रेणी']?.trim(),
                            account: item['खाते']?.trim(),
                            responsibilityLevel: item['दायित्व स्तर']?.trim(),
                            responsibility: item['दायित्व']?.trim(),
                            otherResponsibility: item['दायित्व अन्य'],
                            specialSkills: item['विशेष नैपुण्य'],
                            trainingInOrganization: item['संघ शिक्षण'],
                            isPledge: getBoolean(item['प्रतिज्ञा']),
                            isCap: getBoolean(item['टोपी']),
                            isSadara: getBoolean(item['सदरा']),
                            isWizar: getBoolean(item['विजार']),
                            isPosition: getBoolean(item['पदवेष']),
                            isPatta: getBoolean(item['पट्टा']),
                            isPunishment: getBoolean(item['दंड']),
                            isDuchaki: getBoolean(item['दुचाकी आहे?']),
                            isCharChaki: getBoolean(item['चार चाकी आहे?'])
                        };
                        return data;
                    });
                console.log('convertedData', convertedData);
                const payload = {
                    selectedLocation: selectedGeo?.location_id,
                    // selectedLocation: '1',
                    swayamsevaks: convertedData
                };
                setBtnDisable(true);
                addSwayamSevak(payload)
                    .then((resp) => {
                        setBtnDisable(false);
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: 'फाईल यशस्वीरित्या अपलोड झाली',
                            variant: 'alert',
                            alertSeverity: 'success'
                        });
                        clearAllFields();
                        fetchAllSuchiLocationWise();
                    })
                    .catch((error) => {
                        setBtnDisable(false);
                        if (error?.response?.status === 400 && error?.response?.data?.message === 'submision failed') {
                            // console.log(error?.response?.data?.error);
                            setErrorListData(error?.response?.data?.error);
                        } else {
                            dispatch({
                                type: SNACKBAR_OPEN,
                                open: true,
                                message: 'Something Went Wrong, Please Try Again Later.',
                                variant: 'alert',
                                alertSeverity: 'error'
                            });
                        }
                    });
                // console.log('convertedData', convertedData);
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'कृपया भौगोलिक रचना निवडा',
                    variant: 'alert',
                    alertSeverity: 'error'
                });
            }
        } else {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'कृपया प्रथम त्रुटी दूर करा',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    };

    const proceedAfterGeoSelection = () => {
        setPayload({ ...payload, selectedGeoLevel: selectedGeo });
        //
    };

    const exportFile = useCallback(() => {
        const ws = utils.json_to_sheet(rawData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Data');
        writeFileXLSX(wb, 'Swayam suchi.xlsx');
    }, [rawData]);

    useEffect(async () => {
        fetchAllVyavsayTree();
        fetchAllShreniAndDayitv();
        fetchAllSanghShikshan();
        // fetchAllSuchiLocationWise();
        // setVyavsayTree(vyavsayTreeData);
    }, []);

    // console.log('geo', selectedGeo);
    // console.log('location', selectedGeo?.name_suffix);
    // console.log('locationData', rawData);

    const handleDelete = (ids) => {
        const payload = {
            ids: [...ids]
        };
        deleteSwayamSevak(payload)
            .then((resp) => {
                fetchAllSuchiLocationWise();

                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'यशस्वीरित्या माहिती हटवली',
                    variant: 'alert',
                    alertSeverity: 'success'
                });
            })
            .catch((error) => {
                fetchAllSuchiLocationWise();
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'अयशस्वी हटवणे. नंतर पुन्हा प्रयत्न करा',
                    variant: 'alert',
                    alertSeverity: 'error'
                });
                console.log(error);
            });
    };

    const options = {
        download: false,
        filter: true,
        print: false,
        pagination: true,
        rowsPerPageOptions: [10, 20, 50, 100, 150, 200],
        search: true,
        viewColumns: true,
        // selectableRows: true,
        selectableRows: 'multiple',
        // onRowsSelect: (curRowSelected, allRowsSelected) => {
        //     console.log('---RowSelect');
        //     console.log('Row Selected: ', curRowSelected);
        //     console.log('All Selected: ', allRowsSelected);
        // },
        onRowsDelete: (e) => {
            const ids = e.data?.map((item) => locationWiseList[item.index]?.id);
            handleDelete(ids);
        },
        textLabels: {
            body: {
                noMatch: locationWiseListLoader ? 'कृपया काही क्षण प्रतीक्षा करा...' : 'माहिती उपलब्ध नाही'
            }
        }
    };
    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h3">
                                <FormattedMessage id="menu.swayamsuchak" />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Stack direction="row" flexDirection="row-reverse" alignItems="flex-end">
                                <Button
                                    variant="outlined"
                                    color={selectedGeo ? 'primary' : 'error'}
                                    onClick={() => setGeoTreeDialogOpen(true)}
                                    sx={{ mr: '10px' }}
                                    endIcon={<LocationOnTwoToneIcon />}
                                >
                                    {selectedGeo ? `"${selectedGeo?.localized_name} ${selectedGeo?.name_suffix}"` : 'भौगोलिक रचना निवडा!'}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box textAlign="right">
                            <a
                                style={{ color: '#fff', textDecoration: 'none' }}
                                href="https://pmp-v2.s3.ap-south-1.amazonaws.com/Swayamsevak_Suchi_Template_2023_05_31.xlsx"
                                download="https://pmp-v2.s3.ap-south-1.amazonaws.com/Swayamsevak_Suchi_Template_2023_05_31.xlsx"
                            >
                                <Button variant="contained" color="primary">
                                    <DownloadForOfflineTwoToneIcon sx={{ mr: 1 }} />
                                    रिक्त टेम्पलेट डाउनलोड करा
                                </Button>
                            </a>
                            <Button
                                sx={{ ml: 2 }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (!selectedGeo?.location_id) {
                                        dispatch({
                                            type: SNACKBAR_OPEN,
                                            open: true,
                                            message: 'कृपया प्रथम भौगोलिक रचना निवडा',
                                            variant: 'alert',
                                            alertSeverity: 'error'
                                        });
                                        return;
                                    }
                                    if (
                                        payload.selectedGeoLevel &&
                                        +payload.selectedGeoLevel.level.level_id !== 10 && // Gram
                                        +payload.selectedGeoLevel.level.level_id !== 11 // Vasti
                                    ) {
                                        dispatch({
                                            type: SNACKBAR_OPEN,
                                            open: true,
                                            message: 'स्वयंसेवक केवळ गाव/वस्ती स्तरावर भरला जाऊ शकतो. ',
                                            variant: 'alert',
                                            alertSeverity: 'error'
                                        });
                                    } else {
                                        setEditSuchiModel(true);
                                        setSelectedSuchiRow({});
                                        setDisableAllFields(false);
                                    }
                                }}
                            >
                                <AddTwoTone sx={{ mr: 1 }} />
                                स्वयंसेवक जोडा
                            </Button>
                            {/* <Button variant="contained" color="primary" onClick={exportFile} sx={{ ml: 2 }}>
                                <DownloadForOfflineTwoToneIcon sx={{ mr: 1 }} disabled={!rawData} />
                                Export File
                            </Button> */}
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Alert severity="info" variant="standard" sx={{ color: '#000', mb: 1 }}>
                            <AlertTitle sx={{ color: '#000000' }}>कृपया प्रथम भौगोलिक रचना निवडा.</AlertTitle>
                        </Alert>
                        <Alert severity="info" variant="standard" sx={{ color: '#000', mb: 1 }}>
                            <AlertTitle sx={{ color: '#000000' }}>या पूर्वी भरलेली स्वयंसेवक सूची पुन्हा भरू नये.</AlertTitle>
                        </Alert>
                        {/* <Alert severity="info" variant="standard" sx={{ color: '#000', mb: 1 }}>
                            <AlertTitle sx={{ color: '#000000' }}>भ्रमणभाष नंबर वेगळा असावा.</AlertTitle>
                        </Alert>
                        <Alert severity="info" variant="standard" sx={{ color: '#000', mb: 1 }}>
                            <AlertTitle sx={{ color: '#000000' }}>प्रत्येक इ-मेल आयडी वेगळी असावा.</AlertTitle>
                        </Alert> */}
                    </Grid>

                    <Grid item xs={12}>
                        {loaderUploadFile ? (
                            <div {...getRootProps({ style })} style={uploadBox}>
                                <p>कृपया प्रतीक्षा करा...</p>
                            </div>
                        ) : (
                            <div {...getRootProps({ style })} style={uploadBox}>
                                <input
                                    {...getInputProps()}
                                    multiple={false}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                                {/* {selecteFileName ? <p>{selecteFileName}</p> : <p>Drag n drop file here, or click to select file</p>} */}
                                {selecteFileName ? <p>{selecteFileName}</p> : <p>भरलेली स्वयंसेवक सूची या ठिकाणी क्लिक करून अपलोड करा.</p>}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Box textAlign="right">
                            <Button disabled={!!loaderUploadFile || btnDisable} variant="contained" color="primary" onClick={submitFile}>
                                कृपया फाईल अपलोड करा
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {filterdErrorList?.map((item) => (
                            <>
                                <p>
                                    <Alert severity="warning" variant="filled" sx={{ color: '#000' }}>
                                        <AlertTitle sx={{ color: '#000' }}>
                                            पंक्ती क्रमांक {item.row} मध्ये {getArrayToString(item.message)}.
                                        </AlertTitle>
                                    </Alert>
                                </p>
                            </>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <MUIDataTable
                            className="mui_data_table"
                            data={locationWiseList}
                            options={options}
                            columns={[
                                {
                                    label: 'क्र.',
                                    name: 'sr',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <>
                                                <span>{tableMeta.rowIndex + 1}</span>
                                            </>
                                        )
                                    }
                                },
                                {
                                    name: 'पहिले नांव',
                                    label: 'पहिले नांव',
                                    options: {
                                        filter: true,
                                        sort: true,
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <>
                                                <span>{value}</span>
                                            </>
                                        )
                                    }
                                },

                                {
                                    name: 'वडिलांचे नांव',
                                    label: 'वडिलांचे नांव',
                                    options: {
                                        filter: true,
                                        sort: true,
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <>
                                                <span>{value}</span>
                                            </>
                                        )
                                    }
                                },

                                {
                                    name: 'आडनांव',
                                    label: 'आडनांव',
                                    options: {
                                        filter: true,
                                        sort: true,
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <>
                                                <span>{value}</span>
                                            </>
                                        )
                                    }
                                },
                                // {
                                //     name: 'पूर्ण पत्ता',
                                //     label: 'पूर्ण पत्ता',
                                //     options: {
                                //         filter: true,
                                //         sort: true,
                                //         customBodyRender: (value, tableMeta, updateValue) => (
                                //             <>
                                //                 <span>{value}</span>
                                //             </>
                                //         )
                                //     }
                                // },
                                {
                                    name: 'भ्रमणभाष',
                                    label: 'भ्रमणभाष',
                                    options: {
                                        filter: true,
                                        sort: true,
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <>
                                                <span>{value}</span>
                                            </>
                                        )
                                    }
                                },
                                {
                                    name: 'श्रेणी',
                                    label: 'श्रेणी',
                                    options: {
                                        filter: true,
                                        sort: true,
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <>
                                                <span>{value}</span>
                                            </>
                                        )
                                    }
                                },
                                {
                                    name: 'खाते',
                                    label: 'खाते',
                                    options: {
                                        filter: true,
                                        sort: true,
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <>
                                                <span>{value}</span>
                                            </>
                                        )
                                    }
                                },
                                {
                                    name: 'दायित्व स्तर',
                                    label: 'दायित्व स्तर',
                                    options: {
                                        filter: true,
                                        sort: true,
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <>
                                                <span>{value}</span>
                                            </>
                                        )
                                    }
                                },
                                {
                                    name: 'दायित्व',
                                    label: 'दायित्व',
                                    options: {
                                        filter: true,
                                        sort: true,
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <>
                                                <span>{value}</span>
                                            </>
                                        )
                                    }
                                },
                                // {
                                //     name: 'संघ शिक्षण',
                                //     label: 'संघ शिक्षण',
                                //     options: {
                                //         filter: true,
                                //         sort: true,
                                //         customBodyRender: (value, tableMeta, updateValue) => (
                                //             <>
                                //                 <span>{value}</span>
                                //             </>
                                //         )
                                //     }
                                // },
                                {
                                    name: 'id',
                                    label: 'Action/कृती',
                                    options: {
                                        filter: false,
                                        sort: false,
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <>
                                                <Tooltip arrow title="बदल करा">
                                                    <IconButton
                                                        onClick={() => {
                                                            setEditSuchiModel(true);
                                                            setSelectedSuchiRow(locationWiseList?.find((item) => item.id === value));
                                                            setDisableAllFields(true);
                                                        }}
                                                    >
                                                        <EditTwoToneIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip arrow title="काढून टाका">
                                                    <IconButton
                                                        onClick={() => {
                                                            const id = [];
                                                            id.push(value);
                                                            handleDelete(id);
                                                        }}
                                                    >
                                                        <DeleteForeverTwoToneIcon color="error" />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )
                                    }
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
            </MainCard>

            {/* geo tree model */}
            <GeoTreeDialog
                selectedGeo={selectedGeo}
                geoTreeDialogOpen={geoTreeDialogOpen}
                handleGeoDialogClose={(event, reason) => {
                    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
                        return;
                    }
                    setGeoTreeDialogOpen(false);
                }}
                handleCancelGeoSelection={() => {
                    setSelectedGeo(undefined);
                    setGeoTreeDialogOpen(false);
                    dispatch({ type: SET_GEO_STRUCTURE, payload: { selectedGeoStructure: undefined } });
                }}
                handleGeoLevelSelection={(selectedGeoLevel) => {
                    setSelectedGeo(selectedGeoLevel);
                    dispatch({ type: SET_GEO_STRUCTURE, payload: { selectedGeoStructure: selectedGeoLevel } });
                }}
                handleConfirmGeoSelection={() => {
                    fetchAllSuchiLocationWise();
                    setGeoTreeDialogOpen(false);
                    proceedAfterGeoSelection();
                }}
            />

            {/* add and edit suchi model */}
            <EditSuchiDialog
                setEditSuchiModel={setEditSuchiModel}
                editSuchiModel={editSuchiModel}
                selectedSuchiRow={selectedSuchiRow}
                setSelectedSuchiRow={setSelectedSuchiRow}
                vyavsayTree={vyavsayTree}
                shreniAndDayitv={shreniAndDayitv}
                dayitvTree={dayitvTree}
                fetchAllSuchiLocationWise={fetchAllSuchiLocationWise}
                sanghShikshan={sanghShikshan}
                disableAllFields={disableAllFields}
                locationId={selectedGeo?.location_id}
            />
        </>
    );
}
