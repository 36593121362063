import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Divider, List, Typography } from '@material-ui/core';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import useAuth from 'hooks/useAuth';

// style constant
const useStyles = makeStyles((theme) => ({
    menuCaption: {
        ...theme.typography.menuCaption
    },
    subMenuCaption: {
        ...theme.typography.subMenuCaption
    },
    menuDivider: {
        marginTop: '2px',
        marginBottom: '10px'
    }
}));

// ===========================|| SIDEBAR MENU LIST GROUP ||=========================== //

const mainMenuLevlesApplicable = ['1', '2', '3', '4', '5', '6', '7', '8'];
const onlySuperAdminMainMenus = ['system-users'];
const onlySuperAdminMenus = ['unapproved-users-list', 'rp-anukulVyakti-suchi'];

const prodSankalpAccessTo = [
    'pmpsangh@gmail.com',
    // BHAG - JILHA Users
    'moreshwar.gadre0102@gmail.com',
    'js_jayesh@hotmail.com',
    'yesanejotiba@gmail.com',
    'jinevam06@gmail.com',
    'vkota2774@gmail.com',
    'amitraut126@gmail.com',
    'vilaspawar19@gmail.com',
    'kulkarniraghven@gmail.com',
    'bindumadhav69@gmail.com',
    'katkeajinkya86@gmail.com',
    'javinashan@gmail.com',
    'amitraut126@gmail.com',
    'ambekarharshal@gmail.com',
    'akolkaryashvant@gmail.com',
    'narendrakul18@yahoo.in',
    'usureka@gmail.com',
    'rajendra.r.chavan@gmail.com',
    'katraj.bhag@gmail.com',
    'tulasi123dk@gmail.com',
    'muktman25@gmail.com',
    'thitesa17@GMAIL.COM',
    'mayur.rede@gmail.com',
    'deshpandesameer59@gmail.com',
    'ravinikam555@gmail.com',
    'kaleam76@yahoo.co.in',
    'vijaykhalkar88@gmail.com',
    'gawalinandu7@gmail.com',
    'sunil.g.patil1@gmail.com',
    'abhay.barahate@gmail.com',
    'mukunda.thombare@gmail.com',
    'dhananjay.randhavan@outlook.com',
    'raskar2atul@gmail.com ',
    'pss13262013@gmail. Com',
    'ashok.vagga7@gmail.com',
    'mahendraparanjpe@gmail.com',
    'jitmisar2012@gmail.com',
    'rohitkarus@gmail.com',
    'thakurvg07@gmail.com',
    'sandipkhadake5116@gmail.com'
];
const stagingSankalpAccessTo = ['kundanart1983+katrajbhag@gmail.com', 'prafulla@patheya.tech'];
const sankalpAccessToOnlyEmails = [...prodSankalpAccessTo, ...stagingSankalpAccessTo];

const NavGroup = ({ item }) => {
    const classes = useStyles();
    const { user } = useAuth();
    const [items, setItems] = useState([]);
    // Main menu list hidden option
    let filteredItems = item.children;

    let filteredMenus = filteredItems;
    const filterMenus = () => {
        filteredMenus = filteredMenus.map((i) => {
            const tempMenu = [];
            if (i.id === 'lokjagaran-abhiyan-vrutta') {
                i.children.forEach((e) => {
                    if (user?.profile?.level?.level_id !== '10' && e.id === 'la-bhaugolik-vrutta') {
                        tempMenu.push(e);
                    }
                    if (parseInt(user?.profile?.level?.level_id, 10) < 8 && e.id === 'la-samajik-vrutta') {
                        tempMenu.push(e);
                    }
                    if (
                        (user?.profile?.level?.level_id === '1' ||
                            user?.profile?.level?.level_id === '6' ||
                            user?.profile?.level?.level_id === '7') &&
                        e.id === 'la-sahayogi-sanstha-mahiti-sankalan'
                    ) {
                        tempMenu.push(e);
                    }
                    if (user?.profile?.level?.level_id === '1' && e.id === 'la-vividh-kshetrache-vrutt') {
                        tempMenu.push(e);
                    }
                });
            }
            if (tempMenu.length > 0) {
                i.children = tempMenu;
            }
            return i;
        });
    };

    useEffect(() => {
        if (user && user.profile && mainMenuLevlesApplicable.includes(user.profile.level.level_id) === false) {
            filteredItems = item.children.filter((m) => onlySuperAdminMainMenus.indexOf(m.id) < 0);
        }
        if (filteredMenus[0].type === 'collapse') {
            filterMenus();
            const newFilteredMenus = [];
            filteredMenus.forEach((i) => {
                // if (i.id === 'sankalp' && user.profile.level.level_id === '1') {
                //     newFilteredMenus.push(i);
                //     return;
                // }
                // if (i.id === 'sankalp' && sankalpAccessToOnlyEmails.includes(user.email) === false) {
                //     return;
                // }
                newFilteredMenus.push(i);
            });
            filteredMenus = newFilteredMenus;
        }
        const tempItems = filteredMenus.map((menu) => {
            switch (menu.type) {
                case 'collapse':
                    return <NavCollapse key={menu.id} menu={menu} level={1} />;
                case 'item':
                    return <NavItem key={menu.id} item={menu} level={1} />;
                default:
                    return (
                        <Typography key={menu.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
        setItems(tempItems);
    }, []);

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" className={classes.menuCaption} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider className={classes.menuDivider} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
