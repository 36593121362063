import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconInfoCircle, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = { IconInfoCircle, IconDeviceAnalytics };

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const pmpAbout = {
    id: 'about',
    title: <FormattedMessage id="misc-menu" />,
    type: 'group',
    children: [
        {
            id: 'pmp-about',
            title: <FormattedMessage id="pmp-about" />,
            type: 'item',
            url: '/about',
            icon: icons.IconInfoCircle,
            breadcrumbs: false
        }
    ]
};

export default pmpAbout;
