import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Card,
    Grid,
    Menu,
    MenuItem,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// project card
import { gridSpacing } from 'store/constant';
// import Avatar from '../extended/Avatar';
import './index.css';
// style card
const useStyles = makeStyles((theme) => ({
    followerBlock: {
        padding: '4px',
        // background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: theme.palette.mode === 'dark' ? '1px solid transparent' : `1px solid ${theme.palette.grey[100]}`,
        '&:hover': {
            borderColor: theme.palette.primary.main
        }
    },
    primaryLight: {
        color: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[500],
        cursor: 'pointer'
    },
    btnProfile: {
        width: '100%',
        '&:hover ': {
            color: '#fff'
        },
        '&.MuiButton-outlinedPrimary:hover ': {
            background: theme.palette.primary.main
        },
        '&.MuiButton-outlinedSecondary': {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main
        },
        '&.MuiButton-outlinedSecondary:hover ': {
            background: theme.palette.error.main,
            color: '#fff'
        }
    },
    accordianStyle: {
        width: '100% !important'
    }
}));

// ===========================|| USER DETAILS CARD ||=========================== //

const SanghikDetailsCard = (props) => {
    const classes = useStyles();
    const history = useNavigate();
    // const avatarProfile = avatar && avatarImage(`./${avatar}`).default;
    const { sanghik, year, index, setSanghikList, sanghikList, sankalpId } = props;
    // console.log('props', props);

    const handleInputChange = (e) => {
        const tempObj = [...sanghikList];
        console.log(
            tempObj,
            sanghikList.find((i) => i.sankalp_id === sankalpId)
        );
        const obj = sanghikList.find((i) => i.sankalp_id === sankalpId);
        obj.sanghik_upstiti[e.target.name] = parseInt(e.target.value, 10);
        const totalKeyTemp = obj.sanghik_upstiti;

        const praudhNum = parseInt(totalKeyTemp.praudh, 10) || 0;
        const tarunVyavsayiNum = parseInt(totalKeyTemp.tarun_vyavasayi, 10) || 0;
        const mahaVidyarthiNum = parseInt(totalKeyTemp.maha_vidyarthi, 10) || 0;
        const baalNum = parseInt(totalKeyTemp.baal, 10) || 0;

        const total = praudhNum + tarunVyavsayiNum + mahaVidyarthiNum + baalNum;
        obj.sanghik_upstiti.total = total;
        const newMap = sanghikList.map((i) => {
            if (i.sankalp_id === sankalpId) {
                return obj;
            }
            return i;
        });
        setSanghikList([...newMap]);
    };

    const localMetaData = JSON.parse(localStorage.getItem('pmp_metadata_v2'));
    // console.log('localMetaData', localMetaData);
    const typeIdName =
        localMetaData &&
        localMetaData?.ekakTypes?.find((localValue) => localValue?.ekak_type_id?.toString() === sanghik?.ekak_type_id?.toString());

    const subTypeIdName =
        typeIdName &&
        typeIdName?.sub_types?.find((subData) => subData?.ekak_sub_type_id?.toString() === sanghik?.ekak_sub_type_id?.toString());
    // console.log('id', sanghik?.ekak_type_id);
    // console.log('sub id', sanghik?.ekak_sub_type_id);
    // console.log('sanghik', sanghik);

    return (
        <>
            <Card className={classes.followerBlock} style={{ backgroundColor: '#e3f2fd' }}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography className={sanghik?.sanghik_upstiti?.total > 0 ? 'highlightedSankalp' : 'unhighlightedSankalp'}>
                            {sanghik?.localized_name} -{' '}
                            {subTypeIdName?.ekak_type_id === 3
                                ? subTypeIdName?.localized_name
                                : `${subTypeIdName?.localized_name} ${typeIdName?.localized_name}`}{' '}
                            ({sanghik?.sanghik_upstiti?.total})
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="caption">एकक प्रकार</Typography>
                                        <Typography variant="h6">
                                            {subTypeIdName?.ekak_type_id === 3
                                                ? subTypeIdName?.localized_name
                                                : `${subTypeIdName?.localized_name} ${typeIdName?.localized_name}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="caption">स्थान</Typography>
                                        <Typography variant="h6">{sanghik.location.localized_name}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="caption">स्थान</Typography>
                                        <Typography variant="h6">{sanghik.location.localized_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="caption">{sanghik.parent.name_suffix}</Typography>
                                        <Typography variant="h6">{sanghik.parent.localized_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="caption">{sanghik.parent?.parent?.name_suffix}</Typography>
                                        <Typography variant="h6">{sanghik.parent?.parent?.localized_name}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    {(subTypeIdName.ekak_sub_type_id === 4 || subTypeIdName.ekak_sub_type_id === 8) && (
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                name="praudh"
                                                label="प्रौढ"
                                                type="number"
                                                InputProps={{ inputProps: { min: 0 } }}
                                                value={sanghik?.sanghik_upstiti?.praudh}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    )}
                                    {(subTypeIdName.ekak_sub_type_id === 3 || subTypeIdName.ekak_sub_type_id === 7) && (
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                name="tarun_vyavasayi"
                                                label="तरुण व्यवसायी"
                                                type="number"
                                                InputProps={{ inputProps: { min: 0 } }}
                                                value={sanghik?.sanghik_upstiti?.tarun_vyavasayi}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    )}
                                    {(subTypeIdName.ekak_sub_type_id === 1 ||
                                        subTypeIdName.ekak_sub_type_id === 2 ||
                                        subTypeIdName.ekak_sub_type_id === 2 ||
                                        subTypeIdName.ekak_sub_type_id === 5) && (
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    name="maha_vidyarthi"
                                                    label="महाविद्यालयीन विद्यार्थी "
                                                    type="number"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={sanghik?.sanghik_upstiti?.maha_vidyarthi}
                                                    onChange={handleInputChange}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    name="baal"
                                                    label="बाल"
                                                    type="number"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={sanghik?.sanghik_upstiti?.baal}
                                                    onChange={handleInputChange}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            name="total"
                                            label="एकूण"
                                            type="number"
                                            disabled
                                            InputProps={{ inputProps: { min: 0 } }}
                                            value={sanghik?.sanghik_upstiti?.total}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    {(subTypeIdName.ekak_sub_type_id === 1 || subTypeIdName.ekak_sub_type_id === 2) && (
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                name="shishu"
                                                label="शिशु"
                                                type="number"
                                                InputProps={{ inputProps: { min: 0 } }}
                                                value={sanghik?.sanghik_upstiti?.shishu}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                {/* <Typography variant="caption">praudh</Typography> */}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </>
    );
};

export default SanghikDetailsCard;
