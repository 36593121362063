import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const SadyasthitiService = {};
export default SadyasthitiService;

export const getSanghikAhawalListForGraminJilha = async (payload) => {
    const res = await axios.post(`${BASE_URL}/api/v2/sanghik/report/bhag`, payload);
    // const res = await axios.post(`http://localhost:7500/pmpapp/api/v2/sanghik/report/district`, payload);
    return res.data;
};
export const getSanghikAhawalListForBhag = async (payload) => {
    const res = await axios.post(`${BASE_URL}/api/v2/sanghik/report/bhag`, payload);
    // const res = await axios.post(`http://localhost:7500/pmpapp/api/v2/sanghik/report/bhag`, payload);
    return res.data;
};
export const getSanghikAhawalListForNagar = async (payload) => {
    const res = await axios.post(`${BASE_URL}/api/v2/sanghik/report/nagar`, payload);
    // const res = await axios.post(`http://localhost:7500/pmpapp/api/v2/sanghik/report/nagar`, payload);
    return res.data;
};
export const getSanghikAhawalListForUpakhand = async (payload) => {
    const res = await axios.post(`${BASE_URL}/api/v2/sanghik/report/upkhand`, payload);
    // const res = await axios.post(`http://localhost:7500/pmpapp/api/v2/sanghik/report/upkhand`, payload);
    return res.data;
    // return apiResponse;
};
export const getSanghikAhawalListForTaluka = async (payload) => {
    const res = await axios.post(`${BASE_URL}/api/v2/sanghik/report/upkhand`, payload);
    // const res = await axios.post(`http://localhost:7500/pmpapp/api/v2/sanghik/report/taluka`, payload);
    return res.data;
    // return apiResponse;
};
export const getSanghikList = async (payload) => {
    const res = await axios.post(`${BASE_URL}/api/v2/sanghik/list`, payload);
    return res.data;
};

export const updateSanghikList = async (payload) => {
    const res = await axios.post(`${BASE_URL}/api/v2/sanghik/add`, payload);
    return res.data;
};
