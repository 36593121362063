import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import { DateTime } from 'luxon';
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone';
import GeoTreeDialog from 'ui-component/pmp-components/GeoTreeDialog';
import { YEARS } from 'utils/pmp-constants';
import SendTwoToneIcon from '@material-ui/icons/SendTwoTone';
import { getSanghikList, updateSanghikList } from 'services/sanghik-service';
import { SET_GEO_STRUCTURE, SNACKBAR_OPEN } from 'store/actions';
import SanghikDetailsCard from './SanghikDetailsCard';
import Loader from 'ui-component/Loader';
import _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    accordianStyle: {
        width: '100% !important'
    }
}));
export default function Sanghik() {
    const classes = useStyles();
    const MONTH_FORMAT = 'MMMM';
    const sadyasthitiStore = useSelector((state) => state.sadyasthiti);
    const selectedgeoStructure = useSelector((state) => state?.centrallySelectedgeoStructure?.selectedGeoStructure);
    const [month, setMonth] = useState(sadyasthitiStore.month || DateTime.now().setLocale('mr').toFormat(MONTH_FORMAT));
    const [monthOptions, setMonthOptions] = useState([]);
    const [selectedGeo, setSelectedGeo] = useState(selectedgeoStructure);
    const [geoTreeDialogOpen, setGeoTreeDialogOpen] = useState(false);
    const [payload, setPayload] = useState({
        karyalay_pramukh: true
    });
    const [year, setYear] = useState(sadyasthitiStore.year || '2023-24');
    const [sanghikData, setSanghikData] = useState({});
    const [sankalpList, setSankalpList] = useState([]);
    const [groupedSankalpList, setGroupedSankalpList] = useState([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showEmptyMessage, setShowEmptyMessage] = useState('');

    // function block------------------------

    const getMonth = () => {
        const months = [];
        const dt = DateTime.now();
        if (dt.month === 4) {
            // April
            months.push(dt.setLocale('mr').toFormat(MONTH_FORMAT));
        } else if (dt.month === 5) {
            // May
            months.push(dt.setLocale('mr').toFormat(MONTH_FORMAT));

            const dt1 = dt.minus({ month: 1 });
            months.push(dt1.setLocale('mr').toFormat(MONTH_FORMAT));
        } else {
            // June till March
            months.push(dt.setLocale('mr').toFormat(MONTH_FORMAT));

            const dt1 = dt.minus({ month: 1 });
            months.push(dt1.setLocale('mr').toFormat(MONTH_FORMAT));

            const dt2 = dt.minus({ months: 2 });
            months.push(dt2.setLocale('mr').toFormat(MONTH_FORMAT));
        }

        setMonthOptions(months);
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
        // loadSadyasthiti(selectedGeo, event.target.value);
    };

    const proceedAfterGeoSelection = () => {
        setPayload({ ...payload, selectedGeoLevel: selectedGeo });
        //
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
        // if (selectedGeo && selectedGeo.location_id) {
        //     loadSankalp(selectedGeo.location_id, event.target.value);
        // }
    };

    const fetchSanghikList = () => {
        setSanghikData({});
        setSankalpList([]);
        setShowEmptyMessage('');
        const selectedYear = year;
        const selectedMonth = month;

        if (selectedGeo?.level_id !== '7' && selectedGeo?.level_id !== '8') {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'कृपया शहरी नगर किंवा उपखंड किंवा उपखंड नगर स्तर निवडा.',
                variant: 'alert',
                alertSeverity: 'warning'
            });
            return;
        }

        setLoading(true);
        const payload = {
            locationId: selectedGeo?.location_id,
            year: selectedYear,
            month: selectedMonth
        };
        // const payload = {
        //     locationId: '3623',
        //     year: '2023-24',
        //     month: 'जून'
        // };

        getSanghikList(payload)
            .then((resp) => {
                setLoading(false);
                // console.log(resp);
                if (resp && resp?.sankalp?.length > 0) {
                    if (resp?.sanghik_id) {
                        setShowEmptyMessage('');
                    } else {
                        setShowEmptyMessage('सांघिक झालेलं नाही.');
                    }
                    const tempSanghikData =
                        resp &&
                        resp?.sankalp?.map((item) => {
                            const tempData = {
                                ...item,
                                sanghik_upstiti: {
                                    praudh: item.sanghik_upstiti?.praudh || 0,
                                    tarun_vyavasayi: item.sanghik_upstiti?.tarun_vyavasayi || 0,
                                    maha_vidyarthi: item.sanghik_upstiti?.maha_vidyarthi || 0,
                                    baal: item.sanghik_upstiti?.baal || 0,
                                    shishu: item.sanghik_upstiti?.shishu || 0,
                                    total: item.sanghik_upstiti?.total || 0
                                }
                            };
                            return tempData;
                        });
                    const tempObj = {
                        // sankalp_data: [...tempSanghikData],
                        location: resp?.location,
                        month: resp?.month,
                        sanghik_id: resp?.sanghik_id,
                        year: resp?.year
                    };
                    const groupedBylocationid = _.groupBy(tempSanghikData, 'location.location_id');
                    console.log(groupedBylocationid);
                    setGroupedSankalpList(groupedBylocationid);
                    setSankalpList(tempSanghikData);
                    setSanghikData(tempObj);
                } else {
                    setShowEmptyMessage('सांघिक माहिती उपलब्ध नाही.');
                    setGroupedSankalpList([]);
                    setSanghikData({});
                    setSankalpList([]);
                }
            })
            .catch((error) => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'सांघिक माहिती उपलब्ध नाही. कृपया नंतर पुन्हा प्रयत्न करा',
                    variant: 'alert',
                    alertSeverity: 'error'
                });
                setLoading(false);
                setShowEmptyMessage(true);
                console.log(error);
            });
    };
    const addedSankalp = sankalpList?.filter((item) => item?.sanghik_upstiti?.total > 0 || item?.sanghik_upstiti?.shishu > 0);

    const updateSanghikData = () => {
        const selectedYear = year;
        const selectedMonth = month;

        if (selectedGeo?.level_id !== '7' && selectedGeo?.level_id !== '8') {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'कृपया तालुका किंवा शहरी नगर स्तर निवडा.',
                variant: 'alert',
                alertSeverity: 'warning'
            });
            return;
        }

        if (addedSankalp && addedSankalp?.length <= 0) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'कृपया सांघिक माहिती भरा.',
                variant: 'alert',
                alertSeverity: 'warning'
            });
            return;
        }
        setLoading(true);
        const payload = {
            locationId: selectedGeo?.location_id,
            year: selectedYear,
            month: selectedMonth,
            upstitiDataArray: [...addedSankalp]
        };

        console.log('payload', payload);
        setLoading(false);
        updateSanghikList(payload)
            .then((resp) => {
                setLoading(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'सांघिक माहिती यशस्वीरित्या अद्ययावत केली आहे.',
                    variant: 'alert',
                    alertSeverity: 'success'
                });
                fetchSanghikList();
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'सांघिक माहिती अद्ययावत झाली नाही. कृपया नंतर पुन्हा प्रयत्न करा',
                    variant: 'alert',
                    alertSeverity: 'error'
                });
            });
    };
    const renderCards = (sankalp) => {
        const cards = sankalp.map((item, index) => (
            <Grid item xs={12} sm={4} md={4}>
                <SanghikDetailsCard
                    sanghik={item}
                    sankalpId={item.sankalp_id}
                    sanghikList={sankalpList}
                    year={year}
                    index={index}
                    setSanghikList={setSankalpList}
                />
            </Grid>
        ));
        return (
            <Grid container spacing={3}>
                {cards}
            </Grid>
        );
    };
    // lifecycle methods ------------------------
    useEffect(() => {
        getMonth();
        // fetchSanghikList();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h2">
                                <FormattedMessage id="menu.sanghik" />
                            </Typography>
                        </Grid>
                    </Grid>
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={2} lg={1.4}>
                        <FormControl fullWidth>
                            <InputLabel id="month-select">
                                <FormattedMessage id="month" />
                            </InputLabel>
                            <Select
                                id="month"
                                name="month"
                                label="month"
                                value={month}
                                onChange={handleMonthChange}
                                displayEmpty
                                size="small"
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {monthOptions.map((monthOption, index) => (
                                    <MenuItem key={index} value={monthOption}>
                                        {monthOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3} md={2} lg={2}>
                        <Stack direction="row">
                            <FormControl fullWidth>
                                <InputLabel id="year-select">
                                    <FormattedMessage id="year" />
                                </InputLabel>
                                <Select
                                    id="year"
                                    fullWidth
                                    name="year"
                                    label="year"
                                    value={year || ''}
                                    onChange={handleYearChange}
                                    displayEmpty
                                    // disabled
                                    size="small"
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    {YEARS.map((year, index) => (
                                        <MenuItem key={index} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <Stack direction="row">
                            <Button
                                fullWidth
                                variant="outlined"
                                color={selectedGeo ? 'primary' : 'error'}
                                onClick={() => setGeoTreeDialogOpen(true)}
                                sx={{ mr: '10px' }}
                                endIcon={<LocationOnTwoToneIcon />}
                            >
                                {selectedGeo ? `"${selectedGeo?.localized_name} ${selectedGeo?.name_suffix}"` : 'भौगोलिक रचना निवडा!'}
                            </Button>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Button variant="contained" fullWidth endIcon={<SendTwoToneIcon />} onClick={fetchSanghikList}>
                            <FormattedMessage id="btn_find" />
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <Typography variant="h3" display="flex" alignItems="center">
                            <FormattedMessage id="menu.sanghik.ekunSankalp" />
                            {` : ${sankalpList && sankalpList?.length > 0 ? sankalpList?.length : 0}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {showEmptyMessage && (
                            <Alert severity="error" variant="standard" sx={{ color: '#000', mb: 1 }}>
                                <AlertTitle sx={{ color: '#000000' }}>{showEmptyMessage}</AlertTitle>
                            </Alert>
                        )}
                    </Grid>
                    {/* {sankalpList?.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4}>
                            <SanghikDetailsCard
                                sanghik={item}
                                sanghikList={sankalpList}
                                year={year}
                                index={index}
                                setSanghikList={setSankalpList}
                            />
                        </Grid>
                    ))} */}
                    <Grid container>
                        {Object.keys(groupedSankalpList).length > 0 &&
                            Object.keys(groupedSankalpList)?.map((item, index) => (
                                <Accordion className={classes.accordianStyle}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography variant="h4">
                                            {`${groupedSankalpList[item][0]?.location?.localized_name} `}
                                            {`${groupedSankalpList[item][0]?.location?.name_suffix}`}
                                            {` (${groupedSankalpList[item].length})`}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>{renderCards(groupedSankalpList[item])}</AccordionDetails>
                                </Accordion>
                            ))}
                    </Grid>
                    {sankalpList && sankalpList?.length > 0 ? (
                        <Grid item xs={12}>
                            <Box textAlign="right">
                                <Button
                                    variant="contained"
                                    color="success"
                                    endIcon={<SendTwoToneIcon />}
                                    onClick={updateSanghikData}
                                    disabled={addedSankalp && addedSankalp.length === 0 && !sanghikData?.sanghik_id}
                                >
                                    {sanghikData?.sanghik_id ? 'माहिती अद्ययावत करा' : 'सांघिक जतन करा'}
                                </Button>
                            </Box>
                        </Grid>
                    ) : null}
                </Grid>
            </MainCard>

            {/* geo tree model */}
            <GeoTreeDialog
                selectedGeo={selectedGeo}
                geoTreeDialogOpen={geoTreeDialogOpen}
                handleGeoDialogClose={(event, reason) => {
                    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
                        return;
                    }
                    setGeoTreeDialogOpen(false);
                }}
                handleCancelGeoSelection={() => {
                    setSelectedGeo(undefined);
                    setGeoTreeDialogOpen(false);
                    dispatch({ type: SET_GEO_STRUCTURE, payload: { selectedGeoStructure: undefined } });
                }}
                handleGeoLevelSelection={(selectedGeoLevel) => {
                    setSelectedGeo(selectedGeoLevel);
                    dispatch({ type: SET_GEO_STRUCTURE, payload: { selectedGeoStructure: selectedGeoLevel } });
                }}
                handleConfirmGeoSelection={() => {
                    setGeoTreeDialogOpen(false);
                    proceedAfterGeoSelection();
                }}
                skipLevel={['7', '8']}
                skipLeafLevel
            />
        </>
    );
}
