import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Backdrop, CircularProgress } from '@material-ui/core';

// style constant
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1301,
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    }
}));

// ===========================|| Loader ||=========================== //

const Loader = ({ loading }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                {/* <LinearProgress color="error" /> */}
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default Loader;
