// action - state management

import { SET_GEO_STRUCTURE } from 'store/actions';

const initialState = {
    selectedGeoStructure: undefined
};
// ===========================|| ACCOUNT REDUCER ||=========================== //

const updateGeoStructureReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GEO_STRUCTURE: {
            const { selectedGeoStructure } = action.payload;
            return {
                ...state,
                selectedGeoStructure
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default updateGeoStructureReducer;
