import React, { useEffect, useState } from 'react';
import './index.css';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { utils, writeFile } from 'xlsx';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import Loader from 'ui-component/Loader';

export default function SanghikBhagAhawal({ ahawalResponse }) {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const defaultUpakhandAhawal = {
        totalVasti: 0,
        presentVasti: 0,
        sankalpitShakha: 0,
        sankalpitMilan: 0,
        sankalpitSanghMandali: 0,
        sankalpitTotal: 0,
        presentSankalpitShakha: 0,
        presentSankalpitMilan: 0,
        presentSankalpitSanghMandali: 0,
        presentSankalpitTotal: 0,
        praudh: 0,
        tvk: 0,
        mahavidyalayin: 0,
        baal: 0,
        totalUpasthit: 0,
        shishu: 0
    };
    const [tableData, setTableData] = useState(defaultUpakhandAhawal);

    const prepareAhawalData = () => {
        const obj = {
            totalVasti: ahawalResponse?.total_vasti,
            presentVasti: ahawalResponse?.present_vasti,
            sankalpitShakha: ahawalResponse?.ekunSankalpit?.['शाखा'] || 0,
            sankalpitMilan: ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] || 0,
            sankalpitTotal: ahawalResponse?.ekunSankalpit?.['शाखा'] + ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'],
            presentSankalpitShakha: ahawalResponse?.present_sankalpit?.['शाखा'] || 0,
            presentSankalpitMilan: ahawalResponse?.present_sankalpit?.['साप्ताहिक मिलन'] || 0,
            presentSankalpitTotal: ahawalResponse?.present_sankalpit?.['शाखा'] + ahawalResponse?.present_sankalpit?.['साप्ताहिक मिलन'],
            praudh: ahawalResponse?.present_sayamsevak_upastiti?.['प्रौढ'] || 0,
            tvk: ahawalResponse?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'] || 0,
            mahavidyalayin: ahawalResponse?.present_sayamsevak_upastiti?.['महाविद्यालयीन'] || 0,
            baal: ahawalResponse?.present_sayamsevak_upastiti?.['बाल'] || 0,
            totalUpasthit: ahawalResponse?.present_sayamsevak_upastiti?.['एकूण'] || 0,
            shishu: ahawalResponse?.present_sayamsevak_upastiti?.['शिशु'] || 0
        };
        setTableData(obj);
    };
    const fetchBhagAhawalDS = () => {
        const sheetArray = [];
        const allNagars = ahawalResponse?.nagars;
        allNagars.forEach((ele) => {
            const obj = {
                nagarName: ele.location_name,
                totalsVasti: ele?.Vasti?.total_vasti || 0,
                presentVasti: ele?.Vasti?.present_vasti || 0,
                sankalpit_shakha_praudh: ele?.sankalp_total?.['शाखा']?.sub_types?.['प्रौढ'] || 0,
                sankalpit_shakha_tvk: ele?.sankalp_total?.['शाखा']?.sub_types?.['तरुण व्यवसायी'] || 0,
                sankalpit_shakha_mahavidyalayin: ele?.sankalp_total?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
                sankalpit_shakha_sanyuktaVidyarthi: ele?.sankalp_total?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
                sankalpit_milan_praudh: ele?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'] || 0,
                sankalpit_milan_tvk: ele?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'] || 0,
                sankalpit_milan_mahavidyalayin: ele?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
                sankalpit_milan_sanyuktaVidyarthi: ele?.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
                sankalpit_sanghMandali: ele?.sankalp_total?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'] || 0,

                upasthit_shakha_praudh: ele?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['प्रौढ'] || 0,
                upasthit_shakha_tvk: ele?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['तरुण व्यवसायी'] || 0,
                upasthit_shakha_mahavidyalayin: ele?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
                upasthit_shakha_sanyuktaVidyarthi: ele?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
                upasthit_milan_praudh: ele?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'] || 0,
                upasthit_milan_tvk: ele?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'] || 0,
                upasthit_milan_mahavidyalayin:
                    ele?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
                upasthit_milan_sanyuktaVidyarthi:
                    ele?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
                upasthit_sanghMandali: ele?.present_sankalpit_age_wise?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'] || 0,

                upasthit_praudh: ele?.present_sayamsevak_upastiti?.['प्रौढ'],
                upasthit_tvk: ele?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'],
                upasthit_mahavidyalayin: ele?.present_sayamsevak_upastiti?.['महाविद्यालयीन'],
                upasthit_baal: ele?.present_sayamsevak_upastiti?.['बाल'],
                upasthit_ekun: ele?.present_sayamsevak_upastiti?.['एकूण'],
                upasthit_shishu: ele?.present_sayamsevak_upastiti?.['शिशु']
            };
            obj.totalSankalpitEkake =
                +obj.sankalpit_shakha_praudh +
                +obj.sankalpit_shakha_tvk +
                +obj.sankalpit_shakha_mahavidyalayin +
                +obj.sankalpit_shakha_sanyuktaVidyarthi +
                +obj.sankalpit_milan_praudh +
                +obj.sankalpit_milan_tvk +
                +obj.sankalpit_milan_mahavidyalayin +
                +obj.sankalpit_milan_sanyuktaVidyarthi +
                +obj.sankalpit_sanghMandali;
            obj.totalUpasthitEkake =
                +obj.upasthit_shakha_praudh +
                +obj.upasthit_shakha_tvk +
                +obj.upasthit_shakha_mahavidyalayin +
                +obj.upasthit_shakha_sanyuktaVidyarthi +
                +obj.upasthit_milan_praudh +
                +obj.upasthit_milan_tvk +
                +obj.upasthit_milan_mahavidyalayin +
                +obj.upasthit_milan_sanyuktaVidyarthi +
                +obj.upasthit_sanghMandali;
            sheetArray.push([
                obj.nagarName,
                obj.totalsVasti,
                obj.presentVasti,
                obj.sankalpit_shakha_praudh,
                obj.sankalpit_shakha_tvk,
                obj.sankalpit_shakha_mahavidyalayin,
                obj.sankalpit_shakha_sanyuktaVidyarthi,
                obj.sankalpit_milan_praudh,
                obj.sankalpit_milan_tvk,
                obj.sankalpit_milan_mahavidyalayin,
                obj.sankalpit_milan_sanyuktaVidyarthi,
                obj.sankalpit_sanghMandali,
                obj.totalSankalpitEkake,
                obj.upasthit_shakha_praudh,
                obj.upasthit_shakha_tvk,
                obj.upasthit_shakha_mahavidyalayin,
                obj.upasthit_shakha_sanyuktaVidyarthi,
                obj.upasthit_milan_praudh,
                obj.upasthit_milan_tvk,
                obj.upasthit_milan_mahavidyalayin,
                obj.upasthit_milan_sanyuktaVidyarthi,
                obj.upasthit_sanghMandali,
                obj.totalUpasthitEkake,
                obj.upasthit_praudh,
                obj.upasthit_tvk,
                obj.upasthit_mahavidyalayin,
                obj.upasthit_baal,
                obj.upasthit_ekun,
                obj.upasthit_shishu
            ]);
        });
        const ekunSankalpit =
            +ahawalResponse?.ekunSankalpit?.['शाखा'] +
            +ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] +
            +ahawalResponse?.ekunSankalpit?.['संघ मंडळी'];
        const ekunUpasthitEkake =
            +ahawalResponse?.total_present?.['प्रौढ'] +
            +ahawalResponse?.total_present?.['तरुण व्यवसायी'] +
            +ahawalResponse?.total_present?.['महाविद्यालयीन विद्यार्थी'] +
            +ahawalResponse?.total_present?.['संयुक्त विद्यार्थी'] +
            +ahawalResponse?.total_present?.['संघ मंडळी'];
        sheetArray.push([
            'एकूण',

            ahawalResponse?.Vasti?.total_vasti,
            ahawalResponse?.Vasti?.present_vasti,
            ahawalResponse?.sankalp_total?.['शाखा']?.praudh,
            ahawalResponse?.sankalp_total?.['शाखा']?.tvk,
            ahawalResponse?.sankalp_total?.['शाखा']?.maha_vidyarthi,
            ahawalResponse?.sankalp_total?.['शाखा']?.baal,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.praudh,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.tvk,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.maha_vidyarthi,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.baal,
            ahawalResponse?.sankalp_total?.['संघ मंडळी'],
            ekunSankalpit,
            ahawalResponse?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['प्रौढ'],
            ahawalResponse?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['तरुण व्यवसायी'],
            ahawalResponse?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'],
            ahawalResponse?.present_sankalpit_age_wise?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'],
            ahawalResponse?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'],
            ahawalResponse?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'],
            ahawalResponse?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'],
            ahawalResponse?.present_sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'],
            ahawalResponse?.present_sankalpit_age_wise?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'],
            ekunUpasthitEkake,
            ahawalResponse?.present_sayamsevak_upastiti?.['प्रौढ'],
            ahawalResponse?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'],
            ahawalResponse?.present_sayamsevak_upastiti?.['महाविद्यालयीन'],
            ahawalResponse?.present_sayamsevak_upastiti?.['बाल'],
            ahawalResponse?.present_sayamsevak_upastiti?.['एकूण'],
            ahawalResponse?.present_sayamsevak_upastiti?.['शिशु']
        ]);
        sheetArray.unshift([
            '',
            'एकूण वस्ती',
            'सांघिकला उपस्थित',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'संघमंडळी',
            'एकूण एकके',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'संघमंडळी',
            'एकूण एकके',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'बाल',
            'एकूण',
            'शिशु'
        ]);
        sheetArray.unshift(['', '', '', '', '', 'शाखा', '', '', '', 'सा. मिलन', '', '', '', '', '', 'शाखा', '', '', '', 'सा. मिलन']);
        sheetArray.unshift([
            'नगर',
            'वस्ती',
            '',
            '',
            '',
            '',
            '',
            'एकूण संकल्पित एकके',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'एकूण उपस्थित एकके',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'उपस्थिती (स्वयंसेवक)'
        ]);
        return sheetArray;
    };
    const generateExcelFile = () => {
        setLoading(true);
        const wb = utils.book_new();
        const ws1 = utils.json_to_sheet(fetchBhagAhawalDS());
        utils.book_append_sheet(wb, ws1, 'अहवाल');

        writeFile(wb, `${ahawalResponse?.location_name || ''} ${ahawalResponse?.name_suffix || ''} अहवाल.xlsx`);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'यशस्वीरितीने फाईल डाउनलोड झालेली आहे!',
            variant: 'alert',
            alertSeverity: 'success'
        });
        setLoading(false);
    };
    useEffect(() => {
        if ((ahawalResponse && ahawalResponse.length > 0) || Object.keys(ahawalResponse).length > 0) {
            prepareAhawalData();
        }
    }, [ahawalResponse]);
    const renderFirstReport = () => {
        const tableRows = [];
        ahawalResponse?.nagars.forEach((r) => {
            const shakhaSankalpTotal =
                r?.sankalp_total?.['शाखा']?.maha_vidyarthi +
                r?.sankalp_total?.['शाखा']?.baal +
                r?.sankalp_total?.['शाखा']?.tvk +
                r?.sankalp_total?.['शाखा']?.praudh;
            const milanSankalpTotal =
                r?.sankalp_total?.['साप्ताहिक मिलन']?.maha_vidyarthi +
                r?.sankalp_total?.['साप्ताहिक मिलन']?.baal +
                r?.sankalp_total?.['साप्ताहिक मिलन']?.tvk +
                r?.sankalp_total?.['साप्ताहिक मिलन']?.praudh;
            const totalPresentSankalpit = r?.present_sankalpit?.['शाखा'] + r?.present_sankalpit?.['साप्ताहिक मिलन'];
            tableRows.push(
                <TableRow>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r.location_name}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.Vasti?.total_vasti || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.Vasti?.present_vasti || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {shakhaSankalpTotal || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {milanSankalpTotal || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {shakhaSankalpTotal + milanSankalpTotal || 0}
                    </TableCell>

                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sankalpit?.['शाखा'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sankalpit?.['साप्ताहिक मिलन'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {totalPresentSankalpit || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['प्रौढ'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['महाविद्यालयीन'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['बाल'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['एकूण'] || 0}
                    </TableCell>
                    <TableCell className="tableCell" align="center" colSpan={1}>
                        {r?.present_sayamsevak_upastiti?.['शिशु'] || 0}
                    </TableCell>
                </TableRow>
            );
        });
        return (
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                नगर
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={2}>
                                वस्त्या
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={3}>
                                एकूण संकल्पित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={3}>
                                एकूण उपस्थित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={6}>
                                उपस्थिती (स्वयंसेवक)
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="tableCell" align="center" />
                            <TableCell className="tableCell" align="center">
                                एकूण वस्त्या
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सांघिकला उपस्थित वस्त्या
                            </TableCell>

                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा.मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा.मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                प्रौढ
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                तरुण व्यवसायी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                महाविद्यालयीन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                बाल
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शिशु
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{tableRows}</TableBody>
                </Table>
            </TableContainer>
        );
    };
    const renderSecondReport = () => {
        const sankalpTotal = ahawalResponse?.ekunSankalpit?.['शाखा'] + ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'];

        return (
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell" align="center" colSpan={2}>
                                नगर
                            </TableCell>

                            <TableCell className="tableCell" align="center" colSpan={3}>
                                एकूण संकल्पित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={3}>
                                एकूण उपस्थित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={6}>
                                उपस्थिती (स्वयंसेवक)
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="tableCell" align="center">
                                एकूण नगर
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सांघिक झालेले नगर
                            </TableCell>

                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा.मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा.मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                प्रौढ
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                तरुण व्यवसायी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                महाविद्यालयीन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                बाल
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शिशु
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse.total_nagar || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse.present_nagar || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.ekunSankalpit?.['शाखा'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {sankalpTotal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sankalpit?.['शाखा'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sankalpit?.['साप्ताहिक मिलन'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {`${ahawalResponse?.present_sankalpit?.['शाखा'] + ahawalResponse?.present_sankalpit?.['साप्ताहिक मिलन']}` ||
                                    0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['प्रौढ'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['महाविद्यालयीन'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['बाल'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['एकूण'] || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={1}>
                                {ahawalResponse?.present_sayamsevak_upastiti?.['शिशु'] || 0}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };
    return (
        <>
            {loading && <Loader />}
            <div style={{ margin: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Typography variant="h3">{`${ahawalResponse?.location_name || ''} ${
                        ahawalResponse?.name_suffix || ''
                    } अहवाल`}</Typography>
                    <Button variant="contained" color="success" sx={{ m: '10px' }} onClick={generateExcelFile}>
                        <FormattedMessage id="menu.download.ahawal" />
                    </Button>
                </div>
                {renderFirstReport()}
            </div>
            <div style={{ margin: '10px' }}>
                {/* <Typography variant="h3">{`${ahawalResponse?.location_local_name || ''} ${
                    ahawalResponse?.name_suffix || ''
                } अहवाल`}</Typography> */}
                {/* <Button variant="contained" color="success" sx={{ m: '10px' }} onClick={generateExcelFile}>
                    <FormattedMessage id="menu.download.ahawal" />
                </Button> */}
                {renderSecondReport()}
            </div>
        </>
    );
}
