import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
import useAuth from 'hooks/useAuth';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';

// auth provider
import { FirebaseProvider } from 'contexts/FirebaseContext';
import axios from 'axios';
// import {JWTProvider} from 'contexts/JWTContext';
// import {Auth0Provider} from 'contexts/Auth0Context';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useNavigate } from 'react-router';
import { SNACKBAR_OPEN } from 'store/actions';
import { LogoutSystem } from 'services/logoutServices';

// ===========================|| APP ||=========================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = async () => {
        try {
            await logout();
            setTimeout(() => {
                navigate('/', { replace: true });
            }, 1000);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        axios.interceptors.request.use(
            async (config) => {
                const tokenAuth = await firebase.auth().currentUser.getIdToken();
                Object.assign(config.headers, {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenAuth}`
                });
                return config;
            },
            (error) => {
                console.log('error........', error.response.code);
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            (response) => {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                // console.log('response.......', response);
                const data = response;
                return response;
            },
            async (error) => {
                if (error.response.status === 401) {
                    console.log('log out');
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: 'अनधिकृत उपयोगकर्ता!',
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
                    await LogoutSystem();
                    // setTimeout(() => {
                    //     handleLogout();
                    // }, 1000);
                }
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                return Promise.reject(error);
            }
        );
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                {/* RTL layout */}
                {/* <RTLLayout> */}
                <Locales>
                    <NavigationScroll>
                        <FirebaseProvider>
                            <Routes />
                            <Snackbar />
                        </FirebaseProvider>
                    </NavigationScroll>
                </Locales>
                {/* </RTLLayout> */}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
