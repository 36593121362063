import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { addSwayamSevak, getSingleHostory, updateSwayamSevak } from 'services/swayamsevak-service';
import HistorySuchiDialog from './HistorySuchiDialog';
import { DatePicker, DateTimePicker, LocalizationProvider, MobileDatePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

export default function EditSuchiDialog({
    editSuchiModel,
    setEditSuchiModel,
    selectedSuchiRow,
    setSelectedSuchiRow,
    vyavsayTree,
    shreniAndDayitv,
    dayitvTree,
    fetchAllSuchiLocationWise,
    sanghShikshan,
    disableAllFields,
    locationId
}) {
    // state and variable
    const [errorListData, setErrorListData] = useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();
    const [historyModel, setHistoryModel] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [timeline, setTimeline] = useState([]);
    const bloodGroupData = ['A +', 'B +', 'AB +', 'O +', 'A -', 'B -', 'AB -', 'O -'];

    const handleCloseSuch = () => {
        setEditSuchiModel(false);
        setSelectedSuchiRow({});
        setDisableBtn(false);
    };
    const handleInputChange = (e) => {
        const tempObjForm = { ...selectedSuchiRow };
        tempObjForm[e.target.name] = e.target.value;
        setSelectedSuchiRow(tempObjForm);
    };
    const handleInputChangeBoolean = (e) => {
        const tempObjForm = { ...selectedSuchiRow };
        if (e.target.value === 'true') {
            tempObjForm[e.target.name] = true;
        }
        if (e.target.value === 'false') {
            tempObjForm[e.target.name] = false;
        }
        setSelectedSuchiRow(tempObjForm);
    };

    const fetchHistory = () => {
        setTimeline([]);
        getSingleHostory(selectedSuchiRow?.id)
            .then((resp) => {
                // console.log('history', resp);
                const records = JSON.parse(JSON.stringify(resp?.records));

                const convertedData =
                    records &&
                    records?.map((item) => {
                        const data = {
                            // id: item?.id,
                            // level: item?.level,
                            // location: item?.location,
                            location_id: item?.location_id,
                            'पहिले नांव': item?.firstName,
                            'वडिलांचे नांव': item?.fatherName,
                            आडनांव: item?.surname,

                            'जन्म तारीख': item?.dateOfBirth,
                            // bloodGroup: bloodGroupData?.includes(item['रक्त गट']?.toString()?.trim()) ? item['रक्त गट'] : 'माहीत नाही',
                            'रक्त गट': item?.bloodGroup,
                            व्यवसाय: item?.occupation,
                            'व्यवसाय तपशील 1': item?.occupationDetails1,
                            'व्यवसाय तपशील 2': item?.occupationDetails2,
                            'पूर्ण पत्ता': item?.address,
                            'पिन कोड': item?.pinCode,
                            दूरभाष: item?.phone,
                            भ्रमणभाष: item?.mobile,
                            'इ-मेल आयडी': item?.email,
                            श्रेणी: item?.category,
                            खाते: item?.account,
                            'दायित्व स्तर': item?.responsibilityLevel,
                            दायित्व: item?.responsibility,
                            'दायित्व अन्य': item?.otherResponsibility,
                            'विशेष नैपुण्य': item?.specialSkills,
                            'संघ शिक्षण': item?.trainingInOrganization,
                            प्रतिज्ञा: item?.isPledge ? 'हो' : 'नाही',
                            टोपी: item?.isCap ? 'हो' : 'नाही',
                            सदरा: item?.isSadara ? 'हो' : 'नाही',
                            विजार: item?.isWizar ? 'हो' : 'नाही',
                            पदवेष: item?.isPosition ? 'हो' : 'नाही',
                            पट्टा: item?.isPatta ? 'हो' : 'नाही',
                            दंड: item?.isPunishment ? 'हो' : 'नाही',
                            'दुचाकी आहे?': item?.isDuchaki ? 'हो' : 'नाही',
                            'चार चाकी आहे?': item?.isCharChaki ? 'हो' : 'नाही',
                            createdAt: item?.createdAt
                        };
                        return data;
                    });
                // console.log('convertedData', convertedData);

                if (records && records?.length > 0) {
                    const allKeys = Object.keys(convertedData[0]);
                    const previousCurrentData = [];
                    convertedData?.map((value, index) => {
                        previousCurrentData.push({
                            current: convertedData[index - 1],
                            previous: convertedData[index]
                        });
                        return true;
                    });
                    // console.log('previousCurrentData', previousCurrentData);

                    const timelineData = [];

                    previousCurrentData?.map((value) => {
                        const nameNChangeValue = [];
                        if (value?.previous && value?.current) {
                            allKeys?.map((keyName) => {
                                const preData = value?.previous;
                                const curData = value?.current;
                                if (preData[keyName] !== curData[keyName]) {
                                    // console.log('no matched', keyName);
                                    const tempData = {
                                        name: keyName,
                                        previousValue: preData[keyName],
                                        currentValue: curData[keyName]
                                    };
                                    nameNChangeValue.push(tempData);
                                }
                                return true;
                            });
                        }
                        // console.log('w', value);
                        timelineData.push({
                            changesIn: [...nameNChangeValue],
                            createData: value?.current?.createdAt
                        });

                        return true;
                    });

                    if (timelineData?.length <= 1 && !timelineData[0]?.createData) {
                        setTimeline([]);
                    } else if (timelineData[0]?.createData === undefined) {
                        timelineData.splice(0, 1);

                        setTimeline(timelineData);
                    } else {
                        setTimeline(timelineData);
                    }

                    // console.log('timelineData', JSON.parse(JSON.stringify(timelineData)));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // console.log('selectedSuchiRow', selectedSuchiRow);

    const occupationData = () => {
        let tempOccupation1Tree;
        const occupation = vyavsayTree?.find((value) => value.localized_name?.trim() === selectedSuchiRow['व्यवसाय']?.trim());
        const occupation1Array = occupation?.VyavasayKshetras;
        if (occupation1Array && occupation1Array?.length > 0) {
            tempOccupation1Tree = [...occupation1Array];
        } else {
            tempOccupation1Tree = [];
        }
        // console.log(tempOccupationTree);

        let tempOccupation2Tree;

        const occupation1 = occupation1Array?.find((value) => value.localized_name?.trim() === selectedSuchiRow['व्यवसाय तपशील 1']?.trim());
        const occupation2Array = occupation1?.vyavasay_tapshils;
        if (occupation2Array && occupation2Array?.length > 0) {
            tempOccupation2Tree = [...occupation2Array];
        } else {
            tempOccupation2Tree = [];
        }
        const tempOccupationTree = {
            occupation1: tempOccupation1Tree,
            occupation2: tempOccupation2Tree
        };
        return tempOccupationTree;
    };

    const shreniData = () => {
        let tempShreniTree;

        const shreniData = shreniAndDayitv?.find((value) => value.localized_name?.trim() === selectedSuchiRow['श्रेणी']?.trim());
        const khateArray = shreniData?.khateData;
        if (khateArray && khateArray?.length > 0) {
            tempShreniTree = [...khateArray];
        } else {
            tempShreniTree = [];
        }
        return tempShreniTree;
    };

    const dayitvData = () => {
        let tempDayitvTree;

        const dayitvData = dayitvTree?.find(
            (value) => value.localized_name?.trim()?.replaceAll(' ', '') === selectedSuchiRow['दायित्व स्तर']?.trim()?.replaceAll(' ', '')
        );
        const dayitvStarArray = dayitvData?.dayitvaData;
        if (dayitvStarArray && dayitvStarArray?.length > 0) {
            tempDayitvTree = [...dayitvStarArray];
        } else {
            tempDayitvTree = [];
        }
        return tempDayitvTree;
    };

    const occupationTree = occupationData();
    const shreniKhateTree = shreniData();
    const dayitvTreeData = dayitvData();

    const validateData = () => {
        let status = true;
        const errorList = [];
        setErrorListData([]);

        const data = [];
        data.push(selectedSuchiRow);

        data?.map((item, index) => {
            const rowNumber = index + 2;
            if (_.isEmpty(item['पहिले नांव']?.trim()) || typeof item['पहिले नांव'] === 'undefined') {
                status = false;
                errorList.push({
                    errorIn: 'पहिले नांव',
                    errorType: 'mandatory',
                    message: '"पहिले नांव" आवश्यक आहे',
                    row: rowNumber
                });
            }
            if (_.isEmpty(item['आडनांव']?.trim()) || typeof item['आडनांव'] === 'undefined') {
                status = false;
                errorList.push({
                    errorIn: 'आडनांव',
                    errorType: 'mandatory',
                    message: '"आडनांव" आवश्यक आहे',
                    row: rowNumber
                });
            }

            if (!_.isEmpty(item['दूरभाष']) && !_.isNumber(parseInt(item['दूरभाष'], 10))) {
                status = false;
                errorList.push({
                    errorIn: 'दूरभाष',
                    errorType: 'number',
                    message: '"दूरभाष" संख्या असणे आवश्यक आहे',
                    row: rowNumber
                });
            }
            if (_.isEmpty(item['भ्रमणभाष']) || typeof item['भ्रमणभाष'] === 'undefined') {
                status = false;
                errorList.push({
                    errorIn: 'भ्रमणभाष',
                    errorType: 'mandatory',
                    message: '"भ्रमणभाष" आवश्यक आहे',
                    row: rowNumber
                });
            }

            if (!_.isNumber(parseInt(item['भ्रमणभाष'], 10))) {
                status = false;
                errorList.push({
                    errorIn: 'भ्रमणभाष',
                    errorType: 'number',
                    message: '"भ्रमणभाष" संख्या असणे आवश्यक आहे',
                    row: rowNumber
                });
            }

            if (_.isNumber(parseInt(item['भ्रमणभाष'], 10)) && item['भ्रमणभाष']?.toString()?.length !== 10) {
                status = false;
                errorList.push({
                    errorIn: 'भ्रमणभाष',
                    errorType: 'number length',
                    message: '"भ्रमणभाष" संख्या १० अंकी असणे आवश्यक आहे',
                    row: rowNumber
                });
            }

            // if (_.isEmpty(item['इ-मेल आयडी']?.trim()) || typeof item['इ-मेल आयडी'] === 'undefined') {
            //     status = false;
            //     errorList.push({
            //         errorIn: 'इ-मेल आयडी',
            //         errorType: 'mandatory',
            //         message: '"इ-मेल आयडी" आवश्यक आहे',
            //         row: rowNumber
            //     });
            // }

            // const duplicateEmail = data?.filter((fvalue) => fvalue['इ-मेल आयडी']?.trim() === item['इ-मेल आयडी']?.trim());
            // if (duplicateEmail?.length > 1) {
            //     status = false;
            //     errorList.push({
            //         errorIn: 'इ-मेल आयडी',
            //         errorType: 'duplicate',
            //         message: 'इ-मेल आयडी वेगळी असावी',
            //         row: rowNumber
            //     });
            // }

            if (_.isEmpty(item['पिन कोड']) || typeof item['पिन कोड'] === 'undefined') {
                status = false;
                errorList.push({
                    errorIn: 'पिन कोड',
                    errorType: 'mandatory',
                    message: '"पिन कोड" आवश्यक आहे',
                    row: rowNumber
                });
            }

            if (!_.isNumber(parseInt(item['पिन कोड'], 10))) {
                status = false;
                errorList.push({
                    errorIn: 'पिन कोड',
                    errorType: 'number',
                    message: '"पिन कोड" संख्या असणे आवश्यक आहे',
                    row: rowNumber
                });
            }

            if (_.isNumber(parseInt(item['पिन कोड'], 10)) && item['पिन कोड']?.toString()?.length !== 6) {
                status = false;
                errorList.push({
                    errorIn: 'पिन कोड',
                    errorType: 'number length',
                    message: '"पिन कोड" संख्या 6 अंकी असणे आवश्यक आहे',
                    row: rowNumber
                });
            }

            // if (item['जन्म तारीख\n(DD-MM-YYYY)'] && typeof item['जन्म तारीख\n(DD-MM-YYYY)'] !== 'number') {
            //     const dob = item['जन्म तारीख\n(DD-MM-YYYY)'].replaceAll('/[^ws]/gi', '-');
            //     // const dob = item['जन्म तारीख\n(DD-MM-YYYY)'];

            //     const parts = dob.split('-');
            //     const day = parts[0];
            //     const month = parts[1];
            //     const year = parts[2];

            //     const formattedDate = `${month}-${day}-${year}`;
            //     const currentDate = new Date();

            //     const userDate = new Date(formattedDate);
            //     // const userDate = xlsvDateFormat(item['जन्म तारीख\n(DDMMYYYY)']);
            //     // console.log('dob...........', item['जन्म तारीख\n(DD-MM-YYYY)']);
            //     // const currentDate = new Date();
            //     if (userDate >= currentDate) {
            //         errorList.push({
            //             errorIn: 'जन्म तारीख\n(DD-MM-YYYY)',
            //             errorType: 'invalid dob',
            //             message: 'कृपया वैध "जन्म तारीख" निवडा',
            //             row: rowNumber
            //         });
            //         // console.log(userDate.toLocaleDateString());
            //     }
            //     if (JSON.stringify(userDate) === 'null' || JSON.stringify(userDate) === null) {
            //         errorList.push({
            //             errorIn: 'जन्म तारीख\n(DD-MM-YYYY)',
            //             errorType: 'invalid dob',
            //             message: 'कृपया वैध "जन्म तारीख" निवडा',
            //             row: rowNumber
            //         });
            //         // console.log(userDate.toLocaleDateString());
            //     }
            // } else {
            //     errorList.push({
            //         errorIn: 'जन्म तारीख\n(DD-MM-YYYY)',
            //         errorType: 'invalid dob',
            //         message: 'कृपया वैध "जन्म तारीख" निवडा',
            //         row: rowNumber
            //     });
            // }

            // console.log(xlsvDateFormat(item['जन्म तारीख\n(DDMMYYYY)']).toISOString('dd/MM/yyyy'));
            // console.log(xlsvDateFormat(item['जन्म तारीख\n(DDMMYYYY)']).toLocaleDateString());

            if (_.isEmpty(item['रक्त गट']?.toString()?.trim()) || typeof item['रक्त गट'] === 'undefined') {
                status = false;
                errorList.push({
                    errorIn: 'रक्त गट',
                    errorType: 'mandatory',
                    message: 'रक्त गट आवश्यक आहे',
                    row: rowNumber
                });
            }

            // occupation validation
            const occupation = vyavsayTree?.find((value) => value.localized_name?.trim() === item['व्यवसाय']?.trim());
            // console.log('v', item['व्यवसाय']);
            // console.log('occupation', occupation);
            if (item['व्यवसाय'] && occupation) {
                const occupation2 = occupation?.VyavasayKshetras?.find((value) => value.localized_name === item['व्यवसाय तपशील 1']);
                // console.log('occupation2', occupation2);
                // if (occupation?.VyavasayKshetras && (occupation?.VyavasayKshetras.length > 0) & item['व्यवसाय तपशील 1']) {
                //     errorList.push({
                //         errorIn: 'व्यवसाय तपशील 1',
                //         errorType: 'invalid input',
                //         message: 'व्यवसाय तपशील 1 चुकीची निवड केलीय   ',
                //         row: rowNumber
                //     });
                // }
                if (occupation?.VyavasayKshetras && occupation?.VyavasayKshetras.length > 0 && item['व्यवसाय तपशील 1']) {
                    if (occupation2) {
                        const occupation3 = occupation2?.vyavasay_tapshils?.find(
                            (value) => value.localized_name === item['व्यवसाय तपशील 2']
                        );

                        if (occupation2?.vyavasay_tapshils && occupation2?.vyavasay_tapshils.length > 0 && item['व्यवसाय तपशील 2']) {
                            if (item['व्यवसाय तपशील 2']) {
                                if (!occupation3) {
                                    // console.log('v3', item['व्यवसाय तपशील 2']);
                                    // console.log('wrong occupation 3');
                                    errorList.push({
                                        errorIn: 'व्यवसाय तपशील 1',
                                        errorType: 'invalid input',
                                        message: '"व्यवसाय तपशील 2" चुकीची निवड केलीय   ',
                                        row: rowNumber
                                    });
                                }
                            }
                            // console.log('occupation3', occupation3);
                        } else if (
                            occupation2?.vyavasay_tapshils &&
                            occupation2?.vyavasay_tapshils.length <= 0 &&
                            item['व्यवसाय तपशील 2']
                        ) {
                            errorList.push({
                                errorIn: 'व्यवसाय तपशील 2',
                                errorType: 'invalid input',
                                message: '"व्यवसाय तपशील 2" चुकीची निवड केलीय   ',
                                row: rowNumber
                            });
                        } else if (
                            occupation2?.vyavasay_tapshils &&
                            occupation2?.vyavasay_tapshils.length > 0 &&
                            !item['व्यवसाय तपशील 2']
                        ) {
                            errorList.push({
                                errorIn: 'व्यवसाय तपशील 2',
                                errorType: 'invalid input',
                                message: '"व्यवसाय तपशील 2" निवडणे आवश्यक आहे',
                                row: rowNumber
                            });
                        }
                    } else {
                        // console.log('v2', item['व्यवसाय तपशील 1']);
                        // console.log('wrong occupation 2');
                        errorList.push({
                            errorIn: 'व्यवसाय तपशील 1',
                            errorType: 'invalid input',
                            message: '"व्यवसाय तपशील 1" चुकीची निवड केलीय   ',
                            row: rowNumber
                        });
                    }
                } else if (occupation?.VyavasayKshetras && occupation?.VyavasayKshetras.length <= 0 && item['व्यवसाय तपशील 1']) {
                    errorList.push({
                        errorIn: 'व्यवसाय तपशील 1',
                        errorType: 'invalid input',
                        message: '"व्यवसाय तपशील 1" चुकीची निवड केलीय   ',
                        row: rowNumber
                    });
                } else if (occupation?.VyavasayKshetras && occupation?.VyavasayKshetras.length > 0 && !item['व्यवसाय तपशील 1']) {
                    errorList.push({
                        errorIn: 'व्यवसाय तपशील 1',
                        errorType: 'invalid input',
                        message: '"व्यवसाय तपशील 1" निवडणे आवश्यक आहे',
                        row: rowNumber
                    });
                }
            } else if (item['व्यवसाय'] && !occupation) {
                errorList.push({
                    errorIn: 'व्यवसाय',
                    errorType: 'invalid input',
                    message: '"व्यवसाय" चुकीची निवड केलीय   ',
                    row: rowNumber
                });
            }

            // shreni and dayitv validation
            const shreniData = shreniAndDayitv?.find((value) => value.localized_name?.trim() === item['श्रेणी']?.trim());
            if (item['श्रेणी'] && shreniData) {
                const khateData = shreniData?.khateData?.find((value) => value.localized_name?.trim() === item['खाते']?.trim());
                if (shreniData?.khateData && shreniData?.khateData.length > 0 && item['खाते']) {
                    if (item['खाते']) {
                        if (!khateData) {
                            errorList.push({
                                errorIn: 'खाते',
                                errorType: 'invalid input',
                                message: '"खाते" चुकीची निवड केलीय',
                                row: rowNumber
                            });
                        }
                    }
                    // console.log('occupation3', occupation3);
                } else if (shreniData?.khateData && shreniData?.khateData.length <= 0 && item['खाते']) {
                    errorList.push({
                        errorIn: 'खाते',
                        errorType: 'invalid input',
                        message: '"खाते" चुकीची निवड केलीय',
                        row: rowNumber
                    });
                } else if (shreniData?.khateData && shreniData?.khateData.length > 0 && !item['खाते']) {
                    errorList.push({
                        errorIn: 'खाते',
                        errorType: 'invalid input',
                        message: '"खाते" निवडणे आवश्यक आहे',
                        row: rowNumber
                    });
                }
            } else if (item['श्रेणी'] && !shreniData) {
                errorList.push({
                    errorIn: 'श्रेणी',
                    errorType: 'invalid input',
                    message: '"श्रेणी" चुकीची निवड केलीय',
                    row: rowNumber
                });
            }

            const dayatvStarData = dayitvTree?.find(
                (value) => value.localized_name?.trim()?.replaceAll(' ', '') === item['दायित्व स्तर']?.trim()?.replaceAll(' ', '')
            );
            // console.log('दायित्व स्तर', item['दायित्व स्तर']);
            // console.log('dayitvTree............', dayitvTree);

            if (item['दायित्व स्तर'] && dayatvStarData) {
                // console.log('dayatvStarData............', dayatvStarData);

                const dayitvaData = dayatvStarData?.dayitvaData?.find((value) => value.localized_name?.trim() === item['दायित्व']?.trim());

                // console.log('दायित्व', item['दायित्व']);
                // console.log('dayitvaData............', dayitvaData);

                if (dayatvStarData?.dayitvaData && dayatvStarData?.dayitvaData.length > 0 && item['दायित्व']) {
                    if (item['दायित्व']) {
                        if (!dayitvaData) {
                            errorList.push({
                                errorIn: 'दायित्व',
                                errorType: 'invalid input',
                                message: '"दायित्व" चुकीची निवड केलीय   ',
                                row: rowNumber
                            });
                        }
                    }
                    // console.log('occupation3', occupation3);
                } else if (dayatvStarData?.dayitvaData && dayatvStarData?.dayitvaData.length <= 0 && item['दायित्व']) {
                    errorList.push({
                        errorIn: 'दायित्व',
                        errorType: 'invalid input',
                        message: '"दायित्व" चुकीची निवड केलीय   ',
                        row: rowNumber
                    });
                } else if (dayatvStarData?.dayitvaData && dayatvStarData?.dayitvaData.length > 0 && !item['दायित्व']) {
                    errorList.push({
                        errorIn: 'दायित्व',
                        errorType: 'invalid input',
                        message: '"दायित्व" निवडणे आवश्यक आहे',
                        row: rowNumber
                    });
                }
            } else if (item['दायित्व स्तर'] && !dayatvStarData) {
                errorList.push({
                    errorIn: 'दायित्व स्तर',
                    errorType: 'invalid input',
                    message: '"दायित्व स्तर" चुकीची निवड केलीय   ',
                    row: rowNumber
                });
            }

            // sangh shikshan
            const sanghShikshanData = sanghShikshan?.find((value) => value.localized_name?.trim() === item['संघ शिक्षण']?.trim());
            if (item['संघ शिक्षण'] && !sanghShikshanData) {
                errorList.push({
                    errorIn: 'संघ शिक्षण',
                    errorType: 'invalid input',
                    message: '"संघ शिक्षण" चुकीची निवड केलीय   ',
                    row: rowNumber
                });
            }

            return true;
        });
        setErrorListData(errorList);
        return errorList;
    };

    const getArrayToString = (data) => {
        const stringData = data.toString();
        return stringData?.replaceAll(',', ', ');
    };

    const filterError = (errorListData) => {
        let filterdData = [];
        if (errorListData && errorListData.length > 0) {
            const result = Object.values(
                errorListData.reduce((c, { row, message }) => {
                    c[row] = c[row] || { row, message: [] };
                    c[row].message = c[row].message.concat(Array.isArray(message) ? message : [message]);
                    return c;
                }, {})
            );
            filterdData = result;
        } else {
            filterdData = [];
        }
        if (filterdData && filterdData?.length > 0) {
            filterdData?.map((item) =>
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: `${getArrayToString(item.message)}.`,
                    variant: 'alert',
                    alertSeverity: 'warning'
                })
            );
        }
        return filterdData;
    };

    const getBoolean = (data) => {
        let status = false;
        if (data === true || data === 'हो') {
            status = true;
        } else {
            status = false;
        }
        return status;
    };
    const convertDate = (inputFormat) => {
        let date = '';
        if (inputFormat) {
            const dateString = inputFormat;
            const dateObj = new Date(dateString);
            const day = dateObj.getDate().toString().padStart(2, '0');
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based
            const year = dateObj.getFullYear().toString();

            const formattedDate = `${day}-${month}-${year}`;
            date = formattedDate;
            // const dateFormat = new Date(inputFormat).toISOString();
            // const dateSplit = dateFormat.split('T')[0];
            // date = `${dateSplit.split('-')[2]}-${dateSplit.split('-')[1]}-${dateSplit.split('-')[0]}`;
        } else {
            date = undefined;
        }
        return date;
    };

    const submitData = (data) => {
        setDisableBtn(true);
        const payload = [];
        payload.push(data);

        if (disableAllFields) {
            updateSwayamSevak(payload)
                .then((resp) => {
                    // console.log(resp);
                    setDisableBtn(false);
                    if (resp?.message === 'Users updated successfully.' || resp?.code === 200) {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: 'यशस्वीरित्या Update झाली',
                            variant: 'alert',
                            alertSeverity: 'success'
                        });
                    }
                    fetchAllSuchiLocationWise();
                    handleCloseSuch();
                    // setBtnDisable(false);
                    // clearAllFields();
                })
                .catch((error) => {
                    setDisableBtn(false);
                    console.log(error);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: 'अयशस्वी Update',
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
                    // setBtnDisable(false);
                    // if (error?.response?.status === 400 && error?.response?.data?.message === 'submision failed') {
                    //     // console.log(error?.response?.data?.error);
                    //     setErrorListData(error?.response?.data?.error);
                    // } else {
                    //     dispatch({
                    //         type: SNACKBAR_OPEN,
                    //         open: true,
                    //         message: 'Something Went Wrong, Please Try Again Later.',
                    //         variant: 'alert',
                    //         alertSeverity: 'error'
                    //     });
                    // }
                });
        } else {
            const payloadForSave = {
                selectedLocation: locationId,
                swayamsevaks: payload
            };
            addSwayamSevak(payloadForSave)
                .then((resp) => {
                    setDisableBtn(false);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: 'यशस्वीरित्या जतन करण्यात आलेले आहे.',
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    fetchAllSuchiLocationWise();
                    handleCloseSuch();
                    // setBtnDisable(false);
                    // clearAllFields();
                })
                .catch((error) => {
                    setDisableBtn(false);
                    console.log(error);
                    if (error?.response?.status === 400 && error?.response?.data?.message === 'submision failed') {
                        // console.log(error?.response?.data?.error);
                        filterError(error?.response?.data?.error);
                    } else {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: 'Something Went Wrong, Please Try Again Later.',
                            variant: 'alert',
                            alertSeverity: 'error'
                        });
                    }
                });
        }
    };
    const updateData = () => {
        // validateData();
        // const filterdErrorList = filterError();
        const errorList = validateData();
        if (errorList && errorList?.length > 0) {
            filterError(errorList);
        } else {
            const data = {
                id: selectedSuchiRow?.id,
                level: selectedSuchiRow?.level,
                location: selectedSuchiRow?.location,
                location_id: selectedSuchiRow?.location?.location_id,
                firstName: selectedSuchiRow['पहिले नांव'],
                fatherName: selectedSuchiRow['वडिलांचे नांव'],

                surname: selectedSuchiRow['आडनांव'],

                // dateOfBirth: xlsvDateFormat(selectedSuchiRow['जन्म तारीख\n(DDMMYYYY)']).toLocaleDateString() || '',
                // dateOfBirth: selectedSuchiRow['जन्म तारीख\n(DDMMYYYY)'],

                dateOfBirth: disableAllFields ? selectedSuchiRow['जन्म तारीख'] : convertDate(selectedSuchiRow['जन्म तारीख']),
                bloodGroup: selectedSuchiRow['रक्त गट'] || 'माहीत नाही',
                occupation: selectedSuchiRow['व्यवसाय'],
                occupationDetails1: selectedSuchiRow['व्यवसाय तपशील 1'],
                occupationDetails2: selectedSuchiRow['व्यवसाय तपशील 2'],
                address: selectedSuchiRow['पूर्ण पत्ता'],
                pinCode: selectedSuchiRow['पिन कोड'] ? parseInt(selectedSuchiRow['पिन कोड'], 10) : undefined,
                phone: selectedSuchiRow['दूरभाष'] ? parseInt(selectedSuchiRow['दूरभाष'], 10) : undefined,
                mobile: selectedSuchiRow['भ्रमणभाष'] ? parseInt(selectedSuchiRow['भ्रमणभाष'], 10) : undefined,
                email: selectedSuchiRow['इ-मेल आयडी'],
                category: selectedSuchiRow['श्रेणी'],
                account: selectedSuchiRow['खाते'],
                responsibilityLevel: selectedSuchiRow['दायित्व स्तर'],
                responsibility: selectedSuchiRow['दायित्व'],
                otherResponsibility: selectedSuchiRow['दायित्व अन्य'],
                specialSkills: selectedSuchiRow['विशेष नैपुण्य'],
                trainingInOrganization: selectedSuchiRow['संघ शिक्षण'],
                isPledge: getBoolean(selectedSuchiRow['प्रतिज्ञा']),
                isCap: getBoolean(selectedSuchiRow['टोपी']),
                isSadara: getBoolean(selectedSuchiRow['सदरा']),
                isWizar: getBoolean(selectedSuchiRow['विजार']),
                isPosition: getBoolean(selectedSuchiRow['पदवेष']),
                isPatta: getBoolean(selectedSuchiRow['पट्टा']),
                isPunishment: getBoolean(selectedSuchiRow['दंड']),
                isDuchaki: getBoolean(selectedSuchiRow['दुचाकी आहे?']),
                isCharChaki: getBoolean(selectedSuchiRow['चार चाकी आहे?'])
            };
            submitData(data);
        }
    };

    return (
        <>
            <Dialog
                // fullScreen={fullScreen}
                maxWidth="100px"
                style={{ maxWidth: '1080px', width: '100%', margin: 'auto' }}
                fullWidth
                open={editSuchiModel}
                onClose={handleCloseSuch}
                // aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">स्वयंसेवक सूची {_.isEmpty(selectedSuchiRow) ? 'भरा' : 'सुधारणे'} </DialogTitle>
                {/* <DialogContent>
                    <DialogContentText>खाली क्रमशः भौगोलिक रचना आहे, त्यामधून आवश्यक ते स्थान निवडा</DialogContentText>
                </DialogContent> */}
                <DialogActions>
                    <Box sx={{ px: 2 }}>
                        {/* personal information */}
                        <h3>वैयक्तिक माहिती</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    name="पहिले नांव"
                                    label="पहिले नांव"
                                    value={selectedSuchiRow['पहिले नांव']}
                                    onChange={handleInputChange}
                                    disabled={disableAllFields}
                                    // defaultValue={formik.values.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    name="वडिलांचे नांव"
                                    label="वडिलांचे नांव"
                                    onChange={handleInputChange}
                                    disabled={disableAllFields}
                                    value={selectedSuchiRow['वडिलांचे नांव']}
                                    // defaultValue={formik.values.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    name="आडनांव"
                                    label="आडनांव"
                                    onChange={handleInputChange}
                                    disabled={disableAllFields}
                                    value={selectedSuchiRow['आडनांव']}
                                    // defaultValue={formik.values.name}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                                        label="जन्म तारीख"
                                        name="जन्म तारीख"
                                        value={selectedSuchiRow['जन्म तारीख']}
                                        maxDate={new Date()}
                                        disabled={disableAllFields}
                                        onChange={(newValue) => {
                                            // console.log(newValue);

                                            const tempObjForm = { ...selectedSuchiRow };
                                            tempObjForm['जन्म तारीख'] = newValue;
                                            setSelectedSuchiRow(tempObjForm);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>रक्त गट</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        label="रक्त गट"
                                        name="रक्त गट"
                                        disabled={disableAllFields}
                                        value={selectedSuchiRow['रक्त गट'] ?? ''}
                                        defaultValue=""
                                        onChange={(e) => {
                                            // handleInputChange(e);
                                            const tempObj = { ...selectedSuchiRow };
                                            tempObj['रक्त गट'] = e.target.value;
                                            setSelectedSuchiRow({ ...tempObj });
                                        }}
                                        size="medium"
                                        fullWidth
                                        // inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {bloodGroupData?.map((item) => (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        ))}
                                        <MenuItem value="माहीत नाही">माहीत नाही</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    name="दूरभाष"
                                    label="दूरभाष"
                                    type="number"
                                    onChange={handleInputChange}
                                    disabled={disableAllFields}
                                    value={selectedSuchiRow['दूरभाष']}
                                    // defaultValue={formik.values.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    name="भ्रमणभाष"
                                    label="भ्रमणभाष"
                                    type="number"
                                    onChange={handleInputChange}
                                    disabled={disableAllFields}
                                    value={selectedSuchiRow['भ्रमणभाष']}
                                    // defaultValue={formik.values.name}
                                />
                            </Grid>
                        </Grid>

                        <Divider sx={{ mt: 1.5, mb: 3 }} />

                        {/* address infomation */}
                        <h3>पत्ता</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    fullWidth
                                    name="पूर्ण पत्ता"
                                    label="पूर्ण पत्ता"
                                    onChange={handleInputChange}
                                    value={selectedSuchiRow['पूर्ण पत्ता']}
                                    // defaultValue={formik.values.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    name="पिन कोड"
                                    label="पिन कोड"
                                    type="number"
                                    onChange={handleInputChange}
                                    disabled={disableAllFields}
                                    value={selectedSuchiRow['पिन कोड']}
                                    // defaultValue={formik.values.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    name="इ-मेल आयडी"
                                    label="इ-मेल आयडी"
                                    type="email"
                                    onChange={handleInputChange}
                                    disabled={disableAllFields}
                                    value={selectedSuchiRow['इ-मेल आयडी']}
                                    // defaultValue={formik.values.name}
                                />
                            </Grid>
                        </Grid>

                        <Divider sx={{ mt: 1.5, mb: 3 }} />

                        {/* occupatoin information */}
                        <h3>व्यवसाय</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>व्यवसाय</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="व्यवसाय"
                                        label="व्यवसाय"
                                        value={selectedSuchiRow['व्यवसाय']?.trim() ?? ''}
                                        defaultValue=""
                                        disabled={disableAllFields}
                                        onChange={(e) => {
                                            // handleInputChange(e);
                                            const tempObj = { ...selectedSuchiRow };
                                            tempObj['व्यवसाय'] = e.target.value;
                                            tempObj['व्यवसाय तपशील 1'] = '';
                                            tempObj['व्यवसाय तपशील 2'] = '';
                                            setSelectedSuchiRow({ ...tempObj });
                                        }}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        // inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {vyavsayTree?.map((item) => (
                                            <MenuItem value={item?.localized_name}>{item?.localized_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>व्यवसाय तपशील 1</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="व्यवसाय तपशील 1"
                                        label="व्यवसाय तपशील 1"
                                        value={selectedSuchiRow['व्यवसाय तपशील 1']?.trim() ?? ''}
                                        defaultValue=""
                                        disabled={disableAllFields}
                                        onChange={(e) => {
                                            // handleInputChange(e);
                                            const tempObj = { ...selectedSuchiRow };
                                            tempObj['व्यवसाय तपशील 1'] = e.target.value;
                                            tempObj['व्यवसाय तपशील 2'] = '';
                                            setSelectedSuchiRow(tempObj);
                                        }}
                                        size="medium"
                                        fullWidth
                                        // inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {occupationTree?.occupation1 && occupationTree?.occupation1?.length > 0 ? (
                                            occupationTree?.occupation1?.map((item) => (
                                                <MenuItem value={item?.localized_name}>{item?.localized_name}</MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem selected disabled value="">
                                                माहिती उपलब्ध नाही
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>व्यवसाय तपशील 2</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="व्यवसाय तपशील 2"
                                        label="व्यवसाय तपशील 2"
                                        value={selectedSuchiRow['व्यवसाय तपशील 2']?.trim() ?? ''}
                                        defaultValue=""
                                        disabled={disableAllFields}
                                        onChange={(e) => {
                                            // handleInputChange(e);
                                            const tempObj = { ...selectedSuchiRow };
                                            tempObj['व्यवसाय तपशील 2'] = e.target.value;
                                            setSelectedSuchiRow(tempObj);
                                        }}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {occupationTree?.occupation2?.map((item) => (
                                            <MenuItem value={item?.localized_name}>{item?.localized_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Divider sx={{ mt: 1.5, mb: 3 }} />

                        {/* dayitv */}
                        <h3>दायित्व</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>श्रेणी</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="श्रेणी"
                                        label="श्रेणी"
                                        value={selectedSuchiRow['श्रेणी']?.trim()}
                                        onChange={(e) => {
                                            // handleInputChange(e);
                                            const tempObj = { ...selectedSuchiRow };
                                            tempObj['श्रेणी'] = e.target.value;
                                            tempObj['खाते'] = '';
                                            setSelectedSuchiRow(tempObj);
                                        }}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {shreniAndDayitv?.map((item) => (
                                            <MenuItem value={item?.localized_name}>{item?.localized_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>खाते</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="खाते"
                                        label="खाते"
                                        value={selectedSuchiRow['खाते']?.trim()}
                                        onChange={handleInputChange}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {shreniKhateTree?.map((item) => (
                                            <MenuItem value={item?.localized_name}>{item?.localized_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>दायित्व स्तर</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="दायित्व स्तर"
                                        label="दायित्व स्तर"
                                        value={selectedSuchiRow['दायित्व स्तर']?.trim() ?? ''}
                                        defaultValue=""
                                        onChange={(e) => {
                                            // handleInputChange(e);
                                            const tempObj = { ...selectedSuchiRow };
                                            tempObj['दायित्व स्तर'] = e.target.value;
                                            tempObj['दायित्व'] = '';
                                            setSelectedSuchiRow(tempObj);
                                        }}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        // inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {dayitvTree?.map((item) => (
                                            <MenuItem value={item?.localized_name?.trim()?.replaceAll(' ', '')}>
                                                {item?.localized_name?.trim()?.replaceAll(' ', '')}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>दायित्व</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="दायित्व"
                                        label="दायित्व"
                                        value={selectedSuchiRow['दायित्व']?.trim() ?? ''}
                                        defaultValue=""
                                        onChange={handleInputChange}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        // inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {dayitvTreeData?.map((item) => (
                                            <MenuItem value={item?.localized_name}>{item?.localized_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    name="दायित्व अन्य"
                                    label="दायित्व अन्य"
                                    onChange={handleInputChange}
                                    disabled={disableAllFields}
                                    value={selectedSuchiRow['दायित्व अन्य']}
                                    // defaultValue={formik.values.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    name="विशेष नैपुण्य"
                                    label="विशेष नैपुण्य"
                                    onChange={handleInputChange}
                                    disabled={disableAllFields}
                                    value={selectedSuchiRow['विशेष नैपुण्य']}
                                    // defaultValue={formik.values.name}
                                />
                            </Grid>
                        </Grid>

                        <Divider sx={{ mt: 1.5, mb: 3 }} />

                        {/* sangh information */}
                        <h3>संघ माहिती</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>संघ शिक्षण</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="संघ शिक्षण"
                                        label="संघ शिक्षण"
                                        value={selectedSuchiRow['संघ शिक्षण'] ?? ''}
                                        defaultValue=""
                                        onChange={handleInputChange}
                                        // disabled
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        // inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {sanghShikshan?.map((item) => (
                                            <MenuItem value={item?.localized_name}>{item?.localized_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>प्रतिज्ञा</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="प्रतिज्ञा"
                                        label="प्रतिज्ञा"
                                        value={selectedSuchiRow['प्रतिज्ञा'] ?? ''}
                                        defaultValue=""
                                        onChange={handleInputChangeBoolean}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        // inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="true">हो</MenuItem>
                                        <MenuItem value="false">नाही</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Divider sx={{ mt: 1.5, mb: 3 }} />

                        {/* ganvesh */}
                        <h3>गणवेश</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>टोपी</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="टोपी"
                                        label="टोपी"
                                        value={selectedSuchiRow['टोपी']}
                                        onChange={handleInputChangeBoolean}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="true">हो</MenuItem>
                                        <MenuItem value="false">नाही</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>सदरा</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="सदरा"
                                        label="सदरा"
                                        value={selectedSuchiRow['सदरा']}
                                        onChange={handleInputChangeBoolean}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="true">हो</MenuItem>
                                        <MenuItem value="false">नाही</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>विजार</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="विजार"
                                        label="विजार"
                                        value={selectedSuchiRow['विजार']}
                                        onChange={handleInputChangeBoolean}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="true">हो</MenuItem>
                                        <MenuItem value="false">नाही</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>पदवेष</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="पदवेष"
                                        label="पदवेष"
                                        value={selectedSuchiRow['पदवेष']}
                                        onChange={handleInputChangeBoolean}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="true">हो</MenuItem>
                                        <MenuItem value="false">नाही</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>पट्टा</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="पट्टा"
                                        label="पट्टा"
                                        value={selectedSuchiRow['पट्टा']}
                                        onChange={handleInputChangeBoolean}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="true">हो</MenuItem>
                                        <MenuItem value="false">नाही</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>दंड</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="दंड"
                                        label="दंड"
                                        value={selectedSuchiRow['दंड']}
                                        onChange={handleInputChangeBoolean}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="true">हो</MenuItem>
                                        <MenuItem value="false">नाही</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Divider sx={{ mt: 1.5, mb: 3 }} />

                        {/* other */}
                        <h3>इतर</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>दुचाकी आहे?</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="दुचाकी आहे?"
                                        label="दुचाकी आहे?"
                                        value={selectedSuchiRow['दुचाकी आहे?']}
                                        onChange={handleInputChangeBoolean}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="true">हो</MenuItem>
                                        <MenuItem value="false">नाही</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth sx={{ height: '51px' }}>
                                    <InputLabel>चार चाकी आहे?</InputLabel>
                                    <Select
                                        sx={{ height: '51px' }}
                                        name="चार चाकी आहे?"
                                        label="चार चाकी आहे?"
                                        value={selectedSuchiRow['चार चाकी आहे?']}
                                        onChange={handleInputChangeBoolean}
                                        displayEmpty
                                        size="medium"
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="true">हो</MenuItem>
                                        <MenuItem value="false">नाही</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Divider sx={{ mt: 1.5, mb: 3 }} />

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                {disableAllFields ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            setHistoryModel(true);
                                            fetchHistory();
                                        }}
                                    >
                                        मागिल महिती दाखवा
                                    </Button>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box textAlign="right">
                                    <Button variant="contained" color="error" onClick={handleCloseSuch}>
                                        रद्द करा
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        sx={{ ml: 1 }}
                                        disabled={disableBtn}
                                        // onClick={() => {
                                        //     _.isEmpty(selectedSuchiRow) && disableAllFields === false ? saveData() : updateData();
                                        // }}
                                        onClick={updateData}
                                    >
                                        {disableAllFields ? 'अपडेट करा' : 'जतन करा'}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogActions>
            </Dialog>

            {/* history model */}
            <HistorySuchiDialog model={historyModel} setModel={setHistoryModel} timeline={timeline} setTimeline={setTimeline} />
        </>
    );
}
