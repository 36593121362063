import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const SadyasthitiService = {};
export default SadyasthitiService;

// export const getAllVyavsayTree = () => {
//     const url = `${BASE_URL}/api/v2/metadata/alltree`;
//     const options = {
//         method: 'GET'
//         // headers: {
//         //     Accept: 'application/json',
//         //     'Content-Type': 'application/json',
//         //     Authorization: `Bearer ${token}`
//         // }
//     };
//     return fetch(url, options)
//         .then((response) => {
//             console.log('response', response);
//         })

//         .catch((error) => {
//             console.error('Error:', error);
//         });
// };

// export const getAllVyavsayTree = () =>
//     axios.get(`${BASE_URL}/api/v2/metadata/alltree`).then((res) => {
//         return res.data;
//     });

export const getAllVyavsayTree = async () => {
    const res = await axios.get(`${BASE_URL}/api/v2/metadata/alltree`);
    return res.data;
};

export const addSwayamSevak = (payload) => {
    console.log(payload);
    return axios.post(`${BASE_URL}/api/v2/swayamsevak/add`, payload);
};

export const getShreniData = async () => {
    const res = await axios.get(`${BASE_URL}/api/v2/metadata/shreni`);
    return res.data;
};

export const getKhateData = async () => {
    const res = await axios.get(`${BASE_URL}/api/v2/metadata/khate`);
    return res.data;
};

export const getDayitwStarData = async () => {
    const res = await axios.get(`${BASE_URL}/api/v2/metadata/dayitva_star`);
    return res.data;
};

export const getDayitwData = async () => {
    const res = await axios.get(`${BASE_URL}/api/v2/metadata/dayitva`);
    return res.data;
};

export const getAllShreniAndKhateTree = async () => {
    const res = await axios.get(`${BASE_URL}/api/v2/metadata/shreni/khate/tree`);
    return res.data;
};

export const getAllDayitvTree = async () => {
    const res = await axios.get(`${BASE_URL}/api/v2/metadata/dayitva_star/dayitva/tree`);
    return res.data;
};

export const getAllSanghShikshan = async () => {
    const res = await axios.get(`${BASE_URL}/api/v2/metadata/sangh_shikshan`);
    return res.data;
};

export const getAllListLocationWise = async (payload) => {
    const res = await axios.post(`${BASE_URL}/api/v2/swayamsevak/allusers`, payload);
    return res.data;
};

export const updateSwayamSevak = async (payload) => {
    const res = await axios.put(`${BASE_URL}/api/v2/swayamsevak/update`, payload);
    return res.data;
};

export const getSingleHostory = async (id) => {
    const res = await axios.get(`${BASE_URL}/api/v2/swayamsevak/history/${id}`);
    return res.data;
};

export const deleteSwayamSevak = async (payload) => {
    const res = await axios.put(`${BASE_URL}/api/v2/swayamsevak/delet`, payload);
    return res.data;
};

// axios.interceptors.request.use(
//     (config) => {
//         const token = useAuth();
//         console.log('token...', token);
//         Object.assign(config.headers, {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer fdgfdgfgfg`
//         });
//         return config;
//     },
//     (error) => Promise.reject(error)
// );

// ------------------ report api ------------------

export const filterReport = async (payload) => {
    const res = await axios.post(`${BASE_URL}/api/v2/swayamsevak/report`, payload);
    return res.data;
};
