import firebase from 'firebase/app';
import { LogoutSystem } from './logoutServices';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const GeoService = {};
export default GeoService;

export const getTree = async (token) => {
    const tokenAuth = await firebase.auth().currentUser.getIdToken();
    const url = `${BASE_URL}/api/v2/geo/tree`;
    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenAuth}`
        }
    };
    return fetch(url, options)
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            const errorData = {
                error: true,
                status: response.status
            };
            return errorData;
        })
        .then(async (data) => {
            if (data.error) {
                if (data.status === 403) {
                    console.log('Unauthorized Credentials... ');
                    return 'force:logout';
                }
                if (data.status === 401) {
                    console.log('Unauthorized Credentials... ');
                    await LogoutSystem();
                }
                return data;
            }
            return data;
        })
        .catch((error) => {
            console.error('Error:', error);
        });
};

export const getTreeLazyForId = async (token, locationId) => {
    const tokenAuth = await firebase.auth().currentUser.getIdToken();
    const url = `${BASE_URL}/api/v2/geo/tree-lazy/${locationId}`;
    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenAuth}`
        }
    };
    return fetch(url, options)
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            const errorData = {
                error: true,
                status: response.status
            };
            return errorData;
        })
        .then(async (data) => {
            if (data.error) {
                if (data.status === 403) {
                    console.log('Unauthorized Credentials... ');
                    return 'force:logout';
                }
                if (data.status === 401) {
                    console.log('Unauthorized Credentials... ');
                    await LogoutSystem();
                }
                return data;
            }
            return data;
        })
        .catch((error) => {
            console.error('Error:', error);
        });
};

export const getTreeForId = async (token, locationId) => {
    const tokenAuth = await firebase.auth().currentUser.getIdToken();
    const url = `${BASE_URL}/api/v2/geo/tree/location`;
    const options = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenAuth}`
        },
        body: JSON.stringify({ locationId })
    };
    return fetch(url, options)
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            const errorData = {
                error: true,
                status: response.status
            };
            return errorData;
        })
        .then(async (data) => {
            if (data.error) {
                if (data.status === 403) {
                    console.log('Unauthorized Credentials... ');
                    return 'force:logout';
                }
                if (data.status === 401) {
                    console.log('Unauthorized Credentials... ');
                    await LogoutSystem();
                }
                return data;
            }
            return data;
        })
        .catch((error) => {
            console.error('Error:', error);
        });
};
