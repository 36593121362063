import firebase from 'firebase/app';

import 'firebase/auth';
import { SNACKBAR_OPEN } from 'store/actions';

const LogoutServices = {};
export default LogoutServices;

export const LogoutSystem = async () => {
    await firebase.auth().signOut();
    window.location.href = '/login';
};
