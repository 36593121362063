// import { useNavigate } from 'react-router';

import firebase from 'firebase/app';

import { LogoutSystem } from './logoutServices';
// import 'firebase/auth';

const BASE_URL = process.env.REACT_APP_BASE_URL;

// const navigate = useNavigate();

const UserService = {};
export default UserService;

const submitUser = async (token, userPayload, url, method) => {
    const tokenAuth = await firebase.auth().currentUser.getIdToken();
    const options = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenAuth}`
        },
        body: JSON.stringify(userPayload)
    };
    return fetch(url, options)
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            const errorData = {
                error: true,
                status: response.status,
                message: response.message
            };
            return errorData;
        })
        .then(async (data) => {
            if (data.error) {
                if (data.status === 403) {
                    console.log('Unauthorized Credentials... ');
                    return 'force:logout';
                }
                if (data.status === 401) {
                    console.log('Unauthorized Credentials1... ');
                    await LogoutSystem();
                }
                return data;
            }
            return data;
        })
        .catch((error) => {
            console.error('Error:', error);
        });
};

export const addUser = (token, userPayload) => {
    const url = `${BASE_URL}/api/v2/user`;
    return submitUser(token, userPayload, url, 'POST');
};

export const updateUser = (token, userPayload) => {
    const url = `${BASE_URL}/api/v2/user/update`;
    return submitUser(token, userPayload, url, 'PUT');
};

export const addUserForApproval = (token, userPayload) => {
    const url = `${BASE_URL}/api/v2/user/forApproval`;
    return submitUser(token, userPayload, url, 'POST');
};

const submitPutRequest = async (token, payload, url) => {
    const tokenAuth = await firebase.auth().currentUser.getIdToken();
    const options = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenAuth}`
        },
        body: JSON.stringify(payload)
    };
    return fetch(url, options)
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            const errorData = {
                error: true,
                status: response.status,
                message: response.message
            };
            return errorData;
        })
        .then(async (data) => {
            if (data.error) {
                if (data.status === 403) {
                    console.log('Unauthorized Credentials... ');
                    return 'force:logout';
                }
                if (data.status === 401) {
                    console.log('Unauthorized Credentials2... ');
                    await LogoutSystem();
                }
                return data;
            }
            return data;
        })
        .catch((error) => {
            console.error('Error:', error);
        });
};

export const getUserProfileInfo = (token, userPaylaod) => {
    const url = `${BASE_URL}/api/v2/user/profile`;
    return submitPutRequest(token, userPaylaod, url);
};

export const approveUser = (token, userId) => {
    const url = `${BASE_URL}/api/v2/user/approve`;
    const payload = {
        user_id: userId
    };
    return submitPutRequest(token, payload, url);
};
export const approveAllUser = (token, payload) => {
    const url = `${BASE_URL}/api/v2/user/approveAll`;
    return submitPutRequest(token, payload, url);
};

const getUsers = async (token, url) => {
    const tokenAuth = await firebase.auth().currentUser.getIdToken();
    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenAuth}`
        }
    };
    return fetch(url, options)
        .then((response) => {
            console.log('response.......', response);
            if (response.status === 200) {
                return response.json();
            }
            const errorData = {
                error: true,
                status: response.status,
                message: response.message
            };
            return errorData;
        })
        .then(async (data) => {
            if (data.error) {
                if (data.status === 403) {
                    console.log('Unauthorized Credentials... ');
                    return 'force:logout';
                }
                if (data.status === 401) {
                    await LogoutSystem();
                }
                return data;
            }
            return data;
        })
        .catch((error) => {
            console.error('Error:', error);
        });
};

export const getUsersList = (token, userId) => {
    const url = `${BASE_URL}/api/v2/user/list/${userId}`;
    return getUsers(token, url);
};

export const getAllUsersList = (token) => {
    const url = `${BASE_URL}/api/v2/user/list-all`;
    return getUsers(token, url);
};

export const getAllUnapprovedUsersList = (token) => {
    const url = `${BASE_URL}/api/v2/user/list-unapproved`;
    return getUsers(token, url);
};

export const deleteUser = (token, userIds) => {
    const url = `${BASE_URL}/api/v2/user/delete`;
    const payload = {
        userIds
    };
    return submitPutRequest(token, payload, url);
};
export const activateUser = (token, userId) => {
    const url = `${BASE_URL}/api/v2/user/activate`;
    const payload = {
        userId
    };
    return submitPutRequest(token, payload, url);
};
export const deactivateUser = (token, userId) => {
    const url = `${BASE_URL}/api/v2/user/deactivate`;
    const payload = {
        userId
    };
    return submitPutRequest(token, payload, url);
};
