import { DateTime } from 'luxon';
import _ from 'lodash';

const getDefaultSelectedYear = () => {
    const dateYear = new Date().getFullYear();
    const newYear = (dateYear + 1) % 100;
    return `${dateYear}-${newYear}`;
};

export const getUpdatedAtDateInFormat = (currentDate) => {
    const dateString = DateTime.fromISO(currentDate || new Date().toISOString()).toFormat('dd/MM/yyyy HH:mm');

    return dateString;
};
export const defaultSelectedYear = getDefaultSelectedYear();
export const YEARS = ['2023-24', '2024-25'];
export const AHWAAL_MONTHS = [];

export const groupedGaonVastiWiseRows = (rows) => {
    let ogRows = [];
    const groupedrows = _.groupBy(rows, 'location.localized_name');
    Object.keys(groupedrows).forEach((i) => {
        ogRows = [...ogRows, ...groupedrows[i]];
    });
    return ogRows;
};
