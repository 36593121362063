import React, { useEffect, useState } from 'react';
import './index.css';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { utils, writeFile } from 'xlsx';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import Loader from 'ui-component/Loader';

export default function SanghikUpkhandAhawal({ ahawalResponse }) {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const defaultUpakhandAhawal = {
        totalMandal: 0,
        presentMandal: 0,
        totalGaon: 0,
        presentGaon: 0,
        sankalpitShakha: 0,
        sankalpitMilan: 0,
        sankalpitSanghMandali: 0,
        sankalpitTotal: 0,
        presentSankalpitShakha: 0,
        presentSankalpitMilan: 0,
        presentSankalpitSanghMandali: 0,
        presentSankalpitTotal: 0,
        praudh: 0,
        tvk: 0,
        mahavidyalayin: 0,
        baal: 0,
        totalUpasthit: 0,
        shishu: 0
    };
    const [tableData, setTableData] = useState(defaultUpakhandAhawal);

    const prepareAhawalData = () => {
        const obj = {
            totalMandal: ahawalResponse?.total_mandal || 0,
            presentMandal: ahawalResponse?.present_mandal || 0,
            totalGaon: ahawalResponse?.Gaon?.Total_gaon || 0,
            presentGaon: ahawalResponse?.Gaon?.present_gaon || 0,
            sankalpitShakha: ahawalResponse?.ekunSankalpit?.['शाखा'] || 0,
            sankalpitMilan: ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] || 0,
            sankalpitSanghMandali: ahawalResponse?.ekunSankalpit?.['संघ मंडळी'] || 0,
            sankalpitTotal:
                ahawalResponse?.ekunSankalpit?.['शाखा'] +
                ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] +
                ahawalResponse?.ekunSankalpit?.['संघ मंडळी'],
            presentSankalpitShakha: ahawalResponse?.present_Sankalpit?.['शाखा'] || 0,
            presentSankalpitMilan: ahawalResponse?.present_Sankalpit?.['साप्ताहिक मिलन'] || 0,
            presentSankalpitSanghMandali: ahawalResponse?.present_Sankalpit?.['संघ मंडळी'] || 0,
            presentSankalpitTotal:
                ahawalResponse?.present_Sankalpit?.['शाखा'] +
                ahawalResponse?.present_Sankalpit?.['साप्ताहिक मिलन'] +
                ahawalResponse?.present_Sankalpit?.['संघ मंडळी'],

            praudh: ahawalResponse?.present_sayamsevak_upastiti?.['प्रौढ'] || 0,
            tvk: ahawalResponse?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'] || 0,
            mahavidyalayin: ahawalResponse?.present_sayamsevak_upastiti?.['महाविद्यालयीन'] || 0,
            baal: ahawalResponse?.present_sayamsevak_upastiti?.['बाल'] || 0,
            totalUpasthit: ahawalResponse?.present_sayamsevak_upastiti?.['एकूण'] || 0,
            shishu: ahawalResponse?.present_sayamsevak_upastiti?.['शिशु'] || 0
        };
        setTableData(obj);
    };
    const fetchUpakhandAhawalDS = () => {
        const sheetArray = [];
        const allMandal = ahawalResponse?.mandals?.filter((i) => i.level_id === '9');
        allMandal.forEach((ele) => {
            const obj = {
                mandalName: ele.mandal_name,
                gaonCount: ele.gaon_count,
                sankalpitGaonCount: 0,
                presentGaonCount: 0,
                sankalpit_shakha_praudh: 0,
                sankalpit_shakha_tvk: 0,
                sankalpit_shakha_mahavidyalayin: 0,
                sankalpit_shakha_sanyuktaVidyarthi: 0,
                sankalpit_milan_praudh: 0,
                sankalpit_milan_tvk: 0,
                sankalpit_milan_mahavidyalayin: 0,
                sankalpit_milan_sanyuktaVidyarthi: 0,
                sankalpit_sanghMandali: 0,
                upasthit_shakha_praudh: 0,
                upasthit_shakha_tvk: 0,
                upasthit_shakha_mahavidyalayin: 0,
                upasthit_shakha_sanyuktaVidyarthi: 0,
                upasthit_milan_praudh: 0,
                upasthit_milan_tvk: 0,
                upasthit_milan_mahavidyalayin: 0,
                upasthit_milan_sanyuktaVidyarthi: 0,
                upasthit_sanghMandali: 0,
                upasthit_praudh: 0,
                upasthit_tvk: 0,
                upasthit_mahavidyalayin: 0,
                upasthit_baal: 0,
                upasthit_ekun: 0,
                upasthit_shishu: 0
            };
            ele?.gaon_locations.forEach((i) => {
                obj.sankalpit_shakha_praudh += i?.sankalp_total?.['शाखा']?.sub_types?.['प्रौढ'];
                obj.sankalpit_shakha_tvk += i.sankalp_total?.['शाखा']?.sub_types?.['तरुण व्यवसायी'];
                obj.sankalpit_shakha_mahavidyalayin += i.sankalp_total?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'];
                obj.sankalpit_shakha_sanyuktaVidyarthi += i.sankalp_total?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'];
                obj.sankalpit_milan_praudh += i.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'];
                obj.sankalpit_milan_tvk += i.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'];
                obj.sankalpit_milan_mahavidyalayin += i.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'];
                obj.sankalpit_milan_sanyuktaVidyarthi += i.sankalp_total?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'];
                obj.sankalpit_sanghMandali += i.sankalp_total?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'];

                obj.upasthit_shakha_praudh += i.present_Sankalpit_age_wise['शाखा']?.sub_types?.['प्रौढ'] || 0;
                obj.upasthit_shakha_tvk += i.present_Sankalpit_age_wise['शाखा']?.sub_types?.['तरुण व्यवसायी'] || 0;
                obj.upasthit_shakha_mahavidyalayin += i.present_Sankalpit_age_wise['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0;
                obj.upasthit_shakha_sanyuktaVidyarthi += i.present_Sankalpit_age_wise['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'] || 0;
                obj.upasthit_milan_praudh += i.present_Sankalpit_age_wise['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'] || 0;
                obj.upasthit_milan_tvk += i.present_Sankalpit_age_wise['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'] || 0;
                obj.upasthit_milan_mahavidyalayin +=
                    i.present_Sankalpit_age_wise['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0;
                obj.upasthit_milan_sanyuktaVidyarthi +=
                    i.present_Sankalpit_age_wise['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'] || 0;
                obj.upasthit_sanghMandali += i.present_Sankalpit_age_wise['संघ मंडळी']?.sub_types?.['संघ मंडळी'] || 0;

                obj.upasthit_praudh += i?.present_sayamsevak_upastiti?.['प्रौढ'];
                obj.upasthit_tvk += i?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'];
                obj.upasthit_mahavidyalayin += i?.present_sayamsevak_upastiti?.['महाविद्यालयीन'];
                obj.upasthit_baal += i?.present_sayamsevak_upastiti?.['बाल'];
                obj.upasthit_ekun += i?.present_sayamsevak_upastiti?.['एकूण'];
                obj.upasthit_shishu += i?.present_sayamsevak_upastiti?.['शिशु'];
            });
            const ekunEkake =
                obj.sankalpit_shakha_praudh +
                obj.sankalpit_shakha_tvk +
                obj.sankalpit_shakha_mahavidyalayin +
                obj.sankalpit_shakha_sanyuktaVidyarthi +
                obj.sankalpit_milan_praudh +
                obj.sankalpit_milan_tvk +
                obj.sankalpit_milan_mahavidyalayin +
                obj.sankalpit_milan_sanyuktaVidyarthi +
                obj.sankalpit_sanghMandali;

            const ekunUpasthitEkake =
                obj.upasthit_shakha_praudh +
                obj.upasthit_shakha_tvk +
                obj.upasthit_shakha_mahavidyalayin +
                obj.upasthit_shakha_sanyuktaVidyarthi +
                obj.upasthit_milan_praudh +
                obj.upasthit_milan_tvk +
                obj.upasthit_milan_mahavidyalayin +
                obj.upasthit_milan_sanyuktaVidyarthi +
                obj.upasthit_sanghMandali;
            const ekunUpasthiti =
                obj.upasthit_praudh + obj.upasthit_tvk + obj.upasthit_mahavidyalayin + obj.upasthit_baal + obj.upasthit_ekun;
            sheetArray.push([
                obj.mandalName,
                obj.gaonCount,
                obj.sankalpitGaonCount,
                obj.presentGaonCount,
                obj.sankalpit_shakha_praudh,
                obj.sankalpit_shakha_tvk,
                obj.sankalpit_shakha_mahavidyalayin,
                obj.sankalpit_shakha_sanyuktaVidyarthi,
                obj.sankalpit_milan_praudh,
                obj.sankalpit_milan_tvk,
                obj.sankalpit_milan_mahavidyalayin,
                obj.sankalpit_milan_sanyuktaVidyarthi,
                obj.sankalpit_sanghMandali,
                ekunEkake,
                obj.upasthit_shakha_praudh,
                obj.upasthit_shakha_tvk,
                obj.upasthit_shakha_mahavidyalayin,
                obj.upasthit_shakha_sanyuktaVidyarthi,
                obj.upasthit_milan_praudh,
                obj.upasthit_milan_tvk,
                obj.upasthit_milan_mahavidyalayin,
                obj.upasthit_milan_sanyuktaVidyarthi,
                obj.upasthit_sanghMandali,
                ekunUpasthitEkake,
                obj.upasthit_praudh,
                obj.upasthit_tvk,
                obj.upasthit_mahavidyalayin,
                obj.upasthit_baal,
                ekunUpasthiti,
                obj.upasthit_shishu
            ]);
        });
        const ekunSankalpit =
            +ahawalResponse?.ekunSankalpit?.['शाखा'] +
            +ahawalResponse?.ekunSankalpit?.['साप्ताहिक मिलन'] +
            +ahawalResponse?.ekunSankalpit?.['संघ मंडळी'];
        const ekunUpasthitEkake =
            +ahawalResponse?.Total_present?.['प्रौढ'] +
            +ahawalResponse?.Total_present?.['तरुण व्यवसायी'] +
            +ahawalResponse?.Total_present?.['महाविद्यालयीन विद्यार्थी'] +
            +ahawalResponse?.Total_present?.['संयुक्त विद्यार्थी'] +
            +ahawalResponse?.Total_present?.['संघ मंडळी'];
        sheetArray.push([
            'एकूण',
            ahawalResponse?.Gaon?.Total_gaon,
            ahawalResponse?.Gaon?.sankalpit_gaon,
            ahawalResponse?.Gaon?.present_gaon,
            ahawalResponse?.sankalp_total?.['शाखा']?.praudh || 0,
            ahawalResponse?.sankalp_total?.['शाखा']?.tvk || 0,
            ahawalResponse?.sankalp_total?.['शाखा']?.maha_vidyarthi || 0,
            ahawalResponse?.sankalp_total?.['शाखा']?.baal || 0,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.praudh || 0,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.tvk || 0,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.maha_vidyarthi || 0,
            ahawalResponse?.sankalp_total?.['साप्ताहिक मिलन']?.baal || 0,
            ahawalResponse?.sankalp_total?.['संघ मंडळी'] || 0,
            ekunSankalpit,
            ahawalResponse?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['प्रौढ'] || 0,
            ahawalResponse?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['तरुण व्यवसायी'] || 0,
            ahawalResponse?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
            ahawalResponse?.present_Sankalpit_age_wise?.['शाखा']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
            ahawalResponse?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['प्रौढ'] || 0,
            ahawalResponse?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['तरुण व्यवसायी'] || 0,
            ahawalResponse?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['महाविद्यालयीन विद्यार्थी'] || 0,
            ahawalResponse?.present_Sankalpit_age_wise?.['साप्ताहिक मिलन']?.sub_types?.['संयुक्त विद्यार्थी'] || 0,
            ahawalResponse?.present_Sankalpit_age_wise?.['संघ मंडळी']?.sub_types?.['संघ मंडळी'] || 0,
            ekunUpasthitEkake,
            ahawalResponse?.present_sayamsevak_upastiti?.['प्रौढ'],
            ahawalResponse?.present_sayamsevak_upastiti?.['तरुण व्यवसायी'],
            ahawalResponse?.present_sayamsevak_upastiti?.['महाविद्यालयीन'],
            ahawalResponse?.present_sayamsevak_upastiti?.['बाल'],
            ahawalResponse?.present_sayamsevak_upastiti?.['एकूण'],
            ahawalResponse?.present_sayamsevak_upastiti?.['शिशु']
        ]);
        sheetArray.unshift([
            '',
            'एकूण गावे',
            'संकल्प असलेली गावे',
            'उपस्थित गावे',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'संघमंडळी',
            'एकूण एकके',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'संयुक्त विद्यार्थी',
            'संघमंडळी',
            'एकूण एकके',
            'प्रौढ',
            'तरुण व्यवसायी',
            'महाविद्यालयीन',
            'बाल',
            'एकूण',
            'शिशु'
        ]);
        sheetArray.unshift(['', '', '', '', '', 'शाखा', '', '', '', 'सा. मिलन', '', '', '', '', '', 'शाखा', '', '', '', '', 'सा. मिलन']);
        sheetArray.unshift([
            'मंडलांची नावे',
            '',
            'गाव संख्या',
            '',
            '',
            '',
            '',
            '',
            'एकूण संकल्पित एकके',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'एकूण उपस्थित एकके',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'उपस्थिती (स्वयंसेवक)'
        ]);
        return sheetArray;
    };
    const generateExcelFile = () => {
        setLoading(true);
        const wb = utils.book_new();
        const ws1 = utils.json_to_sheet(fetchUpakhandAhawalDS());
        utils.book_append_sheet(wb, ws1, 'अहवाल');

        writeFile(wb, `${ahawalResponse?.location_name || ''} ${ahawalResponse?.name_suffix || ''} अहवाल.xlsx`);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'यशस्वीरितीने फाईल डाउनलोड झालेली आहे!',
            variant: 'alert',
            alertSeverity: 'success'
        });
        setLoading(false);
    };
    useEffect(() => {
        if ((ahawalResponse && ahawalResponse.length > 0) || Object.keys(ahawalResponse).length > 0) {
            prepareAhawalData();
        }
    }, [ahawalResponse]);

    return (
        <>
            {loading && <Loader />}
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography variant="h3">{`${ahawalResponse?.location_name || ''} ${ahawalResponse?.name_suffix || ''} अहवाल`}</Typography>
                <Button variant="contained" color="success" sx={{ m: '10px' }} onClick={generateExcelFile}>
                    <FormattedMessage id="menu.download.ahawal" />
                </Button>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell" align="center" colSpan={2}>
                                मंडल संख्या
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={2}>
                                गाव संख्या
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={4}>
                                एकूण संकल्पित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={4}>
                                एकूण उपस्थित एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center" colSpan={6}>
                                उपस्थिती (स्वयंसेवक)
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="tableCell" align="center">
                                एकूण मंडले
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                उपस्थित मंडले
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण गावे
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                उपस्थित गावे
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा. मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                संघमंडळी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शाखा
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                सा. मिलन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                संघमंडळी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण एकके
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                प्रौढ
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                तरुण व्यवसायी
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                महाविद्यालयीन
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                बाल
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                एकूण
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                शिशु
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className="tableCell" align="center">
                                {tableData.totalMandal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentMandal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.totalGaon || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentGaon || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.sankalpitShakha || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.sankalpitMilan || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.sankalpitSanghMandali || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.sankalpitTotal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentSankalpitShakha || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentSankalpitMilan || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentSankalpitSanghMandali || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.presentSankalpitTotal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.praudh || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.tvk || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.mahavidyalayin || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.baal || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.totalUpasthit || 0}
                            </TableCell>
                            <TableCell className="tableCell" align="center">
                                {tableData.shishu || 0}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
