import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'ui-component/Loader';
import MainCard from 'ui-component/cards/MainCard';
import { Alert, AlertTitle, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { YEARS, defaultSelectedYear } from 'utils/pmp-constants';
import GeoTreeDialog from 'ui-component/pmp-components/GeoTreeDialog';
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone';
import SendTwoToneIcon from '@material-ui/icons/SendTwoTone';
import { SET_GEO_STRUCTURE, SNACKBAR_OPEN } from 'store/actions';
import {
    getSanghikAhawalListForBhag,
    getSanghikAhawalListForGraminJilha,
    getSanghikAhawalListForNagar,
    getSanghikAhawalListForTaluka,
    getSanghikAhawalListForUpakhand
} from 'services/sanghik-service';
import SanghikUpkhandAhawal from './SanghikUpkhandAhawal';
import SanghikUpkhandNagarAhawal from './SanghikUpkhandNagarAhawal';
import SanghikTalukaAhawal from './SanghikTalukaAhawal';
import SanghikBhagAhawal from './SanhikBhagAhawal';
import SanghikGraminJilhaAhawal from './SanghikGraminJilhaAhawal';

export default function Sanghikahawal() {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const MONTH_FORMAT = 'MMMM';
    const sadyasthitiStore = useSelector((state) => state.sadyasthiti);
    const selectedgeoStructure = useSelector((state) => state?.centrallySelectedgeoStructure?.selectedGeoStructure);
    const [month, setMonth] = useState(sadyasthitiStore.month || DateTime.now().setLocale('mr').toFormat(MONTH_FORMAT));
    const [monthOptions, setMonthOptions] = useState([]);
    const [payload, setPayload] = useState({
        karyalay_pramukh: true
    });
    const [year, setYear] = useState(sadyasthitiStore.year || defaultSelectedYear);
    const [selectedGeo, setSelectedGeo] = useState(selectedgeoStructure);
    const [geoTreeDialogOpen, setGeoTreeDialogOpen] = useState(false);
    const [showEmptyMessage, setShowEmptyMessage] = useState('');
    const [noRecordsToShow, setNoRecordsToShow] = useState(false);
    const [displayReport, setDisplayReport] = useState(false);
    const [ahawalResponse, setAhawalResponse] = useState([]);
    const [showTalukaReport, setShowTalukaReport] = useState(false);
    const defaultCurrentLevel = {
        isBhag: false,
        isGraminJilha: false,
        isTaluka: false,
        isUpkhand: false,
        isUpkhandNagar: false,
        isShahariNagar: false
    };
    const [currentLevel, setCurrentLevel] = useState({ ...defaultCurrentLevel });

    const getMonth = () => {
        const months = [];
        const dt = DateTime.now();
        if (dt.month === 4) {
            // April
            months.push(dt.setLocale('mr').toFormat(MONTH_FORMAT));
        } else if (dt.month === 5) {
            // May
            months.push(dt.setLocale('mr').toFormat(MONTH_FORMAT));

            const dt1 = dt.minus({ month: 1 });
            months.push(dt1.setLocale('mr').toFormat(MONTH_FORMAT));
        } else {
            // June till March
            months.push(dt.setLocale('mr').toFormat(MONTH_FORMAT));

            const dt1 = dt.minus({ month: 1 });
            months.push(dt1.setLocale('mr').toFormat(MONTH_FORMAT));

            const dt2 = dt.minus({ months: 2 });
            months.push(dt2.setLocale('mr').toFormat(MONTH_FORMAT));

            const dt3 = dt.minus({ months: 3 });
            months.push(dt3.setLocale('mr').toFormat(MONTH_FORMAT));
            const dt4 = dt.minus({ months: 4 });
            months.push(dt4.setLocale('mr').toFormat(MONTH_FORMAT));
        }

        setMonthOptions(months);
    };
    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };
    const handleYearChange = (event) => {
        setYear(event.target.value);
        // if (selectedGeo && selectedGeo.location_id) {
        //     loadSankalp(selectedGeo.location_id, event.target.value);
        // }
    };
    const proceedAfterGeoSelection = () => {
        setPayload({ ...payload, selectedGeoLevel: selectedGeo });
        //
    };
    const handleDisplayAhwal = async () => {
        const selectedYear = year;
        const selectedMonth = month;

        if (
            selectedGeo?.level_id !== '3' &&
            selectedGeo?.level_id !== '6' &&
            selectedGeo?.level_id !== '7' &&
            selectedGeo?.level_id !== '8'
        ) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'कृपया भाग, उपखंड, उपखंड नगर किंवा शहरी नगर स्तर निवडा.',
                variant: 'alert',
                alertSeverity: 'warning'
            });
            return;
        }
        setLoading(true);
        const payload = {
            locationId: selectedGeo?.location_id,
            year: selectedYear,
            month: selectedMonth
        };
        try {
            let res = null;
            if (selectedGeo.level_id === '6') {
                res = await getSanghikAhawalListForTaluka(payload);
            } else if (selectedGeo.level_id === '8' && selectedGeo.name_suffix === 'उपखंड') {
                res = await getSanghikAhawalListForUpakhand(payload);
            } else if (selectedGeo.level_id === '7' || (selectedGeo.level_id === '8' && selectedGeo.name_suffix === 'उपखंड नगर')) {
                res = await getSanghikAhawalListForNagar(payload);
            } else if (selectedGeo.level_id === '3' && selectedGeo.name_suffix === 'भाग') {
                res = await getSanghikAhawalListForBhag(payload);
            } else if (selectedGeo.level_id === '3' && selectedGeo.name_suffix === 'ग्रामीण जिल्हा') {
                res = await getSanghikAhawalListForGraminJilha(payload);
            }

            console.log(res);
            if (res && res?.message !== undefined && res?.message === 'No Sanghik Record Found for the given parameters') {
                setNoRecordsToShow(true);
                setDisplayReport(false);
                return;
            }
            setNoRecordsToShow(false);
            setAhawalResponse(res);
            if (res.level_id === '3' && selectedGeo.name_suffix === 'भाग') {
                setCurrentLevel({
                    ...defaultCurrentLevel,
                    isBhag: true
                });
            } else if (res.level_id === '3' && selectedGeo.name_suffix === 'ग्रामीण जिल्हा') {
                setCurrentLevel({
                    ...defaultCurrentLevel,
                    isGraminJilha: true
                });
            } else if (res?.level_id === '6') {
                setCurrentLevel({
                    ...defaultCurrentLevel,
                    isTaluka: true
                });
            } else if (res?.level_id === '7') {
                setCurrentLevel({
                    ...defaultCurrentLevel,
                    isShahariNagar: true
                });
            } else if (res?.level_id === '8' && res.name_suffix === 'उपखंड') {
                setCurrentLevel({
                    ...defaultCurrentLevel,
                    isUpkhand: true
                });
            } else if (res?.level_id === '8' && res.name_suffix === 'उपखंड नगर') {
                setCurrentLevel({
                    ...defaultCurrentLevel,
                    isUpkhandNagar: true
                });
            }
            setDisplayReport(true);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getMonth();
        // fetchSanghikList();
    }, []);
    return (
        <>
            {loading && <Loader />}

            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3">
                                <FormattedMessage id="menu.sanghik.report" />
                            </Typography>
                        </Grid>
                    </Grid>
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={2} lg={1.4}>
                        <FormControl fullWidth>
                            <InputLabel id="month-select">
                                <FormattedMessage id="month" />
                            </InputLabel>
                            <Select
                                id="month"
                                name="month"
                                label="month"
                                value={month}
                                onChange={handleMonthChange}
                                displayEmpty
                                size="small"
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {monthOptions.map((monthOption, index) => (
                                    <MenuItem key={index} value={monthOption}>
                                        {monthOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <Stack direction="row">
                            <FormControl fullWidth>
                                <InputLabel id="year-select">
                                    <FormattedMessage id="year" />
                                </InputLabel>
                                <Select
                                    id="year"
                                    fullWidth
                                    name="year"
                                    label="year"
                                    value={year || ''}
                                    onChange={handleYearChange}
                                    displayEmpty
                                    // disabled
                                    size="small"
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    {YEARS.map((year, index) => (
                                        <MenuItem key={index} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} sm={5} md={4} lg={3}>
                        <Stack direction="row">
                            <Button
                                fullWidth
                                variant="outlined"
                                color={selectedGeo ? 'primary' : 'error'}
                                onClick={() => setGeoTreeDialogOpen(true)}
                                sx={{ mr: '10px' }}
                                endIcon={<LocationOnTwoToneIcon />}
                            >
                                {selectedGeo ? `"${selectedGeo?.localized_name} ${selectedGeo?.name_suffix}"` : 'भौगोलिक रचना निवडा!'}
                            </Button>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Button variant="contained" fullWidth endIcon={<SendTwoToneIcon />} onClick={() => handleDisplayAhwal()}>
                            <FormattedMessage id="btn_find_ahawal" />
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        {showEmptyMessage && (
                            <Alert severity="error" variant="standard" sx={{ color: '#000', mb: 1 }}>
                                <AlertTitle sx={{ color: '#000000' }}>{showEmptyMessage}</AlertTitle>
                            </Alert>
                        )}
                    </Grid>
                </Grid>
                {noRecordsToShow && <Typography variant="h4">No Records Available </Typography>}
                {displayReport && currentLevel.isGraminJilha && <SanghikGraminJilhaAhawal ahawalResponse={ahawalResponse} />}
                {displayReport && currentLevel.isTaluka && <SanghikTalukaAhawal ahawalResponse={ahawalResponse} />}
                {displayReport && currentLevel.isBhag && <SanghikBhagAhawal ahawalResponse={ahawalResponse} />}
                {displayReport && currentLevel.isUpkhand && <SanghikUpkhandAhawal ahawalResponse={ahawalResponse} />}
                {displayReport && currentLevel.isUpkhandNagar && <SanghikUpkhandNagarAhawal isUpkhand ahawalResponse={ahawalResponse} />}
                {displayReport && currentLevel.isShahariNagar && (
                    <SanghikUpkhandNagarAhawal isShahariNagar ahawalResponse={ahawalResponse} />
                )}
            </MainCard>

            {/* geo tree model */}
            <GeoTreeDialog
                selectedGeo={selectedGeo}
                geoTreeDialogOpen={geoTreeDialogOpen}
                handleGeoDialogClose={(event, reason) => {
                    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
                        return;
                    }
                    setGeoTreeDialogOpen(false);
                }}
                handleCancelGeoSelection={() => {
                    setSelectedGeo(undefined);
                    setGeoTreeDialogOpen(false);
                    dispatch({ type: SET_GEO_STRUCTURE, payload: { selectedGeoStructure: undefined } });
                }}
                handleGeoLevelSelection={(selectedGeoLevel) => {
                    setSelectedGeo(selectedGeoLevel);
                    dispatch({ type: SET_GEO_STRUCTURE, payload: { selectedGeoStructure: selectedGeoLevel } });
                }}
                handleConfirmGeoSelection={() => {
                    setGeoTreeDialogOpen(false);
                    proceedAfterGeoSelection();
                }}
                skipLevel={['7', '8']}
                skipLeafLevel
            />
        </>
    );
}
